/**
 * LifeRamp
 *
 * @author      Waris Anwar
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Multiselect } from 'multiselect-react-dropdown';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import randomString from "@afaanbilal/random-string";

import DialogModal from "components/Modal/DialogModal";

import { Coach, Institution, User } from "../../api";
import { uploadToFirebase } from "../../api/utils";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Label,
    CardImg,
    Spinner,
    CardText,
    CardImgOverlay,
} from "reactstrap";
import AdminImageModal from "components/Modal/ImagePickerAdmin";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const blankInstitution = { name: "", shortname: "", description: "", image: "", domains: [], coaches: [], is_enabled_events: false };

const InstitutionAddEdit = (props) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.institution);
    const user = useSelector(state => state.user.profile);

    let uuid = useQuery().get("uuid");
    const isEdit = !!uuid || false;
    const institutionData = state.institutions ? state.institutions.find(i => i.uuid === uuid) : null;
    const [institution, setInstitution] = React.useState(isEdit ? institutionData : blankInstitution);
    const [dialogModal, setDialogModal] = React.useState(false);
    const [dialogModalMessage, setDialogModalMessage] = React.useState("");
    const [imagePicker, setImagePicker] = React.useState(false);

    const [uploadingToFirebase, setUploadingToFirebase] = React.useState(false);
    const [uploadedImageBlob, setUploadedImageBlob] = React.useState(null);
    const [shouldSave, setShouldSave] = React.useState(false);

    const coach = useSelector(state => state.coach);
    const [coachSearch, setCoachSearch] = React.useState('');
    const [showApprovedCoaches, setShowApprovedCoaches] = React.useState(false);

    React.useEffect(() => {
        dispatch(Coach.getCoaches({ page: coach.page, search: coachSearch }));
        dispatch(User.getMyProfile());
    }, [dispatch, coach.page, coachSearch]);

    const toggleCoachSelector = (v) => {
        v = (v === 'true');
        setShowApprovedCoaches(v);
        !v && setInstitution(state => ({ ...state, coaches: [] }));
    }

    React.useEffect(() => {
        if (shouldSave) {
            if (isEdit) {
                dispatch(Institution.updateInstitution({ institution }))
                    .then((result) => {
                        setDialogModal(true);
                        setDialogModalMessage(result.payload.status === "success" ? "Institution saved!" : result.payload.message);
                        setUploadedImageBlob(null);
                    });
            } else {
                dispatch(Institution.createInstitution({ institution }))
                    .then((result) => {
                        setDialogModal(true);
                        setDialogModalMessage(result.payload.status === "success" ? "Institution added!" : result.payload.message);
                        setUploadedImageBlob(null);
                    });
            }

            setShouldSave(false);
        }

        if (institution.coaches && institution.coaches.length) {
            setShowApprovedCoaches(true);
        }
        dispatch(User.getMyProfile());
    }, [shouldSave, isEdit, dispatch, institution]);

    const validateDomain = (d) => {
        const isValid = d.match(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/);

        if (isValid) {
            return true;
        } else {
            alert('Enter a valid domain.');
        }
    }

    const onSave = async () => {
        if (state.loading || uploadingToFirebase) return;

        if (uploadedImageBlob) {
            setUploadingToFirebase(true);
            const url = await uploadToFirebase("institution-images/" + randomString() + "/image.png", uploadedImageBlob);
            setUploadingToFirebase(false);

            if (!url) {
                setDialogModalMessage("Please choose an image less than 5MB");
                return;
            }

            setInstitution({ ...institution, image: url });
            setShouldSave(true);
        } else {
            setShouldSave(true);
        }
    };

    const onImageCropped = ({ url, blob }) => {
        setUploadedImageBlob(blob);
        setInstitution({ ...institution, image: url });
    };

    return (
        <div className="content">
            <AdminImageModal closeModal={() => setImagePicker(false)} isModal={imagePicker} aspectRatio={16 / 16} onImageCropped={onImageCropped} />
            <Row>
                <Col md="4">
                    <Card style={{ margin: "0px", borderTopRightRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "2px", borderBottomRightRadius: "2px" }}>
                        <CardImg style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "2px", borderBottomRightRadius: "2px" }} src={`${institution.image ? institution.image : "https://www.careersinafrica.com/wp-content/uploads/2020/05/placeholder.png"}`} alt="Card image cap" />
                        <CardImgOverlay>
                            <Button
                                style={{
                                    position: "absolute",
                                    bottom: "0px",
                                    right: "10px",
                                    color: "grey",
                                    backgroundColor: "white",
                                    fontSize: "24px",
                                    padding: "5px",
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                }}
                                onClick={() => setImagePicker(true)}
                            ><i className="fa fa-camera" aria-hidden="true"></i></Button>
                        </CardImgOverlay>
                    </Card>
                    <Card style={{ borderTopRightRadius: "2px", borderTopLeftRadius: "2px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                        <CardBody className="d-flex flex-column align-items-center">
                            <CardTitle tag="h4" className="text-center my-4">{institution.name || "Name"}</CardTitle>
                            <CardText tag="h5" className="mb-2 text-muted">{institution.shortname || "Shortname"}</CardText>
                        </CardBody>
                        <hr />
                        <CardFooter className="d-flex flex-column align-items-center">
                            <CardTitle tag="h5">{institution.circleCount} Circles</CardTitle>
                            <CardTitle tag="h5">{institution.memberCount} Members</CardTitle>
                        </CardFooter>
                    </Card>
                </Col>
                <Col md="8">
                    <Card className="card-user">
                        <CardHeader>
                            <Row>
                                <Col><CardTitle tag="h5">{isEdit ? "Edit Institution" : "Add Institution"}</CardTitle></Col>
                                <Col><CardTitle tag="h5" className="text-right">
                                    <Link to="/admin/institutions"><i className="nc-icon nc-simple-remove" /></Link>
                                </CardTitle>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Row>
                                    <Col md="8">
                                        <FormGroup>
                                            <label>Name of the Institution</label>
                                            <Input
                                                placeholder="Name of the Institution"
                                                type="text"
                                                value={institution.name || ''}
                                                onChange={(value) => setInstitution(state => ({ ...state, name: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <label>Short Name</label>
                                            <Input
                                                placeholder="Short Name"
                                                type="text"
                                                value={institution.shortname || ''}
                                                onChange={(value) => setInstitution(state => ({ ...state, shortname: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <Input
                                                placeholder="Description"
                                                type="textarea"
                                                value={institution.description || ''}
                                                onChange={(value) => setInstitution(state => ({ ...state, description: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <label>Total number of seats</label>
                                            <Input
                                                placeholder="Total number of seats"
                                                type="number"
                                                value={institution.limit_seats || ''}
                                                onChange={(value) => setInstitution(state => ({ ...state, limit_seats: parseInt(value.target.value) }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label>Maximum number of Circles</label>
                                            <Input
                                                placeholder="Maximum number of Circles"
                                                type="number"
                                                value={institution.limit_circles || ''}
                                                onChange={(value) => setInstitution(state => ({ ...state, limit_circles: parseInt(value.target.value) }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="3">
                                        <FormGroup>
                                            <label>Session limit</label>
                                            <Input
                                                placeholder="Maximum number of sessions per user per month"
                                                type="number"
                                                value={institution.limit_sessions || ''}
                                                onChange={(value) => setInstitution(state => ({ ...state, limit_sessions: parseInt(value.target.value) }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                            <label>Subscription ends on</label>
                                            <Input
                                                type="date"
                                                value={institution.subscription_ends_at?.split(' ')[0] || ''}
                                                onChange={(value) => setInstitution(state => ({ ...state, subscription_ends_at: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label>Domains</label>
                                            <ReactTagInput
                                                tags={institution.domains}
                                                placeholder="Type and press enter"
                                                maxTags={40}
                                                editable={true}
                                                readOnly={false}
                                                removeOnBackspace={true}
                                                onChange={d => setInstitution(state => ({ ...state, domains: d || [] }))}
                                                validator={validateDomain}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        true &&
                                        <Col md="4">

                                            <FormGroup>
                                                <FormGroup>
                                                    <Label for="exampleSelect">Audit events</Label>
                                                    <Input value={institution.is_enabled_events} type="select" onChange={e => setInstitution(i => ({ ...i, is_enabled_events: e.target.value === "true" }))}>
                                                        <option value={false}>Disabled</option>
                                                        <option value={true}>Enabled</option>
                                                    </Input>
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>
                                    }
                                    <Col md="4">
                                        <FormGroup>
                                            <label>Available coaches</label>
                                            <Input type="select" name="select"
                                                value={showApprovedCoaches}
                                                onChange={value => toggleCoachSelector(value.target.value)}>
                                                <option value={false}>All coaches</option>
                                                <option value={true}>Approved coaches only</option>
                                            </Input>
                                            {/* <Input type="checkbox" name="Approved Coaches" checked={showApprovedCoaches}
                                                    onChange={e => toggleCoachSelector(e.target.checked)}
                                                /> */}
                                        </FormGroup>
                                    </Col>
                                    {
                                        user.isSuperAdmin &&
                                        <Col md="4">
                                            <FormGroup>
                                                <label>Approved Coaches</label>
                                                <Multiselect
                                                    loading={coach.loading}
                                                    onSearch={s => setCoachSearch(s)}
                                                    options={coach.coaches}
                                                    selectedValues={institution.coaches}
                                                    onSelect={c => setInstitution(state => ({ ...state, coaches: c.map(c => ({ name: c.name, uuid: c.uuid })) }))}
                                                    onRemove={c => setInstitution(state => ({ ...state, coaches: c.map(c => ({ name: c.name, uuid: c.uuid })) }))}
                                                    displayValue="name"
                                                />
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <div className="update ml-auto mr-auto">
                                        {
                                            state.loading || uploadingToFirebase ? <Spinner color="primary" /> : <Button
                                                className="btn btn-lg btn-block"
                                                color="primary"
                                                type="button"
                                                onClick={onSave} >
                                                Save
                                            </Button>
                                        }
                                        <DialogModal isModal={dialogModal} closeModal={() => setDialogModal(false)} message={dialogModalMessage} />
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default InstitutionAddEdit;
