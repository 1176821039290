/**
 * LifeRamp
 *
 * @author      Afaan Bilal | Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

export const DEFAULTS = {
    Release: "dev", // "dev" | "prod"
};

const config = {
    apiBase: DEFAULTS.Release === "prod" ? "https://api.liferamp360.com/api" : "https://dev.api.liferamp360.com/api",
    deviceName: "Web",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    firebaseConfig: {
        apiKey: "AIzaSyC7uJ-JH9Kj10d7UvgV06DhemnFVFVvJCU",
        authDomain: "liferamp-app.firebaseapp.com",
        databaseURL: "https://liferamp-app-default-rtdb.firebaseio.com",
        projectId: "liferamp-app",
        storageBucket: "liferamp-app.appspot.com",
        messagingSenderId: "333562444380",
        appId: "1:333562444380:web:33b9c0a25c71d5d209c499",
        measurementId: "G-FRHJ5YJL76",
    },
};

export default config;
