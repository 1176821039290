/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import randomString from "@afaanbilal/random-string";

import DialogModal from "components/Modal/DialogModal";

import { Institution } from "../../api";
import { Post } from "../../api";
import { uploadToFirebase } from "../../api/utils";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Label,
    CardImg,
    Spinner,
    // CardText,
    CardImgOverlay,
} from "reactstrap";
import AdminImageModal from "components/Modal/ImagePickerAdmin";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const blankPost = { title: "", content: "", image: "", type: "post", content_type: "regular" };

const InstitutionAddEdit = (props) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.post);

    let uuid = useQuery().get("uuid");
    const isEdit = !!uuid || false;

    React.useEffect(() => {
        if (isEdit) {
            dispatch(Post.getPost({ uuid }));
        }
    }, [dispatch, isEdit, uuid]);

    const postData = state.posts ? state.posts.find(i => i.uuid === uuid) : null;
    const [post, setPost] = React.useState(isEdit ? postData : blankPost);

    const [dialogModal, setDialogModal] = React.useState(false);
    const [dialogModalMessage, setDialogModalMessage] = React.useState("");
    const [imagePicker, setImagePicker] = React.useState(false);

    const [uploadingToFirebase, setUploadingToFirebase] = React.useState(false);
    const [uploadedImageBlob, setUploadedImageBlob] = React.useState(null);
    const [shouldSave, setShouldSave] = React.useState(false);

    const institutions = useSelector(state => state.institution.institutions);
    React.useEffect(() => {
        dispatch(Institution.getInstitutions({ page: 1 }));
    }, [dispatch]);

    const circles = useSelector(state => state.institution.circles);
    React.useEffect(() => {
        if (post.institution || post.institution_uuid) {
            dispatch(Institution.getCircles({ institution: post.institution_uuid ? { uuid: post.institution_uuid } : post.institution }));
        }
    }, [dispatch, post.institution, post.institution_uuid]);

    React.useEffect(() => {
        if (shouldSave) {
            if (isEdit) {
                dispatch(Post.updatePost({ post }))
                    .then((result) => {
                        setDialogModal(true);
                        setDialogModalMessage(result.payload.status === "success" ? "Post saved!" : result.payload.message);
                        setUploadedImageBlob(null);
                    });
            } else {
                dispatch(Post.createPost({ circle_uuid: post.circle_uuid, post }))
                    .then((result) => {
                        setDialogModal(true);
                        setDialogModalMessage(result.payload.status === "success" ? "Post added!" : result.payload.message);
                        setUploadedImageBlob(null);
                        setTimeout(() => {
                            window.location = "./";
                        }, 1000);
                    });
            }

            setShouldSave(false);
        }
    }, [shouldSave, isEdit, dispatch, post]);

    const onSave = async () => {
        if (state.loading || uploadingToFirebase) return;

        if (!post.title) {
            setDialogModalMessage("Please enter a title");
            setDialogModal(true);
            return;
        }
        if (!post.content) {
            setDialogModalMessage("Please enter your content");
            setDialogModal(true);
            return;
        }
        if (["link", "video"].includes(post.content_type)) {
            try {
                new URL(post.link);
            } catch (_) {
                setDialogModalMessage("Please enter a valid URL");
                setDialogModal(true);
                return;
            }
        }
        if (post.content_type === "video" && !getYouTubeID(post.link)) {
            setDialogModalMessage("Please enter a valid Youtube URL");
            setDialogModal(true);
            return;
        }

        if (uploadedImageBlob) {
            setUploadingToFirebase(true);
            const url = await uploadToFirebase("post-images/" + randomString() + "/image.png", uploadedImageBlob);
            setUploadingToFirebase(false);

            if (!url) {
                setDialogModalMessage("Please choose an image less than 5MB");
                setDialogModal(true);
                return;
            }

            setPost({ ...post, image: url });
            setShouldSave(true);
        } else {
            setShouldSave(true);
        }
    };


    const getYouTubeID = (url = "") => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
    }

    const onImageCropped = ({ url, blob }) => {
        setUploadedImageBlob(blob);
        setPost({ ...post, image: url });
    };

    return (
        <div className="content">
            <AdminImageModal closeModal={() => setImagePicker(false)} isModal={imagePicker} aspectRatio={16 / 16} onImageCropped={onImageCropped} />
            <Row>
                <Col md="4">
                    <Card style={{ margin: "0px", borderTopRightRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "2px", borderBottomRightRadius: "2px" }}>
                        <CardImg style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "2px", borderBottomRightRadius: "2px" }} src={`${post.image ? post.image : "https://www.careersinafrica.com/wp-content/uploads/2020/05/placeholder.png"}`} alt="Card image cap" />
                        <CardImgOverlay>
                            <Button
                                style={{
                                    position: "absolute",
                                    bottom: "0px",
                                    right: "10px",
                                    color: "grey",
                                    backgroundColor: "white",
                                    fontSize: "24px",
                                    padding: "5px",
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                }}
                                onClick={() => setImagePicker(true)}
                            ><i className="fa fa-camera" aria-hidden="true"></i></Button>
                        </CardImgOverlay>
                    </Card>
                    <Card style={{ borderTopRightRadius: "2px", borderTopLeftRadius: "2px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                        <CardBody className="d-flex flex-column align-items-center">
                            <CardTitle tag="h4" className="text-center my-4">{post.title || "Title"}</CardTitle>
                        </CardBody>
                        <hr />
                        <CardFooter className="d-flex flex-column align-items-center">
                            <CardTitle tag="h5">Institution: {post.institution_uuid ? institutions.find(i => i.uuid === post.institution_uuid).name : (post.institution ? post.institution.name : "")}</CardTitle>
                        </CardFooter>
                        <CardFooter className="d-flex flex-column align-items-center">
                            <CardTitle tag="h5">Circle: {post.circle_uuid ? circles.find(c => c.uuid === post.circle_uuid).name : (post.circle ? post.circle.name : "")}</CardTitle>
                        </CardFooter>
                    </Card>
                </Col>
                <Col md="8">
                    <Card className="card-user">
                        <CardHeader>
                            <Row>
                                <Col><CardTitle tag="h5">{isEdit ? "Edit Post" : "Add Post"}</CardTitle></Col>
                                <Col><CardTitle tag="h5" className="text-right">
                                    <Link to="/admin/institutions"><i className="nc-icon nc-simple-remove" /></Link>
                                </CardTitle>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Institution</Label>
                                            <Input type="select" name="select"
                                                disabled={isEdit}
                                                value={post.institution_uuid ? post.institution_uuid : (post.institution ? post.institution.uuid : "")}
                                                onChange={(value) => setPost(state => ({ ...state, institution_uuid: value.target.value }))}>
                                                <option disabled value="">Select Institution</option>
                                                {
                                                    institutions.map(i => <option value={i.uuid} key={i.uuid}>{i.name}</option>)
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Circle</Label>
                                            <Input type="select" name="select"
                                                disabled={isEdit}
                                                value={post.circle_uuid ? post.circle_uuid : (post.circle ? post.circle.uuid : "")}
                                                onChange={(value) => setPost(state => ({ ...state, circle_uuid: value.target.value, institution_uuid: circles.find(c => c.uuid === value.target.value).institution.uuid }))}>
                                                <option disabled value="">Select Circle</option>
                                                {
                                                    circles.map(c => <option value={c.uuid} key={c.uuid}>{c.name}</option>)
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Post Category</Label>
                                            <Input type="select" name="select"
                                                disabled={isEdit}
                                                value={post.type}
                                                onChange={(value) => setPost(state => ({ ...state, type: value.target.value }))}>
                                                <option disabled value="">Show in</option>
                                                <option value={'post'} key={'post'}>Explore</option>
                                                <option value={'notice'} key={'notice'}>Message Board</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Content Type</Label>
                                            <Input type="select" name="select"
                                                value={post.content_type ? post.content_type : "regular"}
                                                onChange={(value) => setPost(state => ({ ...state, content_type: value.target.value }))}>
                                                <option disabled value="">Select Content Type</option>
                                                <option value={'regular'} key={'regular'}>Regular</option>
                                                <option value={'link'} key={'link'}>Link</option>
                                                <option value={'video'} key={'video'}>Video</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    {["link", "video"].includes(post.content_type) &&
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label>URL</Label>
                                                <Input
                                                    placeholder="Link"
                                                    type="text"
                                                    value={post.link || ''}
                                                    onChange={(value) => setPost(state => ({ ...state, link: value.target.value }))}
                                                />
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <label>Post title</label>
                                            <Input
                                                placeholder="Post title"
                                                type="text"
                                                value={post.title || ''}
                                                onChange={(value) => setPost(state => ({ ...state, title: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Content</Label>
                                            <Input
                                                placeholder="Content"
                                                style={{ resize: 'vertical', maxHeight: 600, minHeight: 300 }}
                                                type="textarea"
                                                value={post.content || ''}
                                                onChange={(value) => setPost(state => ({ ...state, content: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="update ml-auto mr-auto">
                                        {
                                            state.loading || uploadingToFirebase ? <Spinner color="primary" /> : <Button
                                                className="btn btn-lg btn-block"
                                                color="primary"
                                                type="button"
                                                onClick={onSave}
                                            >
                                                Save
                                            </Button>
                                        }
                                        <DialogModal isModal={dialogModal} closeModal={() => setDialogModal(false)} message={dialogModalMessage} />
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default InstitutionAddEdit;
