/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { configureStore, getDefaultMiddleware, combineReducers } from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./slices/userSlice";
import institutionReducer from "./slices/institutionSlice";
import circleReducer from "./slices/circleSlice";
import postReducer from "./slices/postSlice";
import resourceReducer from "./slices/resourceSlice";
import coachReducer from "./slices/coachSlice";
import clientReducer from "./slices/clientSlice";
import adminReducer from "./slices/adminSlice";
import dashboardReducer from "./slices/dashboardSlice";
import reportReducer from "./slices/reportSlice";
import coachingSessionReducer from "./slices/coachingSessionSlice";
import eventReducer from "./slices/eventSlice";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
};

const rootReducer = combineReducers({
    user: userReducer,
    institution: institutionReducer,
    circle: circleReducer,
    post: postReducer,
    resource: resourceReducer,
    coach: coachReducer,
    client: clientReducer,
    admin: adminReducer,
    dashboard: dashboardReducer,
    report: reportReducer,
    coachingSession: coachingSessionReducer,
    event: eventReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

export const persistor = persistStore(store);
