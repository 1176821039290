/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const getCircles = createAsyncThunk(
    "circles/getCircles",
    async ({ page, search = "" }, thunkAPI) => apiThunkHandler(axios.get("/circles?page=" + page + (search ? "&search=" + search : "")), thunkAPI)
);

const createCircle = createAsyncThunk(
    "circles/createCircle",
    async ({ circle }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/circles",
            {
                ...circle
            }
        ),
        thunkAPI
    )
);

const updateCircle = createAsyncThunk(
    "circles/updateCircle",
    async ({ circle }, thunkAPI) => apiThunkHandler(
        axios.put(
            "/circles/" + circle.uuid,
            {
                ...circle
            }
        ),
        thunkAPI
    )
);

const deleteCircle = createAsyncThunk(
    "circles/deleteCircle",
    async ({ circle }, thunkAPI) => apiThunkHandler(axios.delete("/circles/" + circle.uuid), thunkAPI)
);

const circle = {
    getCircles,
    createCircle,
    updateCircle,
    deleteCircle,
};

export default circle;
