/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const getCoachingSessions = createAsyncThunk(
    "coachingSessions/getCoachingSessions",
    async ({ page, search = "", filters = "" }, thunkAPI) => apiThunkHandler(axios.get("/coaching-sessions?page=" + page + (search ? "&search=" + search : "") + (filters ? "&filters=" + filters : "")), thunkAPI)
);

const getUpcomingSessions = createAsyncThunk(
    "coachingSessions/getUpcomingSessions",
    async ({ page }, thunkAPI) => apiThunkHandler(axios.get("/coaching-sessions/upcoming?page=" + page), thunkAPI)
);

const getPastSessions = createAsyncThunk(
    "coachingSessions/getPastSessions",
    async ({ page }, thunkAPI) => apiThunkHandler(axios.get("/coaching-sessions/past?page=" + page), thunkAPI)
);

const coachingSession = {
    getCoachingSessions,
    getUpcomingSessions,
    getPastSessions,
};

export default coachingSession;
