/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import randomString from "@afaanbilal/random-string";

import DialogModal from "../../components/Modal/DialogModal";
import CoachMatchingSetup from "./CoachMatchingSetup";

import { User, } from "../../api";
import { uploadToFirebase } from "../../api/utils";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    CardImg,
    Label,
    Spinner,
    CardImgOverlay
} from "reactstrap";
import AdminImageModal from "components/Modal/ImagePickerAdmin";

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const CoachProfile = (props) => {
    const dispatch = useDispatch();

    const state = useSelector(state => state.user || null);
    const loading = useSelector(state => state.user ? state.user.loading : false);

    const userProfile = useSelector(state => state.user.profile);

    const [city, setCity] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [postalCode, setPostalCode] = React.useState("");
    const [address, setAddress] = React.useState("");

    const parseLocation = (locationStr) => {
        if (locationStr != null && isJsonString(locationStr)) {
            const location = JSON.parse(locationStr);
            setCity(location.city);
            setCountry(location.country);
            setAddress(location.address);
            setPostalCode(location.postalCode);
        };
    };

    React.useEffect(() => {
        parseLocation(userProfile.location);
    }, [userProfile.location]);

    const [profile, setProfile] = React.useState(userProfile);

    const history = useHistory();
    if (!profile.isCoach) {
        history.push("../");
    }

    const [dialogModal, setDialogModal] = React.useState(false);
    const [dialogModalMessage, setDialogModalMessage] = React.useState("");
    const [imagePickerProfile, setImagePickerProfile] = React.useState(false);
    const [imagePickerCover, setImagePickerCover] = React.useState(false);

    const [uploadingToFirebase, setUploadingToFirebase] = React.useState(false);
    const [uploadedImageBlobProfile, setUploadedImageBlobProfile] = React.useState(null);
    const [uploadedImageBlobCover, setUploadedImageBlobCover] = React.useState(null);
    const [shouldSave, setShouldSave] = React.useState(false);

    const setDialog = (result) => {
        setDialogModalMessage(result.payload.status === "success" ? "Profile updated!" : result.payload.message);
        setDialogModal(true);
        setUploadedImageBlobProfile(null);
        setUploadedImageBlobCover(null);
    };

    const onMatchingProfileSetup = () => {
        setDialogModalMessage("Matching profile updated!");
        setDialogModal(true);
    };

    React.useEffect(() => {
        if (shouldSave) {
            dispatch(User.updateProfile({ profile })).then((result) => setDialog(result));
            setShouldSave(false);
        }
    }, [shouldSave, dispatch, profile]);

    const onSave = async () => {
        if (state.loading || uploadingToFirebase) return;

        setProfile(state => ({ ...state, location: JSON.stringify({ city: city, country: country, postalCode: postalCode, address: address }) }));

        if (!uploadedImageBlobProfile && !uploadedImageBlobCover) {
            setShouldSave(true);
            return;
        }

        if (uploadedImageBlobProfile || uploadedImageBlobCover) {
            setUploadingToFirebase(true);
            let urlProfile = null;

            if (uploadedImageBlobProfile) {
                urlProfile = await uploadToFirebase("user-images/" + randomString() + "/profile.png", uploadedImageBlobProfile);

                if (!urlProfile) {
                    setDialogModalMessage("Please choose an image less than 5MB");
                    return;
                }

                setProfile({ ...profile, image_profile: urlProfile });
            }

            if (uploadedImageBlobCover) {
                const urlCover = await uploadToFirebase("user-images/" + randomString() + "/cover.png", uploadedImageBlobCover);

                if (!urlCover) {
                    setDialogModalMessage("Please choose an image less than 5MB");
                    return;
                }

                if (uploadedImageBlobProfile) {
                    setProfile({ ...profile, image_profile: urlProfile, image_cover: urlCover });
                } else {
                    setProfile({ ...profile, image_cover: urlCover });
                }
            }

            setShouldSave(true);
            setUploadingToFirebase(false);
        }
    };

    const onImageCroppedProfile = ({ url, blob }) => {
        setUploadedImageBlobProfile(blob);
        setProfile({ ...profile, image_profile: url });
    };

    const onImageCroppedCover = ({ url, blob }) => {
        setUploadedImageBlobCover(blob);
        setProfile({ ...profile, image_cover: url });
    };

    return (
        <div className="content">
            <AdminImageModal closeModal={() => setImagePickerProfile(false)} isModal={imagePickerProfile} aspectRatio={16 / 16} onImageCropped={onImageCroppedProfile} />
            <AdminImageModal closeModal={() => setImagePickerCover(false)} isModal={imagePickerCover} aspectRatio={16 / 9} onImageCropped={onImageCroppedCover} />
            <Row>
                <Col md="4">
                    <Card style={{ margin: "0px", borderTopRightRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "2px", borderBottomRightRadius: "2px" }}>
                        <CardImg style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "2px", borderBottomRightRadius: "2px" }} top width="100%" src={`${profile.image_cover ? profile.image_cover : "https://www.careersinafrica.com/wp-content/uploads/2020/05/placeholder.png"}`} alt="Card image cap" />
                        <CardImgOverlay>
                            <Button
                                style={{
                                    position: "absolute",
                                    bottom: "0px",
                                    right: "10px",
                                    color: "grey",
                                    backgroundColor: "white",
                                    fontSize: "24px",
                                    padding: "5px",
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                }}
                                onClick={() => setImagePickerCover(true)}
                            ><i className="fa fa-camera" aria-hidden="true"></i></Button>
                        </CardImgOverlay>
                    </Card>
                    <Card className="card-user" style={{ borderTopRightRadius: "2px", borderTopLeftRadius: "2px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                        <CardBody>
                            <div className="author update ml-auto mr-auto" style={{ width: "75%" }}>
                                <img
                                    alt="..."
                                    className="avatar border-gray"
                                    src={`${profile.image_profile ? profile.image_profile : "https://www.careersinafrica.com/wp-content/uploads/2020/05/placeholder.png"}`}
                                />
                                <Button
                                    style={{
                                        fontSize: "24px",
                                        position: "absolute",
                                        left: "58%",
                                        top: "0%",
                                        color: "grey",
                                        backgroundColor: "white",
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                        padding: "5px"
                                    }}
                                    onClick={() => setImagePickerProfile(true)}>
                                    <i className="fa fa-camera"></i>
                                </Button>
                                <h5 className="title">{(profile.firstname != null && profile.firstname !== "") || (profile.lastname != null && profile.lastname !== "") ? `${profile.firstname} ` : "Name "}{profile.lastname != null && profile.lastname !== "" ? `${profile.lastname}` : ""}</h5>
                                <p className="text-dark">{profile.email ? profile.email : "Email"}</p>
                                <p className="description">{profile.circle ? profile.circle.name : "Circle"}</p>

                            </div>
                        </CardBody>
                    </Card>
                    <CoachMatchingSetup onMatchingProfileSetup={onMatchingProfileSetup} />
                </Col>
                <Col md="8">
                    <Card className="card-user">
                        <CardHeader>
                            <Row>
                                <Col><CardTitle tag="h5">Edit Profile</CardTitle></Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>First name</Label>
                                            <Input type="text" name="fname" id="fname" value={profile.firstname || ''}
                                                onChange={(value) => setProfile(state => ({ ...state, firstname: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Last name</Label>
                                            <Input type="text" name="lname" id="lname" value={profile.lastname || ''}
                                                onChange={(value) => setProfile(state => ({ ...state, lastname: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Phone Number</Label>
                                            <Input type="number" name="phone" id="phone" placeholder="Enter your phone number" value={profile.phone || ''}
                                                onChange={(value) => setProfile(state => ({ ...state, phone: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <label>Maximum concurrent clients</label>
                                            <Input
                                                placeholder="Maximum concurrent clients"
                                                type="number"
                                                value={profile.limit_clients || ''}
                                                onChange={(value) => setProfile(state => ({ ...state, limit_clients: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <label disabled={true}>Maximum Sessions per month</label>
                                            <Input
                                                placeholder="Maximum sessions per month"
                                                type="number"
                                                value={profile.limit_sessions || ''}
                                                onChange={(value) => setProfile(state => ({ ...state, limit_sessions: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Bio Video</Label>
                                            <Input type="email" name="calendly_email" id="calendly_email" placeholder="Bio Video URL" value={profile.bio_video || ''}
                                                onChange={(value) => setProfile(state => ({ ...state, bio_video: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Calendly email</Label>
                                            <Input type="email" name="calendly_email" id="calendly_email" placeholder="Enter your Calendly email" value={profile.calendly_email || ''}
                                                onChange={(value) => setProfile(state => ({ ...state, calendly_email: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Prefix</Label>
                                            <Input type="email" name="calendly_email" id="calendly_email" placeholder="Enter a Prefix" value={profile.prefix || ''}
                                                onChange={(value) => setProfile(state => ({ ...state, prefix: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Zoom URL</Label>
                                            <Input type="text" name="zoom_url" id="zoom_url" placeholder="Enter your Zoom URL" value={profile.zoom_url || ''}
                                                onChange={(value) => setProfile(state => ({ ...state, zoom_url: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label>Address</Label>
                                    <Input type="text" name="address" id="address" value={address || ''}
                                        onChange={(value) => setAddress(value.target.value)}
                                    />
                                </FormGroup>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>City</Label>
                                            <Input type="text" name="city" id="city" placeholder="Enter city" value={city || ''}
                                                onChange={(value) => setCity(value.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Country</Label>
                                            <Input type="text" name="country" id="country" placeholder="Entry Country" value={country || ''}
                                                onChange={(value) => setCountry(value.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Postal Code</Label>
                                            <Input type="number" name="pincode" id="pincode" placeholder="Enter Postal Code" value={postalCode || ''}
                                                onChange={(value) => setPostalCode(value.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={4}>
                                        <Label>Gender</Label>
                                        <FormGroup>
                                            <Input type="text" name="gender" id="gender" value={profile.gender || ''}
                                                onChange={(value) => setProfile(state => ({ ...state, gender: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>DOB</Label>
                                            <Input type="date" name="dob" id="dob" value={profile.dob ? profile.dob.split(' ')[0] : ''}
                                                onChange={(value) => setProfile(state => ({ ...state, dob: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Pronouns</Label>
                                            <Input type="text" name="pronouns" id="pronouns" value={profile.pronouns || ''}
                                                onChange={(value) => setProfile(state => ({ ...state, pronouns: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label>Role</Label>
                                    <Input type="text" name="state" value={profile.role || ''}
                                        onChange={(value) => setProfile(state => ({ ...state, role: value.target.value }))}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>About</Label>
                                    <Input type="textarea" name="state" value={profile.about || ''}
                                        onChange={(value) => setProfile(state => ({ ...state, about: value.target.value }))}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Headline</Label>
                                    <Input type="textarea" name="state" value={profile.headline || ''}
                                        onChange={(value) => setProfile(state => ({ ...state, headline: value.target.value }))} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Bio</Label>
                                    <Input type="textarea" name="state" value={profile.bio || ''}
                                        onChange={(value) => setProfile(state => ({ ...state, bio: value.target.value }))}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Education</Label>
                                    <Input type="textarea" name="city" value={profile.education || ''}
                                        onChange={(value) => setProfile(state => ({ ...state, education: value.target.value }))}
                                    />
                                </FormGroup>
                                <Row>
                                    <div className="update ml-auto mr-auto">
                                        {
                                            loading || uploadingToFirebase ? <Spinner color="primary" /> : <Button
                                                className="btn btn-lg btn-block"
                                                color="primary"
                                                type="button"
                                                onClick={onSave}
                                            >
                                                Save
                                            </Button>
                                        }
                                        <DialogModal isModal={dialogModal} closeModal={() => setDialogModal(false)} message={dialogModalMessage} />
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CoachProfile;
