/**
 * LifeRamp
 *
 * @author      Burhan Rashid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { setAuthToken } from "./api";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

import { useSelector } from "react-redux";

function App() {
    const authToken = useSelector(state => state.user.token);
    const user = useSelector(state => state.user.profile);
    const isCoach = user?.isCoach;
    const isSignedIn = authToken !== null;

    if (isSignedIn) {
        setAuthToken(authToken);
    }

    return (
        <BrowserRouter>
            <Switch>
                {isSignedIn ?
                    <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                    :
                    <>
                        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                        <Redirect to="/auth/sign-in" />
                    </>
                }
                {
                    isCoach ?
                        <Redirect path="/" to="/admin/coach-profile" />
                        :
                        <Redirect path="/" to="/admin/dashboard" />
                }
            </Switch>
        </BrowserRouter>
    );
};
export default App;
