/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button,
    ButtonGroup,
} from "reactstrap";

import Search from "../../components/utils/Search";
import TableLoading from "../../components/utils/TableLoading";
import MainPagination from "../../components/utils/MainPagination";
import DialogModal from "components/Modal/DialogModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";

import { Institution } from "../../api";
import { prevPage, nextPage } from "../../state/slices/institutionSlice";

function InstitutionList() {
    const dispatch = useDispatch();
    const state = useSelector(state => state.institution);

    const [search, setSearch] = React.useState("");

    React.useEffect(() => {
        dispatch(Institution.getInstitutions({ page: state.page, search }));
    }, [dispatch, state.page, search]);

    const [dialogModal, setDialogModal] = React.useState(false);
    const [dialogModalMessage, setDialogModalMessage] = React.useState("");

    const [displayConfirmationModal, setDisplayConfirmationModal] = React.useState(false);
    const [confirmationModalData, setConfirmationModalData] = React.useState({ message: "", id: "" });

    const confirmDeleteInstitution = (obj) => {
        if (true) return alert("Deletion unavailable at the moment");

        setConfirmationModalData({ message: "You are about to delete " + obj.name + ". Are you sure?", obj: obj })
        setDisplayConfirmationModal(true);
    };

    const hideConfirmationModal = () => setDisplayConfirmationModal(false);

    const deleteInstitution = (institution) => {
        dispatch(Institution.deleteInstitution({ institution }))
            .then((result) => {
                hideConfirmationModal();
                setDialogModal(true);
                setDialogModalMessage(result.payload.status === "success" ? "Institution deleted!" : result.payload.message);
            });
    }

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col md="6">
                                    <CardTitle tag="h4">Institutions</CardTitle>
                                </Col>
                                <Col md="6">
                                    <Row style={{ justifyContent: "flex-end" }}>
                                        <Col md="7" className="d-flex justify-content-end">
                                            <div className="ml-2 d-inline-block">
                                            <Link to="/admin/institutions/addEdit?id="><Button color="primary" style={{ marginTop: "15px" }}>Add</Button></Link>
                                            </div>
                                        </Col>
                                        <Col md="4" className="pt-3 mr-3">
                                            <Search value={search} onChange={setSearch} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead className="text-primary">
                                    <tr>
                                        <th>Name</th>
                                        <th>Shortname</th>
                                        <th className="text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state.institutions.map(i =>
                                            <tr key={i.uuid}>
                                                <td>{i.name}</td>
                                                <td>{i.shortname}</td>
                                                <td className="text-right">
                                                    <ButtonGroup size="sm">
                                                        <Link to={"/admin/institutions/addEdit?uuid=" + i.uuid}>
                                                            <Button color="info mr-2" className="btn-link">
                                                                <i className="fa fa-pen"></i>
                                                            </Button>
                                                        </Link>
                                                        <Button color="danger" className="btn-link" onClick={() => confirmDeleteInstitution(i)}><i className="fa fa-trash"></i></Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {state.loading && <TableLoading colSpan={3} />}
                                    {!state.loading && !state.institutions.length &&
                                        <tr>
                                            <td className="text-center py-4" colSpan={3}>
                                                <h5>{search ? "No result." : "There's nothing but empty space."}</h5>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            <MainPagination page={state.page} prevPage={() => dispatch(prevPage())} nextPage={() => dispatch(nextPage())} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <DialogModal message={dialogModalMessage} closeModal={() => setDialogModal(false)} isModal={dialogModal} />
            <ConfirmationModal type="delete" showModal={displayConfirmationModal} confirmModal={deleteInstitution} hideModal={hideConfirmationModal} data={confirmationModalData} />
        </div>
    );
}

export default InstitutionList;
