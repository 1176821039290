/**
 * LifeRamp
 *
 * @author      Burhan Rashid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import { Dashboard } from "../../api";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import DialogModal from "components/Modal/DialogModal";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Label,
    Spinner,
} from "reactstrap";

const Settings = (props) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.dashboard);

    const [settings, setSettings] = React.useState({});

    React.useEffect(() => {
        dispatch(Dashboard.getSettings());
    }, [dispatch]);

    React.useEffect(() => {
        setSettings(state.settings);
    }, [state.settings])

    const onSave = () => {
        dispatch(Dashboard.saveSettings({ settings })).then((result) => {
            setDialogModal(true);
            setDialogModalMessage(result.payload.status === "success" ? "Settings saved!" : result.payload.message);
        });
    };

    const reRunCalendly = () => {
        dispatch(Dashboard.reRunCalendly({})).then((result) => {
            setDialogModal(true);
            setDialogModalMessage(result.payload.status === "success" ? "Success! It may take a couple of minutes for changes to reflect." : result.payload.message);
        });
    };

    const [dialogModal, setDialogModal] = React.useState(false);
    const [dialogModalMessage, setDialogModalMessage] = React.useState("");

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card className="card-user">
                        <CardHeader>
                            <Row>
                                <Col><CardTitle tag="h5">Settings</CardTitle></Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Maximum Sessions Per Month Per Client</Label>
                                            <Input
                                                placeholder=""
                                                type="number"
                                                value={settings.limit_sessions_client || ''}
                                                onChange={(value) => setSettings({ ...settings, limit_sessions_client: value.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Maximum Sessions Per Month Per Coach</Label>
                                            <Input
                                                placeholder=""
                                                type="number"
                                                value={settings.limit_sessions_coach || ''}
                                                onChange={(value) => setSettings({ ...settings, limit_sessions_coach: value.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Instant Zoom Meeting Session Duration(Minutes)</Label>
                                            <Input
                                                placeholder=""
                                                type="number"
                                                value={settings.instant_session_time_minutes || ''}
                                                onChange={(value) => setSettings({ ...settings, instant_session_time_minutes: value.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Stripe Price ID</Label>
                                            <Input
                                                placeholder=""
                                                type="text"
                                                value={settings.stripe_price_id || ''}
                                                onChange={(value) => setSettings({ ...settings, stripe_price_id: value.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>D2C enabled</Label>
                                            <Input type="select" name="select" style={{ maxWidth: 200 }}
                                                value={settings.d2c_enabled !== '0' ? "Enabled" : "Disabled"}
                                                onChange={(value) => setSettings({ ...settings, d2c_enabled: value.target.value === "Enabled" })}>
                                                <option value="Disabled">Disabled</option>
                                                <option value="Enabled">Enabled</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {
                                    settings.d2c_enabled === '0' &&
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>D2C disabled message</Label>
                                                <Input
                                                    placeholder=""
                                                    type="text"
                                                    value={settings.d2c_disabled_message || ''}
                                                    onChange={(value) => setSettings({ ...settings, d2c_disabled_message: value.target.value })}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <div className="update ml-auto mr-auto">
                                        {
                                            state.loading ? <Spinner color="primary" /> : <Button
                                                className="btn btn-lg btn-block"
                                                color="primary"
                                                type="button"
                                                onClick={onSave}
                                            >
                                                Save
                                            </Button>
                                        }
                                    </div>
                                    <DialogModal isModal={dialogModal} closeModal={() => setDialogModal(false)} message={dialogModalMessage} />
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <Card className="card-user">
                        <CardHeader>
                            <Row>
                                <Col><CardTitle tag="h5">Actions</CardTitle></Col>
                            </Row>
                        </CardHeader>
                        <CardBody style={{ minHeight: 150 }}>
                            <Row>
                                <div className="update ml-auto mr-auto">
                                    <Button
                                        className="btn btn-lg btn-block"
                                        color="primary"
                                        type="button"
                                        onClick={reRunCalendly}>
                                        Refresh Calendly
                                    </Button>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Settings;
