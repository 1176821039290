/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pie, Bar } from "react-chartjs-2";

import ModalFilter from "../components/Modal/ModalFilter";


import { Dashboard } from "../api";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Col,
    Row,
    Spinner,
    Button,
} from "reactstrap";

// core components
import {
    pieChartOptions,
    dashboardSessionStatsChartFn,
    dashboardGoalsChartFn,
    dashboardGoalsCategoryChartFn,

    barChartOptions,
    dashboardUserTimeChartFn,
    dashboardSessionChartFn,
    // dashboard24HoursPerformanceChart,
    // dashboardNASDAQChart,
} from "variables/charts.js";

function DashboardView() {
    const dispatch = useDispatch();

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const state = useSelector(state => state.dashboard);

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const [filters, setFilters] = React.useState([]);
    const [filterModalData, setFilterModalData] = React.useState({
        loadData: true,
        search: {},
    });

    React.useEffect(() => {
        dispatch(Dashboard.getDashboardData({ filters: filters.length && JSON.stringify(filters) }))
            .unwrap()
            .then(() => forceUpdate());
    }, [dispatch, filters, forceUpdate]);

    const dashboardClientsOverTimeChart = React.useRef();
    const dashboardCoachesOverTimeChart = React.useRef();
    const dashboardSessionStatsChart = React.useRef();
    const dashboardGoalsChart = React.useRef();
    const dashboardGoalsCategoryChart = React.useRef();
    const dashboardSessionRatingChart = React.useRef();
    const dashboardCancelReasonCoachSessionChart = React.useRef();
    const dashboardCancelReasonClientSessionChart = React.useRef();
    React.useEffect(() => {
        if (typeof state.data?.activeSessionCount !== "undefined") {
            dashboardClientsOverTimeChart.current = dashboardUserTimeChartFn(state.data.clientsOverTime);
            dashboardCoachesOverTimeChart.current = dashboardUserTimeChartFn(state.data.coachesOverTime, true);
            dashboardSessionStatsChart.current = dashboardSessionStatsChartFn(state.data.activeSessionCount, state.data.completedSessionCount, state.data.cancelledSessionCount);
            dashboardGoalsChart.current = dashboardGoalsChartFn(state.data.totalGoalCount - state.data.completedGoalCount, state.data.completedGoalCount);
            dashboardGoalsCategoryChart.current = dashboardGoalsCategoryChartFn(state.data.goalsProfessionalCount, state.data.goalsEducationalCount, state.data.goalsPersonalCount);
            dashboardSessionRatingChart.current = dashboardSessionChartFn(state.data.sessionsByRating);
            dashboardCancelReasonCoachSessionChart.current = dashboardSessionChartFn(state.data.sessionsByCoachCancellationReason);
            dashboardCancelReasonClientSessionChart.current = dashboardSessionChartFn(state.data.sessionsByClientCancellationReason);
        }
    }, [state.data]);

    return (
        state.loading
            ? <div className="content text-center py-5"><Spinner color="primary" /></div>
            :
            <div className="content">
                <Row style={{ justifyContent: "flex-end", margin: 0, marginBottom: "20px" }}>
                    <div className="ml-2 d-inline-block">
                        {!filters.length ?
                            <Button className={'btn-round'} style={{ marginTop: "4px" }} color="primary" onClick={() => setShowFilterModal(true)}>Apply Filters</Button>
                            :
                            <div style={{ position: 'relative' }}>
                                <Button className={'btn-round'} style={{ marginTop: "4px" }} color="warning" onClick={() => setShowFilterModal(true)}>Edit Filters</Button>
                                <button className="close" style={{ position: 'absolute', top: '35%', right: '-18px' }} onClick={() => setFilters([])}>×</button>
                            </div>
                        }
                    </div>
                </Row>
                <Row>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-hat-3 text-primary" />
                                        </div>
                                    </Col>
                                    <Col md="9" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Institutions</p>
                                            <CardTitle tag="p">{state.data?.totalInstitutionCount}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-planet text-primary" />
                                        </div>
                                    </Col>
                                    <Col md="9" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Circles</p>
                                            <CardTitle tag="p">{state.data?.totalCircleCount}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-badge text-primary" />
                                        </div>
                                    </Col>
                                    <Col md="9" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Clients</p>
                                            <CardTitle tag="p">{state.data?.totalClientCount}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-circle-10 text-primary" />
                                        </div>
                                    </Col>
                                    <Col md="9" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Coaches</p>
                                            <CardTitle tag="p">{state.data?.totalCoachCount}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-calendar-60 text-primary" />
                                        </div>
                                    </Col>
                                    <Col md="9" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Active Sessions</p>
                                            <CardTitle tag="p">{state.data?.activeSessionCount}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-calendar-60 text-success" />
                                        </div>
                                    </Col>
                                    <Col md="9" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Completed Sessions</p>
                                            <CardTitle tag="p">{state.data?.completedSessionCount}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-calendar-60 text-warning" />
                                        </div>
                                    </Col>
                                    <Col md="9" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Total Sessions</p>
                                            <CardTitle tag="p">{state.data?.totalSessionCount}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-calendar-60 text-danger" />
                                        </div>
                                    </Col>
                                    <Col md="9" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Cancelled Sessions</p>
                                            <CardTitle tag="p">{state.data?.cancelledSessionCount}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-user-run text-primary" />
                                        </div>
                                    </Col>
                                    <Col md="9" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Total Goals</p>
                                            <CardTitle tag="p">{state.data?.totalGoalCount}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-satisfied text-success" />
                                        </div>
                                    </Col>
                                    <Col md="9" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Completed Goals</p>
                                            <CardTitle tag="p">{state.data?.completedGoalCount}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-single-copy-04 text-primary" />
                                        </div>
                                    </Col>
                                    <Col md="9" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Resources</p>
                                            <CardTitle tag="p">{state.data?.totalResourceCount}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-paper text-primary" />
                                        </div>
                                    </Col>
                                    <Col md="9" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Posts</p>
                                            <CardTitle tag="p">{state.data?.totalPostCount}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    {typeof dashboardSessionStatsChart.current !== "undefined" &&
                        <Col md="4">

                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h5">Coaching Sessions</CardTitle>
                                    <p className="card-category">All time</p>
                                </CardHeader>
                                <CardBody style={{ height: "266px" }}>
                                    <Pie
                                        data={dashboardSessionStatsChart.current}
                                        options={pieChartOptions}
                                    />
                                </CardBody>
                                <CardFooter>
                                    <div className="legend">
                                        <i className="fa fa-circle text-primary" /> Active{" "}
                                        <i className="fa fa-circle text-success" /> Completed{" "}
                                        <i className="fa fa-circle text-danger" /> Cancelled{" "}
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    }
                    {typeof dashboardGoalsChart.current !== "undefined" &&
                        <Col md="4">

                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h5">Goals</CardTitle>
                                    <p className="card-category">All time</p>
                                </CardHeader>
                                <CardBody style={{ height: "266px" }}>
                                    <Pie
                                        data={dashboardGoalsChart.current}
                                        options={pieChartOptions}
                                    />
                                </CardBody>
                                <CardFooter>
                                    <div className="legend">
                                        <i className="fa fa-circle text-primary" /> Active{" "}
                                        <i className="fa fa-circle text-success" /> Completed{" "}
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    }
                    {typeof dashboardGoalsCategoryChart.current !== "undefined" &&
                        <Col md="4">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h5">Goals</CardTitle>
                                    <p className="card-category">Categorized</p>
                                </CardHeader>
                                <CardBody style={{ height: "266px" }}>
                                    <Pie
                                        data={dashboardGoalsCategoryChart.current}
                                        options={pieChartOptions}
                                    />
                                </CardBody>
                                <CardFooter>
                                    <div className="legend">
                                        <i className="fa fa-circle text-primary" /> Professional{" "}
                                        <i className="fa fa-circle text-success" /> Educational{" "}
                                        <i className="fa fa-circle" style={{ color: "orange" }} /> Personal{" "}
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    }
                    {/* <Col md="8">
                        <Card className="card-chart">
                            <CardHeader>
                                <CardTitle tag="h5">NASDAQ: AAPL</CardTitle>
                                <p className="card-category">Line Chart with Points</p>
                            </CardHeader>
                            <CardBody>
                                <Line
                                    data={dashboardNASDAQChart.data}
                                    options={dashboardNASDAQChart.options}
                                    width={400}
                                    height={100}
                                />
                            </CardBody>
                            <CardFooter>
                                <div className="chart-legend">
                                    <i className="fa fa-circle text-info" /> Tesla Model S{" "}
                                    <i className="fa fa-circle text-warning" /> BMW 5 Series
                                </div>
                                <hr />
                                <div className="card-stats">
                                    <i className="fa fa-check" /> Data information certified
                                </div>
                            </CardFooter>
                        </Card>
                    </Col> */}
                </Row>
                <Row>
                    {typeof dashboardSessionRatingChart.current !== "undefined" &&
                        <Col md="4">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h5">Completed Sessions</CardTitle>
                                    <p className="card-category">by ⭐ rating</p>
                                </CardHeader>
                                <CardBody>
                                    <Bar data={dashboardSessionRatingChart.current} options={barChartOptions} />
                                </CardBody>
                            </Card>
                        </Col>
                    }
                    {typeof dashboardCancelReasonCoachSessionChart.current !== "undefined" &&
                        <Col md="4">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h5">Cancelled Sessions</CardTitle>
                                    <p className="card-category">Coach Reason</p>
                                </CardHeader>
                                <CardBody>
                                    <Bar data={dashboardCancelReasonCoachSessionChart.current} options={barChartOptions} />
                                </CardBody>
                            </Card>
                        </Col>
                    }
                    {typeof dashboardCancelReasonClientSessionChart.current !== "undefined" &&
                        <Col md="4">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h5">Cancelled Sessions</CardTitle>
                                    <p className="card-category">Client Reason</p>
                                </CardHeader>
                                <CardBody>
                                    <Bar data={dashboardCancelReasonClientSessionChart.current} options={barChartOptions} />
                                </CardBody>
                            </Card>
                        </Col>
                    }
                </Row>

                <Row>
                    <Col md="6">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h5">Client acquisition</CardTitle>
                                <p className="card-category">Last 14 days</p>
                            </CardHeader>
                            <CardBody>
                                <Bar data={dashboardClientsOverTimeChart.current} options={barChartOptions} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h5">Coach acquisition</CardTitle>
                                <p className="card-category">Last 14 days</p>
                            </CardHeader>
                            <CardBody>
                                <Bar data={dashboardCoachesOverTimeChart.current} options={barChartOptions} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ModalFilter showModal={showFilterModal} hideModal={() => setShowFilterModal(false)} filterModalData={filterModalData} setFilterModalData={setFilterModalData} selectedFilter={filters} setFilter={setFilters} types={['institution', 'circle']} />
            </div>
    );
}

export default DashboardView;
