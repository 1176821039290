/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import firebase from "firebase/app";
import "firebase/storage";

export const apiThunkHandler = async (asyncFn, { rejectWithValue }) => {
    try {
        return (await asyncFn).data;
    } catch (err) {
        console.log("ApiThunkHandlerError: ", err);
        return rejectWithValue(err.response.data);
    }
};

export const uploadToFirebase = async (refPath, blob) => {
    const ref = firebase.storage().ref(refPath);

    if (blob.size <= (5 * 1024 * 1024)) {
        await ref.put(blob, { contentType: "image/png" });
        const url = await ref.getDownloadURL();
        return url + (url.includes("?") ? "&" : "?")  + "ts=" + (new Date().getTime().toString());
    } else {
        return false;
    }
};
