/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Route } from "react-router-dom";

import ClientsNeedingCoachList from "./ClientsNeedingCoachList";
import ClientSetCoach from "./ClientSetCoach";

function ClientsNeedingCoach() {
    return (
        <>
            <Route path="/admin/set-coach" exact component={ClientsNeedingCoachList}></Route>
            <Route path="/admin/set-coach/update" exact component={ClientSetCoach}></Route>
        </>
    );
};

export default ClientsNeedingCoach;
