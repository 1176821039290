/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { setAuthToken, User } from "../../api";
import { setIsLoading, setError } from "../utils";

import config from "../../api/config";
import firebase from "firebase/app";
import "firebase/auth";

firebase.initializeApp(config.firebaseConfig);
const signInToFirebase = async (firebaseToken) => await firebase.auth().signInWithCustomToken(firebaseToken);
const singOutOfFirebase = async () => await firebase.auth().signOut();

export const userSlice = createSlice({
    name: "user",
    initialState: {
        loading: false,
        error: "",

        token: null,
        profile: {},

        resetData: {
            stage: User.resetStages.REQUEST,
            token: null,
            uuid: null,
        },
    },
    reducers: {
        setLoading(state, { payload }) {
            state.loading = payload;
        },
        setErrorMessage(state, { payload }) {
            state.error = payload;
        },
        clearError(state) {
            state.error = "";
        },
        setResetStage(state, { payload: { stage } }) {
            state.resetData.stage = stage;
        },
        clearAuthToken(state) {
            state.token = null;
        },
    },
    extraReducers: {
        [User.signUp.pending]: setIsLoading(true),
        [User.signIn.pending]: setIsLoading(true),
        [User.signOut.pending]: setIsLoading(true),
        [User.requestResetPassword.pending]: setIsLoading(true),
        [User.verifyCode.pending]: setIsLoading(true),
        [User.resetPassword.pending]: setIsLoading(true),

        [User.updateProfile.pending]: setIsLoading(true),
        [User.getMyProfile.pending]: setIsLoading(true),

        [User.signUp.fulfilled]: (state, { payload: { token, me, firebaseToken } }) => {
            signInToFirebase(firebaseToken);

            state.token = token;
            state.profile = me;
            state.error = "";

            setAuthToken(state.token);
            setIsLoading(false)(state);
        },
        [User.signIn.fulfilled]: (state, { payload: { token, me, firebaseToken } }) => {
            signInToFirebase(firebaseToken);

            state.token = token;
            state.profile = me;
            state.error = "";

            setAuthToken(state.token);
            setIsLoading(false)(state);
        },
        [User.signOut.fulfilled]: (state) => {
            singOutOfFirebase();
            state.token = null;
            state.profile = {};
            state.error = "";

            setAuthToken(null);
            setIsLoading(false)(state);
        },

        [User.requestResetPassword.fulfilled]: (state, { payload: { uuid } }) => {
            state.resetData.uuid = uuid;
            state.resetData.stage = User.resetStages.VERIFY;
            state.error = "";
            setIsLoading(false)(state);
        },
        [User.verifyCode.fulfilled]: (state, { payload: { token } }) => {
            state.resetData.token = token;
            state.resetData.stage = User.resetStages.RESET;
            state.error = "";
            setIsLoading(false)(state);
        },
        [User.resetPassword.fulfilled]: (state) => {
            state.resetData.stage = User.resetStages.DONE;
            state.error = "";
            setIsLoading(false)(state);
        },

        [User.updateProfile.fulfilled]: (state, { payload: { me } }) => {
            state.profile = me;
            setIsLoading(false)(state);
        },
        [User.getMyProfile.fulfilled]: (state, { payload: { me } }) => {
            state.profile = me;
            setIsLoading(false)(state);
        },

        [User.signUp.rejected]: setError,
        [User.signIn.rejected]: setError,
        [User.signOut.rejected]: setError,
        [User.requestResetPassword.rejected]: setError,
        [User.verifyCode.rejected]: setError,
        [User.resetPassword.rejected]: setError,

        [User.updateProfile.rejected]: setError,
        [User.getMyProfile.rejected]: setError,
    }
});

export const { setLoading, setErrorMessage, clearError, setResetStage, clearAuthToken } = userSlice.actions;

export default userSlice.reducer;
