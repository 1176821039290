/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

function Footer(props) {
    return (
        <footer className={"footer" + (props.default ? " footer-default" : "")}>
            <Container fluid={props.fluid ? true : false}>
                <Row>
                    <nav className="footer-nav">
                        <ul>
                            <li>
                                <a href="https://eonyx.io" target="_blank">
                                    Eonyx
                                </a>
                            </li>
                            <li>
                                <Link to="/admin/open-source">Open Source</Link>
                            </li>
                        </ul>
                    </nav>
                    <div className="credits ml-auto">
                        <div className="copyright">
                            &copy; {new Date().getFullYear()} LifeRamp Inc. &middot; Made with{" "}
                            <i className="fa fa-heart heart" /> by <a href="https://eonyx.io" target="_blank" className="text-secondary">Eonyx</a>
                        </div>
                    </div>
                </Row>
            </Container>
        </footer>
    );
}

Footer.propTypes = {
    default: PropTypes.bool,
    fluid: PropTypes.bool,
};

export default Footer;
