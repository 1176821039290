/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import datetime from "date-and-time";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
} from "reactstrap";

import Search from "../../components/utils/Search";
import TableLoading from "../../components/utils/TableLoading";
import MainPagination from "../../components/utils/MainPagination";

import { Event } from "../../api";
import { prevPage, nextPage } from "../../state/slices/eventSlice";

function EventList() {
    const dispatch = useDispatch();
    const state = useSelector(state => state.event);

    const [search, setSearch] = React.useState("");

    React.useEffect(() => {
        dispatch(Event.getEvents({ page: state.page, search }));
    }, [dispatch, state.page, search]);

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col md="6">
                                    <CardTitle tag="h4">Events</CardTitle>
                                </Col>
                                <Col md="6">
                                    <Row style={{ justifyContent: "flex-end" }}>
                                        <Col md="4" className="pt-3 mr-3">
                                            <Search value={search} onChange={setSearch} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead className="text-primary">
                                    <tr>
                                        <th>Institution</th>
                                        <th>Type</th>
                                        <th>User</th>
                                        <th>IP</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state.events?.map((i, index) =>
                                            <tr key={index}>
                                                <td>{i.institution?.name}</td>
                                                <th style={{fontSize: 12, color: "#555"}}>{i.type.toUpperCase()}</th>
                                                <td>{i.user?.name}</td>
                                                <td>{i.ip}</td>
                                                <td>{datetime.format(new Date(i.createdAt), "MMM D YYYY h:mm A")}</td>
                                            </tr>
                                        )
                                    }
                                    {state.loading && <TableLoading colSpan={3} />}
                                    {!state.loading && !state.events?.length &&
                                        <tr>
                                            <td className="text-center py-4" colSpan={4}>
                                                <h5>{search ? "No result." : "There's nothing but empty space."}</h5>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            <MainPagination page={state.page} prevPage={() => dispatch(prevPage())} nextPage={() => dispatch(nextPage())} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default EventList;
