/**
 * LifeRamp
 *
 * @author      Waris Anwar
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Input, Label } from "reactstrap";

const FormInputs = ({ inputType, inputValue, onChange, selectList, selectLabel, radioLabel, radioChecked, checkboxLabel, checkboxChecked, name }) => {

    let options = selectList ? selectList.map((value, index) => (
        <option key={index} value={value}> {value}</option>
    )) : null;

    return (
        <>
            {inputType === "text" &&
                <Input
                    type="text"
                    value={inputValue}
                    name="input-form"
                    onChange={(value) => onChange(value.target.value)} />
            }
            {inputType === "number" &&
                <Input
                    type="number"
                    value={inputValue}
                    name="input-form"
                    onChange={(value) => onChange(value.target.value)} />
            }
            {inputType === "textarea" &&
                <Input
                    type="textarea"
                    value={inputValue}
                    name="input-form"
                    onChange={(value) => onChange(value.target.value)} />
            }
            {inputType === "select" &&
                <Input
                    type="select"
                    name="select"
                    value={inputValue || selectLabel}
                    onChange={(value) => onChange(value.target.value)}>
                    <option disabled>{selectLabel}</option>
                    {options}
                </Input>
            }
            {inputType === "radio" &&
                <Label className="text-muted">
                    <Input
                        type="radio"
                        name={name}
                        value={name}
                        onChange={(value) => onChange(value.target.value)}
                        checked={radioChecked || false} />
                    {radioLabel}
                </Label>
            }
            {inputType === "checkbox" &&
                <Label className="text-muted">
                    <Input
                        type="checkbox"
                        name={name}
                        value={name}
                        checked={checkboxChecked || false}
                        onChange={(value) => onChange(value.target.value)} />
                    {checkboxLabel}
                </Label>
            }
        </>
    );
}
export default FormInputs;