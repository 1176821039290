/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Route } from "react-router-dom";

import MyCoachingSessionList from "./MyCoachingSessionList";

function MyCoachingSessions() {
    return (
        <>
            <Route path="/admin/my-coaching-sessions" exact component={MyCoachingSessionList} />
        </>
    );
}

export default MyCoachingSessions;
