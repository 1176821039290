/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DialogModal from "../../components/Modal/DialogModal";

import { Client, Coach, } from "../../api";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    FormGroup,
    Form,
    Row,
    Col,
    CardImg,
    Label,
    Spinner,
} from "reactstrap";
import Multiselect from "multiselect-react-dropdown";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ClientSetCoach = (props) => {
    const dispatch = useDispatch();

    const uuid = useQuery().get("uuid");

    const loading = useSelector(state => state.client.loading);
    const state = useSelector(state => state.client);
    const [user, setUser] = React.useState(state.needingCoach.find(u => u.uuid === uuid));

    const coach = useSelector(state => state.coach);
    const [coachSearch, setCoachSearch] = React.useState('');

    React.useEffect(() => {
        dispatch(Coach.getCoaches({ page: coach.page, search: coachSearch }));
    }, [dispatch, coach.page, coachSearch]);

    const setCoach = (v) => {
        v.length ? setUser(state => ({ ...state, coach_uuid: v[0].uuid })) : setUser(state => ({ ...state, coach_uuid: null }))
    };

    const [dialogModal, setDialogModal] = React.useState(false);
    const [dialogModalMessage, setDialogModalMessage] = React.useState("");

    const setDialog = (result) => {
        setDialogModalMessage(result.payload.status === "success" ? "Coach added!" : result.payload.message);
        setDialogModal(true);
    };

    const onSave = () => {
        if (!user.coach_uuid) {
            setDialogModalMessage("Please choose a coach to continue");
            setDialogModal(true);
            return;
        }

        dispatch(Client.updateClient({ client: { ...user, institution_uuid: user.institution.uuid } })).then((result) => {
            setDialog(result)
            if (!result.error) {
                setTimeout(() => {
                    window.location = "./";
                }, 1000);
            }
        });
    };

    return (
        <div className="content">
            <Row>
                <Col md="4">
                    <Card style={{ margin: "0px", borderTopRightRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "2px", borderBottomRightRadius: "2px" }}>
                        <CardImg style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "2px", borderBottomRightRadius: "2px" }} top width="100%" src={`${(user && user.image_cover) ? user.image_cover : "https://www.careersinafrica.com/wp-content/uploads/2020/05/placeholder.png"}`} alt="Card image cap" />
                    </Card>
                    <Card className="card-user" style={{ borderTopRightRadius: "2px", borderTopLeftRadius: "2px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                        <CardBody>
                            <div className="author update ml-auto mr-auto" style={{ width: "75%" }}>
                                <img
                                    alt="..."
                                    className="avatar"
                                    style={{ border: "3px solid #343a40" }}
                                    src={`${(user && user.image_profile) ? user.image_profile : "http://www.gravatar.com/avatar/?d=mp"}`}
                                />
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <span style={{ color: "#888", fontWeight: "bold" }}>Name</span>
                                    <span style={{ margin: "0 12px" }}>-</span>
                                    <span style={{  color: "#222", fontWeight: "bold" }}>{!!user && (user.firstname + " " + user.lastname)}</span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <span style={{ color: "#888", fontWeight: "bold" }}>Circle</span>
                                    <span style={{ margin: "0 12px" }}>-</span>
                                    <span style={{ color: "#222", fontWeight: "bold" }}>{!!user && user.circle?.name}</span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="8">
                    <Card className="card-user">
                        <CardHeader>
                            <Row>
                                <Col><CardTitle tag="h5" >Set Coach</CardTitle></Col>
                                <Col><CardTitle tag="h5" className="text-right">
                                    <Link to={`/admin/clients-needing-coach}`}>
                                        <i className="nc-icon nc-simple-remove" /></Link>
                                </CardTitle>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label tag={"h6"} style={{ margin: "20px 0" }}>Preferred coaches</Label>
                                            {user?.preferredCoaches.map((c, i) => (
                                                <div key={c.uuid} style={{ fontWeight: "bold", color: "#888", margin: "10px 0" }}> {c.name} </div>
                                            ))}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} style={{ borderLeft: "solid 1px #ddd" }}>
                                        <FormGroup style={{ margin: "0 10px" }}>
                                            <Label tag={"h6"} style={{ margin: "20px 0" }}>Assign coach</Label>
                                            <Multiselect
                                                loading={coach.loading}
                                                onSearch={s => setCoachSearch(s)}
                                                options={coach.coaches.filter(c => c.name)}
                                                selectionLimit={1}
                                                onSelect={setCoach}
                                                onRemove={setCoach}
                                                displayValue="name"
                                                closeIcon="close"
                                                style={{
                                                    chips: {
                                                        background: "#51cbce"
                                                    },
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="update ml-auto mr-auto">
                                        {
                                            loading ? <Spinner color="primary" /> : <Button
                                                className="btn btn-lg btn-block"
                                                color="primary"
                                                type="button"
                                                onClick={onSave}
                                            >
                                                Save
                                            </Button>
                                        }
                                        <DialogModal isModal={dialogModal} closeModal={() => setDialogModal(false)} message={dialogModalMessage} />
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default ClientSetCoach;
