/**
 * LifeRamp
 *
 * @author      Ummar Hamid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Route } from "react-router-dom";

import CoachList from "./CoachList";
import UserAddEdit from "../UserAddEdit";

function Coaches() {
    return (
        <>
            <Route path="/admin/coaches" exact component={CoachList}></Route>
            <Route path="/admin/coaches/addEdit" exact component={UserAddEdit}></Route>
        </>
    );
};

export default Coaches;
