/**
 * LifeRamp
 *
 * @author      Chetanya Gupta
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const DialogModal = (props) => {
	const {
		className,
		isModal,
		message,
		closeModal
	} = props;

	return (
		<div>
			<Modal isOpen={isModal} toggle={closeModal} className={className}>
				<ModalHeader toggle={closeModal}>Message</ModalHeader>
				<ModalBody>
					{message}
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={closeModal}>Ok</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}

export default DialogModal;