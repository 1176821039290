/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import config from "./config";
import user from "./user";
import institution from "./institution";
import circle from "./circle";
import resource from "./resource";
import coach from "./coach";
import client from "./client";
import admin from "./admin";
import dashboard from "./dashboard";
import report from "./report";
import post from "./post";
import coachingSession from "./coachingSession";
import event from "./event";

axios.defaults.baseURL = config.apiBase;

export const setAuthToken = (token) => { axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; }

export const User = user;
export const Institution = institution;
export const Circle = circle;
export const Resource = resource;
export const Coach = coach;
export const Client = client;
export const Admin = admin;
export const Dashboard = dashboard;
export const Report = report;
export const Post = post;
export const CoachingSession = coachingSession;
export const Event = event;
