
/**
 * LifeRamp
 *
 * @author      Chetanya Gupta
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Row, Col } from "reactstrap";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const ImagePickerCard = (props) => {
    const {
        upImg,
        onLoad,
        crop,
        setCrop,
        setCompletedCrop,
        previewCanvasRef,
    } = props;

    return (
        <div className="content">
            <Row >
                <div className="update ml-auto mr-auto">
                    <Col size="lg" style={{ maxWidth: '500px', width: '100%' }}>
                        <ReactCrop
                            className="border border-secondary"
                            style={{ maxWidth: '500px', width: '100%' }}
                            src={upImg}
                            onImageLoaded={onLoad}
                            crop={crop}
                            onChange={(c) => setCrop(c)}
                            onComplete={(c) => setCompletedCrop(c)}
                        />
                        <canvas
                            className="border border-secondary"
                            ref={previewCanvasRef}
                            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                            style={{
                                width: Math.round(0),
                                height: Math.round(0),
                            }}
                        />
                    </Col>
                </div>
            </Row>
        </div>
    );
}

export default ImagePickerCard;
