/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";

import {
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";

const Search = ({ value, onChange, onKeyDown, disableForm = true }) => {
    return (
        <form onSubmit={e => {disableForm && e.preventDefault()}}>
            <InputGroup className="no-border">
                <Input
                    placeholder="Search..."
                    value={value}
                    onKeyDown={onKeyDown}
                    onChange={(value) => onChange(value.target.value)} />
                <InputGroupAddon addonType="append">
                    <InputGroupText>
                        <i className="nc-icon nc-zoom-split" />
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </form>
    );
}

export default Search;
