/**
 * LifeRamp
 *
 * @author      Waris Anwar
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Route } from "react-router-dom";

import InstitutionList from "./InstitutionList";
import InstitutionAddEdit from "./InstitutionAddEdit";

function Institutions() {
    return (
        <>
            <Route path="/admin/institutions" exact component={InstitutionList} />
            <Route path="/admin/institutions/addEdit" exact component={InstitutionAddEdit} />
        </>
    );
}

export default Institutions;
 