/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { Client } from "../../api";
import { setIsLoading, setError } from "../utils";

export const clientSlice = createSlice({
    name: "client",
    initialState: {
        loading: false,
        error: "",

        uuid: null,
        data: {},

        clients: [],
        page: 1,
        maxPage: 1,

        needingCoach: [],
        needingCoachPage: 1,
        needingCoachMaxPage: 1,
    },
    reducers: {
        setClient(state, { payload: { client } }) {
            state.uuid = client.uuid;
            state.data = client;
        },
        prevPage(state) {
            if (state.page > 1) {
                state.page--;
            }
        },
        nextPage(state) {
            if (state.page < state.maxPage) {
                state.page++;
            }
        },
        updateNeedingCoachPage(state, { val }) {
            if (val === 1) { state.needingCoachPage < state.needingCoachMaxPage && state.needingCoachPage++; }
            if (val === -1) { state.needingCoachPage < 1 && state.needingCoachPage--; }
        },
    },
    extraReducers: {
        [Client.getClients.pending]: setIsLoading(true),
        [Client.createClient.pending]: setIsLoading(true),
        [Client.updateClient.pending]: setIsLoading(true),
        [Client.removeCoach.pending]: setIsLoading(true),
        [Client.deleteClient.pending]: setIsLoading(true),
        [Client.needingCoach.pending]: setIsLoading(true),

        [Client.getClients.fulfilled]: (state, action) => {
            state.maxPage = action.payload.maxPage
            state.clients = action.payload.clients;
            setIsLoading(false)(state);
        },
        [Client.createClient.fulfilled]: (state, action) => {
            const client = action.payload.client;
            state.clients.unshift(client)
            setIsLoading(false)(state);
        },
        [Client.updateClient.fulfilled]: (state, action) => {
            const client = action.payload.client;
            state.clients.splice(state.clients.indexOf(state.clients.find(i => i.uuid === client.uuid)), 1, client);
            setIsLoading(false)(state);
        },
        [Client.removeCoach.fulfilled]: (state, action) => {
            const client = action.payload.client;
            state.clients.splice(state.clients.indexOf(state.clients.find(i => i.uuid === client.uuid)), 1, client);
            setIsLoading(false)(state);
        },
        [Client.deleteClient.fulfilled]: (state, action) => {
            const client = action.meta.arg.client;
            state.clients.splice(state.clients.indexOf(state.clients.find(i => i.uuid === client.uuid)), 1);
            setIsLoading(false)(state);
        },
        [Client.needingCoach.fulfilled]: (state, action) => {
            state.needingCoachMaxPage = action.payload.maxPage
            state.needingCoach = action.payload.clients;
            setIsLoading(false)(state);
        },

        [Client.getClients.rejected]: setError,
        [Client.createClient.rejected]: setError,
        [Client.updateClient.rejected]: setError,
        [Client.removeCoach.rejected]: setError,
        [Client.deleteClient.rejected]: setError,
        [Client.needingCoach.rejected]: setError,
    }
});

export const { setClient, prevPage, nextPage, updateNeedingCoachPage } = clientSlice.actions;

export default clientSlice.reducer;
