/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { Circle } from "../../api";
import { setIsLoading, setError } from "../utils";

export const circleSlice = createSlice({
    name: "circle",
    initialState: {
        loading: false,
        error: "",

        uuid: null,
        data: {},

        circles: [],
        page: 1,
        maxPage: 1,
    },
    reducers: {
        setCircle(state, { payload: { circle } }) {
            state.uuid = circle.uuid;
            state.data = circle;
        },
        prevPage(state) {
            if (state.page > 1) {
                state.page--;
            }
        },
        nextPage(state) {
            if (state.page < state.maxPage) {
                state.page++;
            }
        },
    },
    extraReducers: {
        [Circle.getCircles.pending]: setIsLoading(true),
        [Circle.createCircle.pending]: setIsLoading(true),
        [Circle.updateCircle.pending]: setIsLoading(true),
        [Circle.deleteCircle.pending]: setIsLoading(true),

        [Circle.getCircles.fulfilled]: (state, action) => {
            state.maxPage = action.payload.maxPage
            state.circles = action.payload.circles;
            setIsLoading(false)(state);
        },
        [Circle.createCircle.fulfilled]: (state, action) => {
            const circle = action.payload.circle;
            state.circles.unshift(circle)
            setIsLoading(false)(state);
        },
        [Circle.updateCircle.fulfilled]: (state, action) => {
            const circle = action.payload.circle;
            state.circles.splice(state.circles.indexOf(state.circles.find(i => i.uuid === circle.uuid)), 1, circle);
            setIsLoading(false)(state);
        },
        [Circle.deleteCircle.fulfilled]: (state, action) => {
            const circle = action.meta.arg.circle;
            state.circles.splice(state.circles.indexOf(state.circles.find(i => i.uuid === circle.uuid)), 1);
            setIsLoading(false)(state);
        },

        [Circle.getCircles.rejected]: setError,
        [Circle.createCircle.rejected]: setError,
        [Circle.updateCircle.rejected]: setError,
        [Circle.deleteCircle.rejected]: setError,
    }
});

export const { setCircle, prevPage, nextPage } = circleSlice.actions;

export default circleSlice.reducer;
