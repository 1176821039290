/**
 * LifeRamp
 *
 * @author      Chetanya Gupta
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import ImagePickerCard from "./ImagePickerCard";
import React from "react";

import { Button, Card, CardBody, CardTitle, CardFooter, Row, Input, Label, Modal, ModalBody } from "reactstrap";

const AdminImageModal = (props) => {
    const {
        isModal,
        closeModal,
        onImageCropped,
        aspectRatio
    } = props;

    const [card, setCard] = React.useState(0);
    const [crop, setCrop] = React.useState({ unit: "%", width: 100, aspect: aspectRatio });
    const [upImg, setUpImg] = React.useState();
    const [completedCrop, setCompletedCrop] = React.useState(null);

    const imgRef = React.useRef(null);
    const previewCanvasRef = React.useRef(null);

    const onLoad = React.useCallback((img) => {
        imgRef.current = img;
    }, []);

    React.useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);

    const setImage = (canvas, crop) => {
        if (!crop || !canvas) {
            return;
        }

        canvas.toBlob(
            (blob) => {
                if (blob != null) {
                    const url = window.URL.createObjectURL(blob);
                    onImageCropped({ url, blob });
                }
            },
            "image/png",
            1
        );
    };

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => { setUpImg(reader.result); });
            reader.readAsDataURL(e.target.files[0]);
            setCard(1);
        }
    };

    const prevCardHandler = () => {
        setCard(0);
    };

    const cancelBtnHandler = () => {
        closeModal();
    };

    const applyBtnHandler = () => {
        setImage(previewCanvasRef.current, completedCrop);
        closeModal();
    };

    return (
        <div className="content">
            <Modal isOpen={isModal} toggle={closeModal} style={{ paddingBottom: "0px" }}>
                <ModalBody style={{ paddingBottom: "0px" }}>
                    {
                        card === 0 &&
                        <Row>
                            <div className="update ml-auto mr-auto" style={{ maxWidth: "500px", width: "100%" }}>
                                <Card style={{ margin: "0px" }}>
                                    <CardBody>
                                        <CardTitle tag="h5">Choose Image</CardTitle>
                                        <div className="update ml-auto mr-auto input-group" style={{ backgroundColor: "rgb(248,248,248)" }}>
                                            <Label className="input-group-btn" style={{ marginLeft: "35%", marginTop: "100px", marginBottom: "100px" }}>
                                                <span className="btn" style={{ backgroundColor: "rgb(225,225,225)", color: "black" }}>Upload Image
                                                    <Input
                                                        style={{ display: "none" }}
                                                        type="file" name="file" id="exampleFile" accept="image/*" onChange={onSelectFile} />
                                                </span>
                                            </Label>
                                        </div>
                                    </CardBody>
                                    <CardFooter>
                                        <div className="d-flex justify-content-end d-inline">
                                            <Button
                                                className="btn"
                                                style={{ backgroundColor: "white", color: "grey", border: "1px solid grey" }}
                                                type="button"
                                                onClick={cancelBtnHandler}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </div>
                        </Row>
                    }
                    {
                        card === 1 &&
                        <>
                            <Row>
                                <div className="update ml-auto mr-auto" style={{ maxWidth: "500px", width: "100%", }}>
                                    <Card style={{ margin: "0px" }}>
                                        <CardBody>
                                            <CardTitle tag="h5">Crop Image</CardTitle>
                                            <ImagePickerCard className="text-center"
                                                upImg={upImg}
                                                crop={crop}
                                                setCrop={setCrop}
                                                onLoad={onLoad}
                                                setCompletedCrop={setCompletedCrop}
                                                previewCanvasRef={previewCanvasRef}
                                            />
                                        </CardBody>
                                        <CardFooter>
                                            <div className="justify-content-start d-inline" style={{ border: "0px" }}>

                                                <Button
                                                    className="btn-link"
                                                    style={{ color: "black" }}
                                                    onClick={prevCardHandler}>
                                                    <i className="fa fa-arrow-left"></i>{" Back"}
                                                </Button>
                                                <Button
                                                    className="btn"
                                                    type="button"
                                                    style={{ backgroundColor: "white", color: "grey", border: "1px solid grey", float: "right" }}
                                                    onClick={cancelBtnHandler}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    className="btn"
                                                    color="primary"
                                                    type="button"
                                                    style={{ float: "right", marginRight: "20px", background: "linear-gradient(to right, #00D6F6, #10CC53)" }}
                                                    onClick={applyBtnHandler}
                                                >
                                                    Apply
                                                </Button>
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </div>
                            </Row>
                        </>
                    }
                </ModalBody>
            </Modal>
        </div>
    );
}

export default AdminImageModal;
