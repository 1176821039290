/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const getCoaches = createAsyncThunk(
    "coaches/getCoaches",
    async ({ page, search = "", filters = "" }, thunkAPI) => apiThunkHandler(axios.get("/coaches?page=" + page + (search ? "&search=" + search : "") + (filters ? "&filters=" + filters : "")), thunkAPI)
);

const createCoach = createAsyncThunk(
    "coaches/createCoach",
    async ({ coach }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/coaches",
            {
                ...coach
            }
        ),
        thunkAPI
    )
);

const updateCoach = createAsyncThunk(
    "coaches/updateCoach",
    async ({ coach }, thunkAPI) => apiThunkHandler(
        axios.put(
            "/coaches/" + coach.uuid,
            {
                ...coach
            }
        ),
        thunkAPI
    )
);

const deleteCoach = createAsyncThunk(
    "coaches/deleteCoach",
    async ({ coach }, thunkAPI) => apiThunkHandler(axios.delete("/coaches/" + coach.uuid), thunkAPI)
);

const getProfileQuestions = createAsyncThunk(
    "coach/getProfileQuestions",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/profile-questions/coach"), thunkAPI)
);

const getProfileData = createAsyncThunk(
    "coach/getProfileData",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/profile-data"), thunkAPI)
);

const setProfileAnswer = createAsyncThunk(
    "coach/setProfileAnswer",
    async ({ uuid, answer }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/profile-data/" + uuid,
            {
                answer: JSON.stringify(answer)
            }
        ),
        thunkAPI
    )
);

const coach = {
    getCoaches,
    createCoach,
    updateCoach,
    deleteCoach,

    getProfileQuestions,
    getProfileData,
    setProfileAnswer,
};

export default coach;
