/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const getPost = createAsyncThunk(
    "posts/getPost",
    async ({ uuid }, thunkAPI) => apiThunkHandler(axios.get("/posts/" + uuid), thunkAPI)
);

const getPosts = createAsyncThunk(
    "posts/getPosts",
    async ({ page, search = "", filters = ""  }, thunkAPI) => apiThunkHandler(axios.get("/posts?page=" + page + (search ? "&search=" + search : "") + (filters ? "&filters=" + filters : "")), thunkAPI)
);

const createPost = createAsyncThunk(
    "circle/createPost",
    async ({ circle_uuid, post }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/circles/" + circle_uuid + "/posts",
            {
                ...post,
                tags: JSON.stringify(post.tags),
            },
        ),
        thunkAPI
    )
);

const updatePost = createAsyncThunk(
    "posts/updatePost",
    async ({ post }, thunkAPI) => apiThunkHandler(
        axios.put(
            "/posts/" + post.uuid,
            {
                ...post,
                tags: JSON.stringify(post.tags),
            }
        ),
        thunkAPI
    )
);

const deletePost = createAsyncThunk(
    "posts/deletePost",
    async ({ post }, thunkAPI) => apiThunkHandler(axios.delete("/posts/" + post.uuid), thunkAPI)
);

const post = {
    getPost,
    getPosts,
    createPost,
    updatePost,
    deletePost,
};

export default post;
