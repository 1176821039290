/**
 * LifeRamp
 *
 * @author      Ummar Hamid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Route } from "react-router-dom";

import AdminList from "./AdminList";
import UserAddEdit from "../UserAddEdit";

function Admins() {
    return (
        <>
            <Route path="/admin/admins" exact component={AdminList}></Route>
            <Route path="/admin/admins/addEdit" exact component={UserAddEdit}></Route>
        </>
    );
};

export default Admins;
