/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Route } from "react-router-dom";

import EventList from "./EventList";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function Events() {
    const user = useSelector(state => state.user.profile);
    const history = useHistory();

    React.useEffect(() => {
        !user.isSuperAdmin && !user.institution.is_enabled_events && history.push("/");
    }, [history, user]);

    return (
        <>
            <Route path="/admin/events" exact component={EventList} />
        </>
    );
}

export default Events;
