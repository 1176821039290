/**
 * LifeRamp
 *
 * @author      Ummar Hamid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import randomString from "@afaanbilal/random-string";

import DialogModal from "../components/Modal/DialogModal";
import ConfirmationModal from "../components/Modal/ConfirmationModal";

import { Client, Coach, Admin, Institution } from "../api";
import { uploadToFirebase } from "../api/utils";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    CardImg,
    Label,
    ListGroup,
    ListGroupItem,
    Badge,
    ListGroupItemHeading,
    ListGroupItemText,
    Spinner,
    CardImgOverlay
} from "reactstrap";
import AdminImageModal from "components/Modal/ImagePickerAdmin";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const blankUser = { circle: "", email: "", phone: "", firstname: "", lastname: "", gender: "", dob: "", pronouns: "", image_profile: "", image_cover: "", role: "", about: "", headline: "", bio: "", education: "", verified: false, location: "", isCoach: false, isSuperAdmin: false, is_active: true, deactivation_reason: "" };

const UserAddEdit = (props) => {
    const dispatch = useDispatch();

    const uuid = useQuery().get("uuid");
    const role = useQuery().get("role");

    const [city, setCity] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [postalCode, setPostalCode] = React.useState("");
    const [address, setAddress] = React.useState("");

    const loading = useSelector(state => state[role] ? state[role].loading : false);
    const state = useSelector(state => state[role] || null);

    const [displayConfirmationModal, setDisplayConfirmationModal] = React.useState(false);
    const [confirmationModalData, setConfirmationModalData] = React.useState({ message: "", id: "" });

    const parseLocation = (locationStr) => {
        if (locationStr != null && isJsonString(locationStr)) {
            const location = JSON.parse(locationStr);
            setCity(location.city);
            setCountry(location.country);
            setAddress(location.address);
            setPostalCode(location.postalCode);
        };
    };

    const userData = () => {
        let userDetails = null;

        switch (role) {
            case "client":
                userDetails = { ...state.clients.find(c => c.uuid === uuid) };
                break;
            case "coach":
                userDetails = { ...state.coaches.find(c => c.uuid === uuid) };
                break;
            case "admin":
                userDetails = { ...state.admins.find(c => c.uuid === uuid) };
                break;
            default:
                break;
        }

        if (userDetails.dob != null && userDetails.dob !== "") {
            userDetails.dob = userDetails.dob.split(' ')[0];
        }

        parseLocation(userDetails.location);
        return userDetails;
    };

    const isEdit = !!uuid || false;
    const [user, setUser] = React.useState(isEdit ? userData : blankUser);

    const institutions = useSelector(state => state.institution.institutions);
    React.useEffect(() => {
        dispatch(Institution.getInstitutions({ page: 1 }));
    }, [dispatch]);

    const circles = useSelector(state => state.institution.circles);
    React.useEffect(() => {
        if (user.institution || user.institution_uuid) {
            dispatch(Institution.getCircles({ institution: user.institution_uuid ? { uuid: user.institution_uuid } : user.institution }));
        }
    }, [dispatch, user.institution, user.institution_uuid]);


    const coaches = useSelector(state => state.coach.coaches);
    React.useEffect(() => {
        dispatch(Coach.getCoaches({ page: 1 }));
    }, [dispatch]);

    const [dialogModal, setDialogModal] = React.useState(false);
    const [dialogModalMessage, setDialogModalMessage] = React.useState("");
    const [imagePickerProfile, setImagePickerProfile] = React.useState(false);
    const [imagePickerCover, setImagePickerCover] = React.useState(false);

    const [uploadingToFirebase, setUploadingToFirebase] = React.useState(false);
    const [uploadedImageBlobProfile, setUploadedImageBlobProfile] = React.useState(null);
    const [uploadedImageBlobCover, setUploadedImageBlobCover] = React.useState(null);
    const [shouldSave, setShouldSave] = React.useState(false);

    const setDialog = (result) => {
        setDialogModalMessage(result.payload.status === "success" ? "User saved!" : result.payload.message);
        setDialogModal(true);
        setUploadedImageBlobProfile(null);
        setUploadedImageBlobCover(null);
    };

    const confirmRemoveCoach = () => {
        setConfirmationModalData({ message: "You are about to remove this client's coach. Are you sure?", obj: null })
        setDisplayConfirmationModal(true);
    };

    const removeCoach = () => {
        dispatch(Client.removeCoach({ client: user })).then(({ payload }) => {
            setDisplayConfirmationModal(false);
            setUser(payload.client);
            window.location.reload();
        });
    };

    React.useEffect(() => {
        if (shouldSave) {
            let fn = null;

            switch (role) {
                case "client":
                    fn = isEdit ? Client.updateClient({ client: user }) : Client.createClient({ client: user });
                    break;
                case "coach":
                    fn = isEdit ? Coach.updateCoach({ coach: user }) : Coach.createCoach({ coach: user });
                    break;
                case "admin":
                    fn = isEdit ? Admin.updateAdmin({ admin: user }) : Admin.createAdmin({ admin: user });
                    break;
                default:
                    break;
            }

            dispatch(fn).then((result) => {
                setDialog(result)

                if (!result.error && !isEdit) {
                    setTimeout(() => {
                        window.location = "./";
                    }, 1000);
                }
            });
            setShouldSave(false);
        }
    }, [shouldSave, isEdit, dispatch, user, role]);

    const onSave = async () => {
        if (state.loading || uploadingToFirebase) return;

        setUser(state => ({ ...state, location: JSON.stringify({ city: city, country: country, postalCode: postalCode, address: address }) }));

        if (!user.institution_uuid) {
            setUser(state => ({ ...state, institution_uuid: state.institution.uuid }))
        }

        if (!uploadedImageBlobProfile && !uploadedImageBlobCover) {
            setShouldSave(true);
            return;
        }

        if (uploadedImageBlobProfile || uploadedImageBlobCover) {
            setUploadingToFirebase(true);
            let urlProfile = null;

            if (uploadedImageBlobProfile) {
                urlProfile = await uploadToFirebase("user-images/" + randomString() + "/profile.png", uploadedImageBlobProfile);

                if (!urlProfile) {
                    setDialogModalMessage("Please choose an image less than 5MB");
                    return;
                }

                setUser({ ...user, image_profile: urlProfile });
            }

            if (uploadedImageBlobCover) {
                const urlCover = await uploadToFirebase("user-images/" + randomString() + "/cover.png", uploadedImageBlobCover);

                if (!urlCover) {
                    setDialogModalMessage("Please choose an image less than 5MB");
                    return;
                }

                if (uploadedImageBlobProfile) {
                    setUser({ ...user, image_profile: urlProfile, image_cover: urlCover });
                } else {
                    setUser({ ...user, image_cover: urlCover });
                }
            }

            setShouldSave(true);
            setUploadingToFirebase(false);
        }
    };

    const onImageCroppedProfile = ({ url, blob }) => {
        setUploadedImageBlobProfile(blob);
        setUser({ ...user, image_profile: url });
    };

    const onImageCroppedCover = ({ url, blob }) => {
        setUploadedImageBlobCover(blob);
        setUser({ ...user, image_cover: url });
    };

    return (
        <div className="content">
            <AdminImageModal closeModal={() => setImagePickerProfile(false)} isModal={imagePickerProfile} aspectRatio={16 / 16} onImageCropped={onImageCroppedProfile} />
            <AdminImageModal closeModal={() => setImagePickerCover(false)} isModal={imagePickerCover} aspectRatio={16 / 9} onImageCropped={onImageCroppedCover} />
            <Row>
                <Col md="4">
                    <Card style={{ margin: "0px", borderTopRightRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "2px", borderBottomRightRadius: "2px" }}>
                        <CardImg style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "2px", borderBottomRightRadius: "2px" }} top width="100%" src={`${user.image_cover ? user.image_cover : "https://www.careersinafrica.com/wp-content/uploads/2020/05/placeholder.png"}`} alt="Card image cap" />
                        <CardImgOverlay>
                            <Button
                                style={{
                                    position: "absolute",
                                    bottom: "0px",
                                    right: "10px",
                                    color: "grey",
                                    backgroundColor: "white",
                                    fontSize: "24px",
                                    float: "right",
                                    padding: "5px",
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",

                                }}
                                onClick={() => setImagePickerCover(true)}
                            ><i className="fa fa-camera"></i></Button>
                        </CardImgOverlay>
                    </Card>
                    <Card className="card-user" style={{ borderTopRightRadius: "2px", borderTopLeftRadius: "2px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                        <CardBody>
                            <div className="author update ml-auto mr-auto" style={{ width: "75%" }}>
                                <img
                                    alt="..."
                                    className="avatar"
                                    style={{ border: "3px solid #343a40" }}
                                    src={`${user.image_profile ? user.image_profile : "http://www.gravatar.com/avatar/?d=mp"}`}
                                />
                                <Button
                                    style={{
                                        fontSize: "18px",
                                        position: "absolute",
                                        left: "58%",
                                        top: "5%",
                                        color: "white",
                                        backgroundColor: "#403D39",
                                        width: "33px",
                                        height: "33px",
                                        borderRadius: "50%",
                                        padding: "5px"
                                    }}
                                    className="user-icon"
                                    onClick={() => setImagePickerProfile(true)}>
                                    <i className="fa fa-camera"></i>
                                </Button>
                                <h5 className="title">{(user.firstname != null && user.firstname !== "") || (user.lastname != null && user.lastname !== "") ? `${user.firstname} ` : "Name "}{user.lastname != null && user.lastname !== "" ? `${user.lastname}` : ""}</h5>
                                <p className="description">{user.circle != null && user.circle !== "" ? `${user.circle.name}` : "Circle"}</p>
                            </div>
                        </CardBody>
                    </Card>
                    <Card >
                        <ListGroup style={{ borderRadius: "10px" }}>
                            <ListGroupItem className="justify-content-between"><Badge pill>{user.sessionCount}</Badge> Sessions</ListGroupItem>
                            <ListGroupItem className="justify-content-between"><Badge pill>{user.goalCount}</Badge> Goals</ListGroupItem>
                            <ListGroupItem className="justify-content-between"><Badge pill>{user.postCount}</Badge> Posts</ListGroupItem>
                        </ListGroup>
                    </Card>
                    <Card className="d-none">
                        <ListGroup style={{ borderRadius: "10px" }}>
                            <ListGroupItem>
                                <ListGroupItemHeading>Coaching Goal</ListGroupItemHeading>
                                <ListGroupItemText>Personal</ListGroupItemText>
                            </ListGroupItem>
                            <ListGroupItem>
                                <ListGroupItemHeading>Coaching Goal</ListGroupItemHeading>
                                <ListGroupItemText>Personal</ListGroupItemText>
                            </ListGroupItem><ListGroupItem>
                                <ListGroupItemHeading>Coaching Goal</ListGroupItemHeading>
                                <ListGroupItemText>Personal</ListGroupItemText>
                            </ListGroupItem><ListGroupItem>
                                <ListGroupItemHeading>Coaching Goal</ListGroupItemHeading>
                                <ListGroupItemText>Personal</ListGroupItemText>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                    {!user.isSuperAdmin && isEdit &&
                        <Card className="card-user">
                            <CardHeader>
                                <Row>
                                    <Col><CardTitle tag="h5" className="text-danger">Suspend {role.charAt(0).toUpperCase() + role.slice(1)}</CardTitle></Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row form>
                                        <Col md={12}>
                                            <FormGroup style={{ marginTop: "5px" }}>
                                                <Label className="ml-4">
                                                    <Input type="checkbox" name="state" id="exampleState" checked={!user.is_active}
                                                        onChange={(value) => setUser(state => ({ ...state, is_active: !value.target.checked }))}
                                                    /> Is suspended
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label>Deactivation Reason</Label>
                                                <Input type="textarea" name="state" value={user.deactivation_reason || ''}
                                                    onChange={(value) => setUser(state => ({ ...state, deactivation_reason: value.target.value }))} />
                                            </FormGroup>

                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    }
                    {role === "client" && isEdit && (user.coaches && user.coaches[0]) &&
                        <Card className="card-user">
                            <CardHeader>
                                <Row>
                                    <Col><CardTitle tag="h5" className="text-success">Coach</CardTitle></Col>
                                </Row>
                            </CardHeader>
                            <CardBody style={{ minHeight: "75px" }}>
                                <Row form>
                                    <Col md={8}>
                                        Assigned coach
                                    </Col>
                                    <Col md={4} className="text-right">
                                        {user.coaches[0].name}
                                        <Button className="btn ml-3" color="primary" type="button" onClick={confirmRemoveCoach}>
                                            Remove
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    }
                </Col>
                <Col md="8">
                    <Card className="card-user">
                        <CardHeader>
                            <Row>
                                <Col><CardTitle tag="h5" >{isEdit ? "Edit " : "Add "}{role.charAt(0).toUpperCase() + role.slice(1)}</CardTitle></Col>
                                <Col><CardTitle tag="h5" className="text-right">
                                    <Link to={`/admin/${role}${role === "coach" ? "es" : "s"}`}>
                                        <i className="nc-icon nc-simple-remove" /></Link>
                                </CardTitle>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Institution</Label>
                                            <Input type="select" name="select"
                                                value={user.institution_uuid ? user.institution_uuid : (user.institution ? user.institution.uuid : "")}
                                                onChange={(value) => setUser(state => ({ ...state, institution_uuid: value.target.value }))}>
                                                <option value="">Select Institution</option>
                                                {
                                                    institutions.map(i => <option value={i.uuid} key={i.uuid}>{i.name}</option>)
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Circle</Label>
                                            <Input type="select" name="select"
                                                value={user.circle_uuid ? user.circle_uuid : (user.circle ? user.circle.uuid : "")}
                                                onChange={(value) => setUser(state => ({ ...state, circle_uuid: value.target.value, institution_uuid: circles.find(c => c.uuid === value.target.value).institution.uuid }))}>
                                                <option value="">Select Circle</option>
                                                {
                                                    circles.map(c => <option value={c.uuid} key={c.uuid}>{c.name}</option>)
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    {(role === "coach" || user?.isCoach) &&
                                        <Col md={4}>
                                            <FormGroup>
                                                <label>Maximum concurrent clients</label>
                                                <Input
                                                    placeholder="Maximum concurrent clients"
                                                    type="number"
                                                    value={user.limit_clients || ''}
                                                    onChange={(value) => setUser(state => ({ ...state, limit_clients: value.target.value }))}
                                                />
                                            </FormGroup>
                                        </Col>
                                    }
                                    {role === "client" &&
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label>Coach</Label>
                                                <Input type="select" name="select"
                                                    value={user.coach_uuid ? user.coach_uuid : (user.coaches ? user.coaches[0]?.uuid : "")}
                                                    onChange={(value) => setUser(state => ({ ...state, coach_uuid: value.target.value }))}>
                                                    <option value="">Select Coach</option>
                                                    {
                                                        coaches.map(c => <option value={c.uuid} key={c.uuid}>{c.name}</option>)
                                                    }
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Email</Label>
                                            <Input type="email" name="email" id="email" placeholder="Enter your email" value={user.email || ''}
                                                onChange={(value) => setUser(state => ({ ...state, email: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Phone Number</Label>
                                            <Input type="number" name="phone" id="phone" placeholder="Enter your phone number" value={user.phone || ''}
                                                onChange={(value) => setUser(state => ({ ...state, phone: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <label disabled={true}>Maximum Sessions per month</label>
                                            <Input
                                                placeholder="Maximum sessions per month"
                                                type="number"
                                                value={user.limit_sessions || ''}
                                                onChange={(value) => setUser(state => ({ ...state, limit_sessions: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {(role === "coach" || user?.isCoach) &&
                                    <Row form>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Bio Video</Label>
                                                <Input type="text" name="bio_video" id="bio_video" placeholder="Bio Video URL" value={user.bio_video || ''}
                                                    onChange={(value) => setUser(state => ({ ...state, bio_video: value.target.value }))}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Calendly email</Label>
                                                <Input type="email" name="calendly_email" id="calendly_email" placeholder="Enter your Calendly email" value={user.calendly_email || ''}
                                                    onChange={(value) => setUser(state => ({ ...state, calendly_email: value.target.value }))}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Prefix</Label>
                                                <Input type="text" name="zoom_url" id="zoom_url" placeholder="Enter a Prefix" value={user.prefix || ''}
                                                    onChange={(value) => setUser(state => ({ ...state, prefix: value.target.value }))}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Zoom URL</Label>
                                                <Input type="text" name="zoom_url" id="zoom_url" placeholder="Enter a Zoom URL" value={user.zoom_url || ''}
                                                    onChange={(value) => setUser(state => ({ ...state, zoom_url: value.target.value }))}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>First name</Label>
                                            <Input type="text" name="fname" id="fname" value={user.firstname || ''}
                                                onChange={(value) => setUser(state => ({ ...state, firstname: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Last name</Label>
                                            <Input type="text" name="lname" id="lname" value={user.lastname || ''}
                                                onChange={(value) => setUser(state => ({ ...state, lastname: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label>Address</Label>
                                    <Input type="text" name="address" id="address" value={address || ''}
                                        onChange={(value) => setAddress(value.target.value)}
                                    />
                                </FormGroup>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>City</Label>
                                            <Input type="text" name="city" id="city" placeholder="Enter city" value={city || ''}
                                                onChange={(value) => setCity(value.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Country</Label>
                                            <Input type="text" name="country" id="country" placeholder="Entry Country" value={country || ''}
                                                onChange={(value) => setCountry(value.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Postal Code</Label>
                                            <Input type="number" name="pincode" id="pincode" placeholder="Enter Postal Code" value={postalCode || ''}
                                                onChange={(value) => setPostalCode(value.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={4}>
                                        <Label>Gender</Label>
                                        <FormGroup>
                                            <Input type="text" name="gender" id="gender" value={user.gender || ''}
                                                onChange={(value) => setUser(state => ({ ...state, gender: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>DOB</Label>
                                            <Input type="date" name="dob" id="dob" value={user.dob || ''}
                                                onChange={(value) => setUser(state => ({ ...state, dob: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Pronouns</Label>
                                            <Input type="text" name="pronouns" id="pronouns" value={user.pronouns || ''}
                                                onChange={(value) => setUser(state => ({ ...state, pronouns: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label>Role</Label>
                                    <Input type="text" name="state" value={user.role || ''}
                                        onChange={(value) => setUser(state => ({ ...state, role: value.target.value }))}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>About</Label>
                                    <Input type="textarea" name="state" value={user.about || ''}
                                        onChange={(value) => setUser(state => ({ ...state, about: value.target.value }))}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Headline</Label>
                                    <Input type="textarea" name="state" value={user.headline || ''}
                                        onChange={(value) => setUser(state => ({ ...state, headline: value.target.value }))} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Bio</Label>
                                    <Input type="textarea" name="state" value={user.bio || ''}
                                        onChange={(value) => setUser(state => ({ ...state, bio: value.target.value }))}
                                    />
                                </FormGroup>
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Education</Label>
                                            <Input type="text" name="city" value={user.education || ''}
                                                onChange={(value) => setUser(state => ({ ...state, education: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={{ size: "auto", offset: 1 }}>
                                        <FormGroup style={{ marginTop: "30px" }}>
                                            <Label>
                                                <Input type="checkbox" name="state" checked={user.verified || ''}
                                                    onChange={(value) => setUser(state => ({ ...state, verified: value.target.checked }))}
                                                />  Verified
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    {role === "admin" &&
                                        <Col sm={{ size: "auto", offset: 1 }}>
                                            <FormGroup style={{ marginTop: "30px" }}>
                                                <Label>
                                                    <Input type="checkbox" name="state" id="exampleState" checked={user.isSuperAdmin}
                                                        onChange={(value) => setUser(state => ({ ...state, isSuperAdmin: value.target.checked }))}
                                                    /> Is Super Admin
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    }
                                    {(role === "coach" || user?.isCoach) &&
                                        <Col sm={{ size: "auto", offset: 1 }} className="text-center">
                                            <FormGroup style={{ marginTop: "30px" }}>
                                                <Input
                                                    type="checkbox"
                                                    value={user.selected_institution_only ? true : false}
                                                    onChange={(value) => setUser(state => ({ ...state, selected_institution_only: value.target.value ? true : null }))}
                                                />
                                                <label>Selected institution only?</label>
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <div className="update ml-auto mr-auto">
                                        {
                                            loading || uploadingToFirebase ? <Spinner color="primary" /> : <Button
                                                className="btn btn-lg btn-block"
                                                color="primary"
                                                type="button"
                                                onClick={onSave}
                                            >
                                                Save
                                            </Button>
                                        }
                                        <DialogModal isModal={dialogModal} closeModal={() => setDialogModal(false)} message={dialogModalMessage} />
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                !!user.profileData?.length &&
                <Row>
                    <Col md="12">
                        <Card className="card-user">
                            <CardHeader tag="h5">
                                Profile Data
                            </CardHeader>
                            <CardBody>
                                {user.profileData.map((p, i) =>
                                    <div
                                        style={{
                                            borderBottom: i === user.profileData.length - 1 ? "0" : "solid 1px #bbbbbb",
                                            padding: "20px 0px",
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                        key={p.uuid}>
                                        <span style={{ fontWeight: "bold", color: "#888" }}>Q{i + 1}. {p.profile_question.question}</span>
                                        <div style={{ marginLeft: "20px", display: "flex", flexWrap: "wrap" }}>
                                            {
                                                p.answer.map((answer, index) =>
                                                    <span
                                                        key={index}
                                                        style={{
                                                            backgroundColor: "#51cbce",
                                                            padding: "4px 12px",
                                                            borderRadius: "6px",
                                                            marginRight: "10px",
                                                            color: "white",
                                                            fontWeight: "bold"

                                                        }}
                                                    >{answer}</span>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
            <ConfirmationModal type="delete" showModal={displayConfirmationModal} confirmModal={removeCoach} hideModal={() => setDisplayConfirmationModal(false)} data={confirmationModalData} />
        </div>
    );
};

export default UserAddEdit;
