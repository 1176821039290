/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { CoachingSession } from "../../api";
import { setIsLoading, setError } from "../utils";

export const coachingSessionSlice = createSlice({
    name: "coachingSession",
    initialState: {
        loading: false,
        error: "",

        uuid: null,
        data: {},

        coachingSessions: [],
        sessionsByRating: [],
        sessionsByStatus: [],

        upcomingSessions: [],
        upcomingPage: 1,
        upcomingMaxPage: 1,

        pastSessions: [],
        pastPage: 1,
        pastMaxPage: 1,

        totalCount: "",

        page: 1,
        maxPage: 1,
    },
    reducers: {
        setCoachingSession(state, { payload: { coachingSession } }) {
            state.uuid = coachingSession.uuid;
            state.data = coachingSession;
        },
        prevPage(state) {
            if (state.page > 1) {
                state.page--;
            }
        },
        nextPage(state) {
            if (state.page < state.maxPage) {
                state.page++;
            }
        },
        updatePage(state, { type, updater }) {
            const entityPage = type === "upcomingSessions" ? "upcomingPage" : "pastPage";
            const entityMaxPage = type === "upcomingSessions" ? "upcomingMaxPage" : "pastMaxPage";

            if (updater === 1) {
                if (state[entityPage] < state[entityMaxPage]) {
                    state[entityPage]++;
                }
            }
            if (updater === -1) {
                if (state[entityPage] > 1) {
                    state[entityPage]--;
                }
            }
        },
    },
    extraReducers: {
        [CoachingSession.getCoachingSessions.pending]: setIsLoading(true),
        [CoachingSession.getUpcomingSessions.pending]: setIsLoading(true),
        [CoachingSession.getPastSessions.pending]: setIsLoading(true),

        [CoachingSession.getCoachingSessions.fulfilled]: (state, action) => {
            state.maxPage = action.payload.maxPage
            state.coachingSessions = action.payload.coachingSessions;
            state.sessionsByRating = action.payload.sessionsByRating;
            state.sessionsByStatus = action.payload.sessionsByStatus;
            state.totalCount = action.payload.totalCount;

            setIsLoading(false)(state);
        },
        [CoachingSession.getUpcomingSessions.fulfilled]: (state, action) => {
            state.upcomingMaxPage = action.payload.maxPage;
            state.upcomingSessions = action.payload.coachingSessions;
            setIsLoading(false)(state);
        },
        [CoachingSession.getPastSessions.fulfilled]: (state, action) => {
            state.pastMaxPage = action.payload.maxPage;
            state.pastSessions = action.payload.coachingSessions;
            setIsLoading(false)(state);
        },

        [CoachingSession.getCoachingSessions.rejected]: setError,
        [CoachingSession.getUpcomingSessions.rejected]: setError,
        [CoachingSession.getPastSessions.rejected]: setError,
    }
});

export const { setCoachingSession, prevPage, nextPage, updatePage } = coachingSessionSlice.actions;

export default coachingSessionSlice.reducer;
