/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, ModalHeader, Row, Label, Modal, ModalBody, ModalFooter, FormGroup, Input } from "reactstrap";
import BarLoader from "react-spinners/BarLoader";

import { Client, Institution } from "../../api";
import { Link } from "react-router-dom";

const UserImportModal = ({ isModalOpen, closeModal }) => {
    const dispatch = useDispatch();
    const institutions = useSelector(state => state.institution.institutions);
    const circles = useSelector(state => state.institution.circles);

    React.useEffect(() => {
        dispatch(Institution.getInstitutions({ page: 1 }));
    }, [dispatch]);

    const inputRef = React.useRef();
    const [showLoader, setShowLoader] = React.useState(false);
    const [message, setMessage] = React.useState(null);

    const [institutionUUID, setInstitutionUUID] = React.useState("");
    const [circleUUID, setCircleUUID] = React.useState("");
    const [file, setFile] = React.useState(null);

    React.useEffect(() => {
        if (institutionUUID) {
            dispatch(Institution.getCircles({ institution: { uuid: institutionUUID } }));
        }
    }, [dispatch, institutionUUID]);

    React.useEffect(() => {
        if (institutions && institutions.length) {
            setInstitutionUUID(institutions[0].uuid)
        }
    }, [institutions]);

    React.useEffect(() => {
        if (circles && circles.length) {
            setCircleUUID(circles[0].uuid)
        }
    }, [circles]);

    const selectFile = () => {
        inputRef.current.click();
    };

    const resetFileInput = () => {
        inputRef.current.value = "";
        setFile(null);
    };

    const inputFileSelected = () => {
        const chosenFile = inputRef.current.files[0];
        if (chosenFile.type !== "text/csv") {
            alert("Please choose a CSV file.");
            return resetFileInput();
        }

        setFile(chosenFile);
    };

    const uploadBtnHandler = () => {
        setShowLoader(true);
        dispatch(Client.importClients({ institution_uuid: institutionUUID, circle_uuid: circleUUID, csv_file: file })).then(({ payload }) => {
            setMessage("Imported " + (payload.imported ? payload.imported : 0) + " clients.");
            setShowLoader(false);
        });
    };

    return (
        <div className="content">
            <Modal isOpen={isModalOpen} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>Import (CSV)</ModalHeader>
                <ModalBody style={{ padding: "2em 3em" }}>
                    <input type="file" accept="csv" ref={inputRef} style={{ display: "none" }} onChange={inputFileSelected} />
                    {message !== null ?
                        <Row>
                            <div className="d-flex d-inline justify-content-center mt-4 mb-2" style={{ fontSize: "20px", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                {message}
                            </div>
                            <div className="d-flex d-inline justify-content-center my-3" style={{ flexDirection: "row", alignItems: "center", width: "100%" }}>
                                <Button
                                    className="btn btn-round btn-link"
                                    style={{ background: "linear-gradient(to right, #00D6F6, #10CC53)", color: "white" }}
                                    type="button"
                                    onClick={() => window.location.reload()}>
                                    Okay
                                </Button>
                            </div>
                        </Row>
                        :
                        <Row>
                            <FormGroup className="w-100">
                                <Label>Institution</Label>
                                <Input type="select" name="select"
                                    value={institutionUUID}
                                    onChange={(value) => setInstitutionUUID(value.target.value)}>
                                    <option disabled>Select Institution</option>
                                    {
                                        institutions.map(i => <option value={i.uuid} key={i.uuid}>{i.name}</option>)
                                    }
                                </Input>
                            </FormGroup>
                            <FormGroup className="w-100">
                                <Label>Circle</Label>
                                <Input type="select" name="select"
                                    value={circleUUID}
                                    onChange={(value) => setCircleUUID(value.target.value)}>
                                    <option disabled>Select Circle</option>
                                    {
                                        circles.map(i => <option value={i.uuid} key={i.uuid}>{i.name}</option>)
                                    }
                                </Input>
                            </FormGroup>

                            <div className="update mx-auto" style={{ maxWidth: "600px", width: "100%" }}>
                                {!showLoader &&
                                    (file ?
                                        <div className="d-flex d-inline justify-content-center my-4" style={{ flexDirection: "row", alignItems: "center", width: "100%" }}>
                                            <span style={{ backgroundColor: "#EAEAEA", fontSize: "16px", padding: "5px 10px 5px 15px", borderRadius: "2px", marginLeft: "1em" }}>
                                                {file.name}
                                                <button type="button" style={{ marginLeft: "1em", backgroundColor: "transparent", border: "none" }} onClick={() => resetFileInput()}>✖</button>
                                            </span>
                                        </div>
                                        :
                                        <div className="d-flex d-inline justify-content-center my-4" style={{ flexDirection: "row", alignItems: "center", width: "100%" }}>
                                            <Button
                                                className="btn btn-round btn-link"
                                                style={{ background: "linear-gradient(to right, #00D6F6, #10CC53)", color: "white" }}
                                                type="button"
                                                onClick={selectFile}>
                                                Choose file
                                            </Button>
                                        </div>
                                    )
                                }
                                {!showLoader && file &&
                                    <div className="d-flex d-inline justify-content-center my-2" style={{ flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <Button
                                            className="btn btn-round btn-link"
                                            style={{ background: "linear-gradient(to right, #00D6F6, #10CC53)", color: "white" }}
                                            type="button"
                                            onClick={uploadBtnHandler}>
                                            Upload
                                        </Button>
                                    </div>
                                }
                                <div className="d-flex d-inline justify-content-center" >
                                    <BarLoader
                                        loading={showLoader}
                                        width={"450px"}
                                        height={"13px"}
                                        color={"#00D6F6"}
                                        css={`margin: 20px; border-radius: 4px;`}
                                    />
                                </div>
                            </div>
                        </Row>
                    }
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex d-inline justify-content-between my-2" style={{ flexDirection: "row", alignItems: "center", width: "100%" }}>
                        <Label style={{ fontSize: "16px" }}>
                            Download Sample File
                        </Label>
                        <Link to="/files/sample.csv" target="_blank" download style={{ backgroundColor: "transparent", fontSize: "16px", border: "0px", color: "black", textTransform: "none", textDecorationLine: "underline" }}>Download</Link>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default UserImportModal;
