/**
 * LifeRamp
 *
 * @author      Burhan Rashid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Spinner,
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setAuthToken, User } from "../../api";
import { clearAuthToken } from "state/slices/userSlice";
import { persistor } from "state/store";
import { setErrorMessage } from "state/slices/userSlice";

function SignIn() {
    const dispatch = useDispatch();
    const history = useHistory();

    const loading = useSelector(state => state.user.loading);
    const error = useSelector(state => state.user.error);

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");

    const handleSignIn = () => {
        if (email === "") {
            return alert("Please enter your email address.");
        }
        if (password === "") {
            return alert("Please enter your password.");
        }

        dispatch(User.signIn({ email, password })).then(({ payload: { me } }) => {
            if (!me) return;

            if (me.isSuperAdmin || me.canManageInstitution) {
                return history.push("/admin/dashboard");
            } else if (me.isCoach) {
                return history.push("/admin/coach-profile");
            } else {
                persistor.purge();
                window.localStorage.clear();
                dispatch(clearAuthToken());
                setAuthToken(null);
                dispatch(setErrorMessage("Invalid credentials."));
                return history.push("/auth/sign-in");
            }
        });
    };

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card className="card-user shadow-none">
                        <Row style={{ marginTop: "8%" }}>
                            <Col md="4" style={{ marginLeft: "10%" }}>
                                <CardHeader>
                                    <CardTitle tag="h5" className="">Sign In</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {error !== "" && <p className="text-danger">{error}</p>}
                                    <Form >
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label>Email</label>
                                                    <Input
                                                        placeholder="Email"
                                                        type="text"
                                                        onChange={(event) => setEmail(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label>Password</label>
                                                    <Input
                                                        placeholder="Password"
                                                        type="password"
                                                        onChange={(event) => setPassword(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <Link to="/auth/reset-password"><p>Forgot Password?</p></Link>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                    <Col md="12">
                                        <Link to="/auth/sign-up"><p>Don't have an account? Sign Up</p></Link>
                                    </Col>
                                </Row> */}
                                        <Row>
                                            <div className="" style={{ marginLeft: "15px" }}>
                                                {loading ? <Spinner color="primary" />
                                                    :
                                                    <Button
                                                        className="btn btn-lg btn-block"
                                                        color="primary"
                                                        type="button"
                                                        onClick={handleSignIn}
                                                    >
                                                        Sign In
                                                    </Button>
                                                }
                                            </div>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Col>
                            <Col md="6">
                                <img style={{ width: "80%", height: "80%" }}
                                    alt="..."
                                    src={require("assets/img/demoImg.jpg").default}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default SignIn;
