/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { Admin } from "../../api";
import { setIsLoading, setError } from "../utils";

export const adminSlice = createSlice({
    name: "admin",
    initialState: {
        loading: false,
        error: "",

        uuid: null,
        data: {},

        admins: [],
        page: 1,
        maxPage: 1,
    },
    reducers: {
        setAdmin(state, { payload: { admin } }) {
            state.uuid = admin.uuid;
            state.data = admin;
        },
        prevPage(state) {
            if (state.page > 1) {
                state.page--;
            }
        },
        nextPage(state) {
            if (state.page < state.maxPage) {
                state.page++;
            }
        },
    },
    extraReducers: {
        [Admin.getAdmins.pending]: setIsLoading(true),
        [Admin.createAdmin.pending]: setIsLoading(true),
        [Admin.updateAdmin.pending]: setIsLoading(true),
        [Admin.deleteAdmin.pending]: setIsLoading(true),

        [Admin.getAdmins.fulfilled]: (state, action) => {
            state.maxPage = action.payload.maxPage
            state.admins = action.payload.admins;
            setIsLoading(false)(state);
        },
        [Admin.createAdmin.fulfilled]: (state, action) => {
            const admin = action.payload.admin;
            state.admins.unshift(admin)
            setIsLoading(false)(state);
        },
        [Admin.updateAdmin.fulfilled]: (state, action) => {
            const admin = action.payload.admin;
            state.admins.splice(state.admins.indexOf(state.admins.find(i => i.uuid === admin.uuid)), 1, admin);
            setIsLoading(false)(state);
        },
        [Admin.deleteAdmin.fulfilled]: (state, action) => {
            const admin = action.meta.arg.admin;
            state.admins.splice(state.admins.indexOf(state.admins.find(i => i.uuid === admin.uuid)), 1);
            setIsLoading(false)(state);
        },

        [Admin.getAdmins.rejected]: setError,
        [Admin.createAdmin.rejected]: setError,
        [Admin.updateAdmin.rejected]: setError,
        [Admin.deleteAdmin.rejected]: setError,
    }
});

export const { setAdmin, prevPage, nextPage } = adminSlice.actions;

export default adminSlice.reducer;
