
/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { Coach } from "../../api";
import { setIsLoading, setError } from "../utils";

export const coachSlice = createSlice({
    name: "coach",
    initialState: {
        loading: false,
        error: "",

        uuid: null,
        data: {},

        coaches: [],

        profileQuestions: [],
        profileAnswers: [],

        page: 1,
        maxPage: 1,
    },
    reducers: {
        setCoach(state, { payload: { coach } }) {
            state.uuid = coach.uuid;
            state.data = coach;
        },
        prevPage(state) {
            if (state.page > 1) {
                state.page--;
            }
        },
        nextPage(state) {
            if (state.page < state.maxPage) {
                state.page++;
            }
        },
        setProfileSingleAnswer(state, { payload: { uuid, answer } }) {
            if (state.profileAnswers.find(o => o.uuid === uuid)) {
                state.profileAnswers.find(o => o.uuid === uuid).answer[0] = answer;
            } else {
                state.profileAnswers.push({ uuid, answer: [answer] });
            }
        },
        setProfileMultiAnswer(state, { payload: { uuid, answer } }) {
            if (state.profileAnswers.find(o => o.uuid === uuid)) {
                let cAnswer = state.profileAnswers.find(o => o.uuid === uuid).answer;
                if (cAnswer.includes(answer)) {
                    state.profileAnswers.find(o => o.uuid === uuid).answer = cAnswer.filter(o => o !== answer);
                } else {
                    cAnswer.push(answer);
                }
            } else {
                state.profileAnswers.push({ uuid, answer: [answer] });
            }
        },
    },
    extraReducers: {
        [Coach.getCoaches.pending]: setIsLoading(true),
        [Coach.createCoach.pending]: setIsLoading(true),
        [Coach.updateCoach.pending]: setIsLoading(true),
        [Coach.deleteCoach.pending]: setIsLoading(true),
        [Coach.getProfileQuestions.pending]: setIsLoading(true),
        [Coach.getProfileData.pending]: setIsLoading(true),

        [Coach.getCoaches.fulfilled]: (state, action) => {
            state.maxPage = action.payload.maxPage
            state.coaches = action.payload.coaches;
            setIsLoading(false)(state);
        },
        [Coach.createCoach.fulfilled]: (state, action) => {
            const coach = action.payload.coach;
            state.coaches.unshift(coach)
            setIsLoading(false)(state);
        },
        [Coach.updateCoach.fulfilled]: (state, action) => {
            const coach = action.payload.coach;
            state.coaches.splice(state.coaches.indexOf(state.coaches.find(i => i.uuid === coach.uuid)), 1, coach);
            setIsLoading(false)(state);
        },
        [Coach.deleteCoach.fulfilled]: (state, action) => {
            const coach = action.meta.arg.coach;
            state.coaches.splice(state.coaches.indexOf(state.coaches.find(i => i.uuid === coach.uuid)), 1);
            setIsLoading(false)(state);
        },
        [Coach.getProfileQuestions.fulfilled]: (state, { payload }) => {
            state.profileQuestions = payload["profile-questions"];
            setIsLoading(false)(state);
        },
        [Coach.getProfileData.fulfilled]: (state, { payload }) => {
            let profileAnswers = payload["profile-data"].map((pD) => {
                let pA = {};
                pA.uuid = pD.profile_question.uuid;
                pA.answer = pD.answer;
                return pA;
            })
            state.profileAnswers = profileAnswers;
            setIsLoading(false)(state);
        },

        [Coach.getCoaches.rejected]: setError,
        [Coach.createCoach.rejected]: setError,
        [Coach.updateCoach.rejected]: setError,
        [Coach.deleteCoach.rejected]: setError,
        [Coach.getProfileQuestions.rejected]: setError,
        [Coach.getProfileData.rejected]: setError,
    }
});

export const {
    setCoach,
    prevPage,
    nextPage,

    setProfileSingleAnswer,
    setProfileMultiAnswer,
} = coachSlice.actions;

export default coachSlice.reducer;
