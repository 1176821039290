/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const getInstitutions = createAsyncThunk(
    "institutions/getInstitutions",
    async ({ page, search = "" }, thunkAPI) => apiThunkHandler(axios.get("/institutions?page=" + page + (search ? "&search=" + search : "")), thunkAPI)
);

const createInstitution = createAsyncThunk(
    "institutions/createInstitution",
    async ({ institution }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/institutions",
            {
                ...institution,
                coaches: JSON.stringify(institution.coaches),
                domains: JSON.stringify(institution.domains),
            }
        ),
        thunkAPI
    )
);

const updateInstitution = createAsyncThunk(
    "institutions/updateInstitution",
    async ({ institution }, thunkAPI) => apiThunkHandler(
        axios.put(
            "/institutions/" + institution.uuid,
            {
                ...institution,
                coaches: JSON.stringify(institution.coaches),
                domains: JSON.stringify(institution.domains),
            }
        ),
        thunkAPI
    )
);

const deleteInstitution = createAsyncThunk(
    "institutions/deleteInstitution",
    async ({ institution }, thunkAPI) => apiThunkHandler(axios.delete("/institutions/" + institution.uuid), thunkAPI)
);

const getCircles = createAsyncThunk(
    "institutions/getCircles",
    async ({ institution }, thunkAPI) => apiThunkHandler(axios.get("/institutions/" + institution.uuid + "/circles"), thunkAPI)
);

const institution = {
    getInstitutions,
    createInstitution,
    updateInstitution,
    deleteInstitution,
    getCircles,
};

export default institution;
