/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import datetime from "date-and-time";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Spinner,
    Input,
} from "reactstrap";

import MainPagination from "../../components/utils/MainPagination";

import { CoachingSession } from "../../api";
import { updatePage } from "../../state/slices/coachingSessionSlice";

function MyCoachingSessionList() {
    const dispatch = useDispatch();

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const state = useSelector(state => state.coachingSession);

    const [type, setType] = React.useState("upcomingSessions");

    React.useEffect(() => {
        type === "upcomingSessions" &&
            dispatch(CoachingSession.getUpcomingSessions({ page: state.upcomingPage }))
                .unwrap()
                .then(() => forceUpdate());

        type === "pastSessions" &&
            dispatch(CoachingSession.getPastSessions({ page: state.pastPage }))
                .unwrap()
                .then(() => forceUpdate());
    }, [dispatch, state.upcomingPage, state.pastPage, forceUpdate, type]);

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col md="6">
                                    <CardTitle tag="h4">My Coaching Sessions</CardTitle>
                                </Col>
                                <Col md="6">
                                    <Row style={{ justifyContent: "flex-end" }}>
                                        <Col md="4" className="pt-3 mr-3">
                                            <Input type="select" name="select"
                                                value={type}
                                                onChange={value => setType(value.target.value)}>
                                                <option value="upcomingSessions">Upcoming sessions</option>
                                                <option value="pastSessions">Past sessions</option>
                                            </Input>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardHeader>
                        {state.loading && <div className="content text-center py-5"><Spinner color="primary" /></div>}
                        {!state.loading &&
                            <CardBody>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th>Name</th>
                                            <th>Start at</th>
                                            <th>Coach</th>
                                            <th>Client</th>
                                            <th>Status</th>
                                            <th>Coach comments</th>
                                            <th>Client status</th>
                                            <th>Client comments</th>
                                            <th>Rating</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !state.loading && state[type] && state[type].map(i =>
                                                <tr key={i.uuid}>
                                                    <td>{i.name}</td>
                                                    <td>{datetime.format(new Date(i.start_at), "MMM D YYYY h:mm A")}</td>
                                                    <td>{i.coach.name}</td>
                                                    <td>{i.user.name}</td>
                                                    <td>{i.status}</td>
                                                    <td>{i.coach_comments}</td>
                                                    <td>{i.client_status}</td>
                                                    <td>{i.client_comments}</td>
                                                    <td>{i.client_rating}{i.client_rating ? "/ 5" : ""}</td>
                                                </tr>
                                            )
                                        }
                                        {!state.loading && !state[type]?.length &&
                                            <tr>
                                                <td className="text-center py-4" colSpan={9}>
                                                    <h5>{"There's nothing but empty space."}</h5>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                                <MainPagination page={state[type === "upcomingSessions" ? "upcomingPage" : "pastPage"]} prevPage={() => dispatch(updatePage(type, -1))} nextPage={() => dispatch(updatePage(type, 1))} />
                            </CardBody>
                        }
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default MyCoachingSessionList;
