/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { Dashboard } from "../../api";
import { setIsLoading, setError } from "../utils";

const parseSettings = (s) => {
    return {
        limit_sessions_client: s.find(o => o.key === 'limit_sessions_client').value,
        limit_sessions_coach: s.find(o => o.key === 'limit_sessions_coach').value,
        instant_session_time_minutes: s.find(o => o.key === 'instant_session_time_minutes').value,
        stripe_price_id: s.find(o => o.key === 'stripe_price_id').value,
        d2c_enabled: s.find(o => o.key === 'd2c_enabled').value,
        d2c_disabled_message: s.find(o => o.key === 'd2c_disabled_message').value,
    };
};

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        loading: false,
        error: "",

        data: {},

        settings: {},
    },
    reducers: {
        resetData(state) {
            state.data = {}
        },
    },
    extraReducers: {
        [Dashboard.getDashboardData.pending]: setIsLoading(true),
        [Dashboard.getSettings.pending]: setIsLoading(true),
        [Dashboard.saveSettings.pending]: setIsLoading(true),
        [Dashboard.reRunCalendly.pending]: setIsLoading(true),


        [Dashboard.getDashboardData.fulfilled]: (state, action) => {
            state.data = action.payload;
            setIsLoading(false)(state);
        },
        [Dashboard.getSettings.fulfilled]: (state, action) => {
            state.settings = parseSettings(action.payload.settings);
            setIsLoading(false)(state);
        },
        [Dashboard.saveSettings.fulfilled]: (state, action) => {
            state.settings = parseSettings(action.payload.settings);
            setIsLoading(false)(state);
        },
        [Dashboard.reRunCalendly.fulfilled]: (state, action) => {
            // console.log(action.payload.status)
            setIsLoading(false)(state);
        },

        [Dashboard.getDashboardData.rejected]: setError,
        [Dashboard.getSettings.rejected]: setError,
        [Dashboard.saveSettings.rejected]: setError,
        [Dashboard.reRunCalendly.rejected]: setError,
    }
});

export const { resetData } = dashboardSlice.actions;

export default dashboardSlice.reducer;
