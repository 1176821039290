/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import Dashboard from "views/Dashboard.js";
// import Notifications from "views/Notifications.js";
// import Icons from "views/Icons.js";
// import Typography from "views/Typography.js";
// import TableList from "views/Tables.js";
// import Maps from "views/Map.js";
// import UserPage from "views/User.js";
import Institutions from "views/Institutions/Institutions";
import Circles from "views/Circles/Circles";
import Events from "views/Events/Events";
import SignIn from "views/Auth/SignIn";
// import SignUp from "views/Auth/SignUp";
import ResetPassword from "views/Auth/ResetPassword";
import Resources from "views/Resources/Resources";
import Clients from "views/Clients/Clients";
import Coaches from "views/Coaches/Coaches";
import Admins from "views/Admins/Admins";
import Posts from "views/Posts/Posts";
import Settings from "views/Settings/Settings";
import ReportList from "views/Report/ReportList";
import OpenSourceLicensesList from "views/OpenSourceLicenses/OpenSourceLicensesList";
import CoachProfile from "views/CoachProfile/CoachProfile";
import CoachingSessions from "views/CoachingSessions/CoachingSessions";
import MyCoachingSessions from "views/MyCoachingSessions/MyCoachingSessions";
import ClientsNeedingCoach from "views/ClientsNeedingCoach/ClientsNeedingCoach";

var routes = [
    {
        invisible: {
            admin: true,
            coach: true,
        },
        path: "/dashboard",
        name: "Dashboard",
        icon: "nc-icon nc-layout-11",
        component: Dashboard,
        layout: "/admin",
    },
    {
        invisible: {
            admin: true,
            superAdmin: true,
        },
        path: "/coach-profile",
        name: "Coach Profile",
        icon: "nc-icon nc-single-02",
        component: CoachProfile,
        layout: "/admin",
    },
    {
        invisible: {
            coach: true,
        },
        path: "/resources",
        name: "Resources",
        icon: "nc-icon nc-tile-56",
        component: Resources,
        layout: "/admin",
    },
    {
        invisible: {
            admin: true,
            coach: true,
        },
        path: "/institutions",
        name: "Institutions",
        icon: "nc-icon nc-bank",
        component: Institutions,
        layout: "/admin",
    },
    {
        invisible: {
            coach: true,
        },
        path: "/circles",
        name: "Circles",
        icon: "nc-icon nc-planet",
        component: Circles,
        layout: "/admin",
    },
    {
        invisible: {
            coach: true,
        },
        path: "/posts",
        name: "Post",
        icon: "nc-icon nc-album-2",
        component: Posts,
        layout: "/admin",
    },
    {
        invisible: {
            coach: true,
        },
        path: "/coaching-sessions",
        name: "Coaching Sessions",
        icon: "nc-icon nc-calendar-60",
        component: CoachingSessions,
        layout: "/admin",
    },
    {
        path: "/my-coaching-sessions",
        name: "My Coaching Sessions",
        icon: "nc-icon nc-calendar-60",
        component: MyCoachingSessions,
        layout: "/admin",
    },
    {
        invisible: {
            coach: false,
        },
        path: "/clients",
        name: "Clients",
        icon: "nc-icon nc-single-02",
        component: Clients,
        layout: "/admin",
    },
    {
        invisible: {
            coach: true,
        },
        path: "/set-coach",
        name: "Set Coach",
        icon: "nc-icon nc-single-02",
        component: ClientsNeedingCoach,
        layout: "/admin",
    },
    {
        invisible: {
            coach: true,
        },
        path: "/coaches",
        name: "Coaches",
        icon: "nc-icon nc-single-02",
        component: Coaches,
        layout: "/admin",
    },
    {
        invisible: {
            admin: true,
            coach: true,
        },
        path: "/admins",
        name: "Admins",
        icon: "nc-icon nc-single-02",
        component: Admins,
        layout: "/admin",
    },
    {
        invisible: {
            admin: true,
            coach: true,
        },
        path: "/reports",
        name: "Reports",
        icon: "nc-icon nc-ambulance",
        component: ReportList,
        layout: "/admin",
    },
    {
        invisible: {
            coach: true,
        },
        id: "events",
        path: "/events",
        name: "Events",
        icon: "nc-icon nc-tile-56",
        component: Events,
        layout: "/admin",
    },
    {
        invisible: {
            admin: true,
            coach: true,
        },
        path: "/settings",
        name: "Settings",
        icon: "nc-icon nc-settings-gear-65",
        component: Settings,
        layout: "/admin",
    },
    {
        hiddenInSidebar: true,
        path: "/open-source",
        name: "Open Source",
        icon: "nc-icon nc-paper",
        component: OpenSourceLicensesList,
        layout: "/admin",
    },
    // {
    //     path: "/icons",
    //     name: "Icons",
    //     icon: "nc-icon nc-diamond",
    //     component: Icons,
    //     layout: "/admin",
    // },
    //   {
    //     path: "/maps",
    //     name: "Maps",
    //     icon: "nc-icon nc-pin-3",
    //     component: Maps,
    //     layout: "/admin",
    //   },
    // {
    //     path: "/notifications",
    //     name: "Notifications",
    //     icon: "nc-icon nc-bell-55",
    //     component: Notifications,
    //     layout: "/admin",
    // },
    // {
    //     path: "/user-page",
    //     name: "User Profile",
    //     icon: "nc-icon nc-single-02",
    //     component: UserPage,
    //     layout: "/admin",
    // },
    // {
    //     path: "/tables",
    //     name: "Table List",
    //     icon: "nc-icon nc-tile-56",
    //     component: TableList,
    //     layout: "/admin",
    // },
    // {
    //     path: "/typography",
    //     name: "Typography",
    //     icon: "nc-icon nc-caps-small",
    //     component: Typography,
    //     layout: "/admin",
    // },
];

var authRoutes = [
    {
        path: "/sign-in",
        name: "Sign In",
        icon: "nc-icon nc-lock-circle-open",
        component: SignIn,
        layout: "/auth",
    },
    // {
    //     path: "/sign-up",
    //     name: "Sign Up",
    //     icon: "nc-icon nc-badge",
    //     component: SignUp,
    //     layout: "/auth",
    // },
    {
        path: "/reset-password",
        name: "Reset Password",
        icon: "nc-icon nc-tap-01",
        component: ResetPassword,
        layout: "/auth",
    },
];

export { routes, authRoutes };
