/**
 * LifeRamp
 *
 * @author      Chetanya Gupta
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import randomString from "@afaanbilal/random-string";

import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

import DialogModal from "components/Modal/DialogModal";

import { Resource } from "../../api";
import { uploadToFirebase } from "../../api/utils";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Label,
    CardImg,
    Spinner,
    CardSubtitle,
    CardText,
    CardImgOverlay
} from "reactstrap";
import AdminImageModal from "../../components/Modal/ImagePickerAdmin";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const blankResource = { category: "", title: "", subtitle: "", byline: "", image: "", link: "", tags: [] };

const ResourcesAddEdit = (props) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.resource);

    let uuid = useQuery().get("uuid");
    const isEdit = !!uuid || false;
    const resourceData = state.resources ? state.resources.find(r => r.uuid === uuid) : null;
    const [resource, setResource] = React.useState(isEdit ? resourceData : blankResource);
    const [dialogModal, setDialogModal] = React.useState(false);
    const [dialogModalMessage, setDialogModalMessage] = React.useState("");
    const [imagePicker, setImagePicker] = React.useState(false);

    const [uploadingToFirebase, setUploadingToFirebase] = React.useState(false);
    const [uploadedImageBlob, setUploadedImageBlob] = React.useState(null);
    const [shouldSave, setShouldSave] = React.useState(false);

    React.useEffect(() => {
        dispatch(Resource.getCategories());
    }, [dispatch]);

    React.useEffect(() => {
        if (shouldSave) {
            if (isEdit) {
                dispatch(Resource.updateResource({ resource }))
                    .then((result) => {
                        setDialogModal(true);
                        setDialogModalMessage(result.payload.status === "success" ? "Resource saved!" : result.payload.message);
                        setUploadedImageBlob(null);
                    });
            } else {
                dispatch(Resource.createResource({ resource }))
                    .then((result) => {
                        setDialogModal(true);
                        setDialogModalMessage(result.payload.status === "success" ? "Resource added!" : result.payload.message);
                        setUploadedImageBlob(null);
                    });
            }

            setShouldSave(false);
        }
    }, [shouldSave, isEdit, dispatch, resource]);

    const onSave = async () => {
        if (state.loading || uploadingToFirebase) return;

        if (uploadedImageBlob) {
            setUploadingToFirebase(true);
            const url = await uploadToFirebase("resource-images/" + randomString() + "/image.png", uploadedImageBlob);
            setUploadingToFirebase(false);

            if (!url) {
                setDialogModalMessage("Please choose an image less than 5MB");
                return;
            }

            setResource({ ...resource, image: url });
            setShouldSave(true);
        } else {
            setShouldSave(true);
        }
    };

    const onImageCropped = ({ url, blob }) => {
        setUploadedImageBlob(blob);
        setResource({ ...resource, image: url });
    };

    return (
        <div className="content">
            <AdminImageModal closeModal={() => setImagePicker(false)} isModal={imagePicker} aspectRatio={16 / 16} onImageCropped={onImageCropped} />
            <Row>
                <Col md="4">
                    <Card style={{ margin: "0px", borderTopRightRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "2px", borderBottomRightRadius: "2px" }}>
                        <CardImg style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "2px", borderBottomRightRadius: "2px" }} src={`${resource.image ? resource.image : "https://www.careersinafrica.com/wp-content/uploads/2020/05/placeholder.png"}`} alt="Card image cap" />
                        <CardImgOverlay>
                            <Button
                                style={{
                                    position: "absolute",
                                    bottom: "0px",
                                    right: "10px",
                                    color: "grey",
                                    backgroundColor: "white",
                                    fontSize: "24px",
                                    padding: "5px",
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                }}
                                onClick={() => setImagePicker(true)}
                            ><i className="fa fa-camera" aria-hidden="true"></i></Button>
                        </CardImgOverlay>
                    </Card>
                    <Card style={{ borderTopRightRadius: "2px", borderTopLeftRadius: "2px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                        <CardBody>
                            <CardTitle tag="h5">{resource.title || "Title"}</CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted">{resource.subtitle || "Subtitle"}</CardSubtitle>
                            <CardText>Created By</CardText>
                            <CardSubtitle tag="h6" className="mb-2 text-muted">{resource.byline}</CardSubtitle>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="8">
                    <Card className="card-user">
                        <CardHeader>
                            <Row>
                                <Col><CardTitle tag="h5">{isEdit ? "Edit Resource" : "Add Resource"}</CardTitle></Col>
                                <Col><CardTitle tag="h5" className="text-right">
                                    <Link to="/admin/resources"><i className="nc-icon nc-simple-remove" /></Link>
                                </CardTitle>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <label>Category</label>
                                            <Input type="select" name="select" id="resourceCategory"
                                                value={resource.category.uuid}
                                                onChange={(value) => setResource(state => ({ ...state, category_uuid: value.target.value, category: {uuid: value.target.value} }))}>
                                                <option>Select Category</option>
                                                {
                                                    state.categories && state.categories.map(c => <option value={c.uuid} key={c.uuid}>{c.name}</option>)
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Title</Label>
                                            <Input
                                                placeholder="Title"
                                                type="text"
                                                value={resource.title}
                                                onChange={(value) => setResource(state => ({ ...state, title: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Subtitle</Label>
                                            <Input
                                                placeholder="Subtitle"
                                                type="text"
                                                value={resource.subtitle}
                                                onChange={(value) => setResource(state => ({ ...state, subtitle: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Created By</Label>
                                            <Input
                                                placeholder="Created By"
                                                type="text"
                                                value={resource.byline}
                                                onChange={value => setResource(state => ({ ...state, byline: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Link</Label>
                                            <Input
                                                placeholder="Link"
                                                type="text"
                                                value={resource.link}
                                                onChange={value => setResource(state => ({ ...state, link: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Tags</Label>
                                            <TagsInput value={resource.tags || []} onChange={tags => setResource(state => ({ ...state, tags: tags }))} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="update ml-auto mr-auto">
                                        {
                                            state.loading || uploadingToFirebase ? <Spinner color="primary" /> : <Button
                                                className="btn btn-lg btn-block"
                                                color="primary"
                                                type="button"
                                                onClick={onSave}
                                            >
                                                Save
                                            </Button>
                                        }
                                        <DialogModal isModal={dialogModal} closeModal={() => setDialogModal(false)} message={dialogModalMessage} />
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div >
    );
};

export default ResourcesAddEdit;
