/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const getReports = createAsyncThunk(
    "reports/getReports",
    async ({ page }, thunkAPI) => apiThunkHandler(axios.get("/reports?page=" + page), thunkAPI)
);

const getResolvedReports = createAsyncThunk(
    "reports/getResolvedReports",
    async ({ page }, thunkAPI) => apiThunkHandler(axios.get("/reports/resolved?page=" + page), thunkAPI)
);

const resolveReport = createAsyncThunk(
    "reports/resolveReport",
    async ({ report, action }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/reports/" + report.uuid + "/resolve",
            {
                action
            }
        ),
        thunkAPI
    )
);

const report = {
    getReports,
    getResolvedReports,
    resolveReport,
};

export default report;
