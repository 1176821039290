/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const getCategories = createAsyncThunk(
    "resources/getCategories",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/resources/categories"), thunkAPI)
);

const getResources = createAsyncThunk(
    "resources/getResources",
    async ({ page, search }, thunkAPI) => apiThunkHandler(axios.get("/resources?page=" + page + (search ? "&search=" + search : "")), thunkAPI)
);

const getResourcesAll = createAsyncThunk(
    "resources/getResourcesAll",
    async ({ page, search, filters = "" }, thunkAPI) => apiThunkHandler(axios.get("/resources/all?page=" + page + (search ? "&search=" + search : "") + (filters ? "&filters=" + filters : "")), thunkAPI)
);

const createResource = createAsyncThunk(
    "resources/createResource",
    async ({ resource }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/resources",
            {
                ...resource
            }
        ),
        thunkAPI
    )
);

const updateResource = createAsyncThunk(
    "resources/updateResource",
    async ({ resource }, thunkAPI) => apiThunkHandler(
        axios.put(
            "/resources/" + resource.uuid,
            {
                ...resource
            }
        ),
        thunkAPI
    )
);

const deleteResource = createAsyncThunk(
    "resources/deleteResource",
    async ({ resource }, thunkAPI) => apiThunkHandler(axios.delete("/resources/" + resource.uuid), thunkAPI)
);

const resource = {
    getCategories,
    getResources,
    getResourcesAll,
    createResource,
    updateResource,
    deleteResource,
};

export default resource;
