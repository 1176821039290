/**
 * LifeRamp
 *
 * @author      Burhan Rashid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Route, Switch, } from "react-router-dom";

import { authRoutes } from "routes.js";
import Footer from "components/Footer/Footer";

function Dashboard(props) {
    const mainPanel = React.useRef();

    return (
        <div className="container-fluid" ref={mainPanel}>
            <div className="logo">
                <div className="logo-img">
                    <img style={{ height: "10%", width: "10%", marginTop: "1%", marginLeft: "2%" }} src={require("assets/img/LifeRamp.png").default} alt="react-logo" />
                </div>
            </div>
            <Switch>
                {authRoutes.map((prop, key) => {
                    return (
                        <Route
                            path={prop.layout + prop.path}
                            component={prop.component}
                            key={key}
                        />
                    );
                })}
            </Switch>
            <Footer fluid />
        </div>
    );
};

export default Dashboard;
