/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const getEvents = createAsyncThunk(
    "events/getEvents",
    async ({ page, search = "" }, thunkAPI) => apiThunkHandler(axios.get("/events?page=" + page + (search ? "&search=" + search : "")), thunkAPI)
);

const event = {
    getEvents,
};

export default event;
