/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button,
    ButtonGroup,
} from "reactstrap";

import Search from "../../components/utils/Search";
import TableLoading from "../../components/utils/TableLoading";
import MainPagination from "../../components/utils/MainPagination";
import DialogModal from "components/Modal/DialogModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ModalFilter from "components/Modal/ModalFilter";

import { Resource } from "../../api";
import { prevPage, nextPage } from "../../state/slices/resourceSlice";


function ResourceList() {
    const dispatch = useDispatch();
    const state = useSelector(state => state.resource);

    const [search, setSearch] = React.useState("");

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const [filters, setFilters] = React.useState([]);
    const [filterModalData, setFilterModalData] = React.useState({
        loadData: true,
        search: {},
    });

    React.useEffect(() => {
        if (filters.length) {
            dispatch(Resource.getResourcesAll({ page: state.page, search, filters: filters.length && JSON.stringify(filters) }));
        } else {
            dispatch(Resource.getResources({ page: state.page, search }));
        }
    }, [dispatch, state.page, search, filters]);

    const [dialogModal, setDialogModal] = React.useState(false);
    const [dialogModalMessage, setDialogModalMessage] = React.useState("");

    const [displayConfirmationModal, setDisplayConfirmationModal] = React.useState(false);
    const [confirmationModalData, setConfirmationModalData] = React.useState({ message: "", id: "" });

    const confirmDeleteResource = (obj) => {
        setConfirmationModalData({ message: "You are about to delete a Resource. Are you sure?", obj: obj })
        setDisplayConfirmationModal(true);
    };

    const hideConfirmationModal = () => setDisplayConfirmationModal(false);

    const deleteResource = (resource) => {
        dispatch(Resource.deleteResource({ resource }))
            .then((result) => {
                hideConfirmationModal();
                setDialogModal(true);
                setDialogModalMessage(result.payload.status === "success" ? "Resource deleted!" : result.payload.message);
            });
    }

    const closeDialogModal = () => setDialogModal(false);

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col md="6">
                                    <CardTitle tag="h4">Resources</CardTitle>
                                </Col>
                                <Col md="6">
                                    <Row style={{ justifyContent: "flex-end" }}>
                                        <Col md="7" className="d-flex justify-content-end align-items-center">
                                            <div className="ml-2 d-inline-block">
                                                <Link to="/admin/resources/addEdit?id="><Button color="primary" style={{ marginTop: "15px" }}>Add</Button></Link>
                                            </div>
                                            <div className="ml-3 d-inline-block">
                                                {!filters.length ?
                                                    <Button className={'btn-round'} color="primary" onClick={() => setShowFilterModal(true)}>Apply Filters</Button>
                                                    :
                                                    <div style={{ position: 'relative' }}>
                                                        <Button className={'btn-round'} color="warning" onClick={() => setShowFilterModal(true)}>Edit Filters</Button>
                                                        <button className="close" style={{ position: 'absolute', top: '35%', right: '-18px' }} onClick={() => setFilters([])}>×</button>
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                        <Col md="4" className="pt-3 mr-3">
                                            <Search value={search} onChange={setSearch} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead className="text-primary">
                                    <tr>
                                        <th>Category</th>
                                        <th>Title</th>
                                        <th>Created by</th>
                                        <th className="text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state.resources.map(i =>
                                            <tr key={i.uuid}>
                                                <td>{i.category.name}</td>
                                                <td>{i.title}</td>
                                                <td>{i.byline}</td>
                                                <td className="text-right">
                                                    <ButtonGroup size="sm">
                                                        <Link to={"/admin/resources/addEdit?uuid=" + i.uuid}>
                                                            <Button color="info mr-2" className="btn-link">
                                                                <i className="fa fa-pen"></i>
                                                            </Button>
                                                        </Link>
                                                        <Button color="danger" className="btn-link" onClick={() => confirmDeleteResource(i)}><i className="fa fa-trash"></i></Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {state.loading && <TableLoading colSpan={4} />}
                                    {!state.loading && !state.resources.length &&
                                        <tr>
                                            <td className="text-center py-4" colSpan={4}>
                                                <h5>{search ? "No result." : "There's nothing but empty space."}</h5>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            <MainPagination page={state.page} prevPage={() => dispatch(prevPage())} nextPage={() => dispatch(nextPage())} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <DialogModal message={dialogModalMessage} closeModal={closeDialogModal} isModal={dialogModal} />
            <ConfirmationModal type="delete" showModal={displayConfirmationModal} confirmModal={deleteResource} hideModal={hideConfirmationModal} data={confirmationModalData} />
            <ModalFilter showModal={showFilterModal} hideModal={() => setShowFilterModal(false)} filterModalData={filterModalData} setFilterModalData={setFilterModalData} selectedFilter={filters} setFilter={setFilters} types={['resource-category', 'date-range']} />        </div>
    );
}

export default ResourceList;
