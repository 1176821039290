/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";

function TableLoading({ colSpan }) {
    return (
        <tr>
            <td className="text-center py-4" colSpan={colSpan}>
                <h5>Loading...</h5>
            </td>
        </tr>
    );
}

export default TableLoading;
