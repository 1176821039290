/**
 * LifeRamp
 *
 * @author      Burhan Rashid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Spinner,
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { User } from "../../api";
import { setResetStage, clearError } from "../../state/slices/userSlice";

function ResetPassword() {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.user.loading);
    const error = useSelector(state => state.user.error);
    const resetData = useSelector(state => state.user.resetData);

    const [email, setEmail] = React.useState("");
    const [code, setCode] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");

    const stage = resetData.stage;

    React.useEffect(() => {
        if (stage === User.resetStages.DONE) {
            dispatch(setResetStage({ stage: User.resetStages.REQUEST }));
            setEmail("");
            setPassword("");
            setConfirmPassword("");
            alert("Password reset successful. Please sign in using your new password.");
        }
    }, [stage, dispatch]);

    const submitEmail = () => {
        if (email === "" || email === null) {
            return alert("Please enter your email address.");
        }

        dispatch(User.requestResetPassword({ email }));
    };

    const submitCode = () => {
        dispatch(clearError());

        if (code === null || code === "") {
            return alert("Please enter verification code.");
        }

        dispatch(User.verifyCode({ email, code, uuid: resetData.uuid }));
    };

    const resetPassword = () => {
        if (password === "" || confirmPassword === "") {
            return alert("Please fill both the fields.");
        }

        if (password !== confirmPassword) {
            return alert("Passwords don't match.");
        }

        dispatch(User.resetPassword({ email, password, uuid: resetData.uuid, token: resetData.token }));
    };

    const backToSignIn = () => {
        dispatch(clearError());
        dispatch(setResetStage({ stage: User.resetStages.REQUEST }));
    };

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card className="card-user shadow-none">
                        <Row style={{ marginTop: "8%" }}>
                            <Col md="4" style={{ marginLeft: "10%" }}>
                                <CardHeader>
                                    <CardTitle tag="h5" className="">Reset Password</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {error !== "" && <p>{error}</p>}
                                    {stage === User.resetStages.REQUEST &&
                                        <Form>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <label>Email</label>
                                                        <Input
                                                            placeholder="Email"
                                                            type="text"
                                                            onChange={(event) => setEmail(event.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className="" style={{ marginLeft: "15px" }}>
                                                    {loading ? <Spinner color="primary" />
                                                        :
                                                        <Button
                                                            className="btn btn-lg btn-block"
                                                            color="primary"
                                                            type="button"
                                                            onClick={submitEmail}
                                                        >
                                                            Submit
                                                        </Button>
                                                    }
                                                </div>
                                            </Row>
                                        </Form>
                                    }
                                    {stage === User.resetStages.VERIFY &&
                                        <Form>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <label>Verification Code</label>
                                                        <Input
                                                            placeholder="verification code"
                                                            type="text"
                                                            onChange={(event) => setCode(event.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className="" style={{ marginLeft: "15px" }}>
                                                    {loading ? <Spinner color="primary" />
                                                        :
                                                        <Button
                                                            className="btn btn-lg btn-block"
                                                            color="primary"
                                                            type="button"
                                                            onClick={submitCode}
                                                        >
                                                            Submit Code
                                                        </Button>
                                                    }
                                                </div>
                                            </Row>
                                        </Form>
                                    }
                                    {stage === User.resetStages.RESET &&
                                        <Form>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <label>Password</label>
                                                        <Input
                                                            placeholder="Password"
                                                            type="password"
                                                            onChange={(event) => setPassword(event.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <label>Confirm Password</label>
                                                        <Input
                                                            placeholder="Confirm Password"
                                                            type="password"
                                                            onChange={(event) => setConfirmPassword(event.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className="" style={{ marginLeft: "15px" }}>
                                                    {loading ? <Spinner color="primary" />
                                                        :
                                                        <Button
                                                            className="btn btn-lg btn-block"
                                                            color="primary"
                                                            type="button"
                                                            onClick={resetPassword}
                                                        >
                                                            Submit
                                                        </Button>
                                                    }
                                                </div>
                                            </Row>
                                        </Form>
                                    }
                                    <Row>
                                        <Col md="12">
                                            <Link to="/auth/sign-in" onClick={backToSignIn}><p>Back to Sign In</p></Link>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Col>
                            <Col md="6">
                                <img style={{ width: "80%", height: "80%" }}
                                    alt="..."
                                    src={require("assets/img/demoImg.jpg").default}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ResetPassword;
