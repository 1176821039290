/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import datetime from "date-and-time";
import { Bar, Pie } from "react-chartjs-2";

import {
    barChartOptions,
    pieChartOptions,
    dashboardSessionChartFn,
    dashboardSessionStatsChartFn,
} from "variables/charts.js";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Table,
    Row,
    Col,
    Button,
    Spinner,
} from "reactstrap";

import Search from "../../components/utils/Search";
import MainPagination from "../../components/utils/MainPagination";
import ModalFilter from "components/Modal/ModalFilter";

import { CoachingSession } from "../../api";
import { prevPage, nextPage } from "../../state/slices/coachingSessionSlice";
import axios from "axios";

function CoachingSessionList() {
    const dispatch = useDispatch();

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const state = useSelector(state => state.coachingSession);

    const [search, setSearch] = React.useState("");

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const [filters, setFilters] = React.useState([]);
    const [filterModalData, setFilterModalData] = React.useState({
        loadData: true,
        search: {},
    });

    React.useEffect(() => {
        dispatch(CoachingSession.getCoachingSessions({ page: state.page, search, filters: filters.length && JSON.stringify(filters) }))
            .unwrap()
            .then(() => forceUpdate());
    }, [dispatch, state.page, search, filters, forceUpdate]);

    const coachingSessionRatingChart = React.useRef();
    const coachingSessionStatsChart = React.useRef();

    React.useEffect(() => {
        if (typeof state.sessionsByRating !== "undefined") {
            coachingSessionRatingChart.current = dashboardSessionChartFn(state.sessionsByRating);
        }
        if (typeof state.sessionsByStatus !== "undefined") {
            coachingSessionStatsChart.current = dashboardSessionStatsChartFn(state.sessionsByStatus.active, state.sessionsByStatus.completed, state.sessionsByStatus.cancelled);
        }
    }, [state.sessionsByRating, state.sessionsByStatus]);

    const exportData = () => {
        axios.get("/coaching-sessions/export?" + (search ? "&search=" + search : "") + (filters ? "&filters=" + filters : "")).then((response) => {
            const type = response.headers["content-type"];
            const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "coaching-session " + new Date().toLocaleString() + ".csv";
            link.click();
        });

    };

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col md="6">
                                    <CardTitle tag="h4">Coaching Sessions</CardTitle>
                                </Col>
                                <Col md="6">
                                    <Row style={{ justifyContent: "flex-end" }}>
                                        <Col md="7" className="d-flex justify-content-end">
                                            <div className="ml-2 d-inline-block">
                                                {!filters.length ?
                                                    <Button className={'btn-round'} style={{ marginTop: "15px" }} color="primary" onClick={() => setShowFilterModal(true)}>Apply Filters</Button>
                                                    :
                                                    <div style={{ position: 'relative' }}>
                                                        <Button className={'btn-round'} style={{ marginTop: "15px" }} color="warning" onClick={() => setShowFilterModal(true)}>Edit Filters</Button>
                                                        <button className="close" style={{ position: 'absolute', top: '35%', right: '-18px' }} onClick={() => setFilters([])}>×</button>
                                                    </div>
                                                }
                                            </div>
                                            <Button className={'btn-round ml-4'} style={{ marginTop: "15px" }} color="primary" onClick={exportData}>Export</Button>
                                        </Col>
                                        <Col md="4" className="pt-3 mr-3">
                                            <Search value={search} onChange={setSearch} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardHeader>
                        {state.loading && <div className="content text-center py-5"><Spinner color="primary" /></div>}
                        {!state.loading &&
                            <Row style={{ gap: 10, justifyContent: "space-between" }} className="mx-4 my-3">
                                <Col style={{ minWidth: 400, flex: 1 }} className="card">
                                    {typeof coachingSessionRatingChart.current !== "undefined" &&
                                        <Col md="12">
                                            <CardHeader>
                                                <CardTitle tag="h5">Completed Sessions</CardTitle>
                                                <p className="card-category">by ⭐ rating</p>
                                            </CardHeader>
                                            <CardBody>
                                                <Bar data={coachingSessionRatingChart.current} options={barChartOptions} />
                                            </CardBody>
                                        </Col>
                                    }
                                </Col>
                                <Col style={{ minWidth: 400, flex: 1 }} className="card">
                                    <Col md="12">
                                        <CardHeader>
                                            <CardTitle tag="h5" >Total Sessions</CardTitle>
                                            <p className="card-category">Filters applied</p>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col md="12" xs="12">
                                                    <div className="numbers text-center">
                                                        <CardTitle tag="p" style={{ fontSize: 100 }}>{state.totalCount}</CardTitle>
                                                    </div>
                                                    <Row className="mt-3">
                                                        <Col md="4 text-center">
                                                            <div style={{ fontSize: 40 }}>{state.sessionsByStatus.active}</div>
                                                            <div style={{ fontSize: 15, opacity: 0.8 }}>Active</div>
                                                        </Col>
                                                        <Col md="4 text-center">
                                                            <div style={{ fontSize: 40 }}>{state.sessionsByStatus.completed}</div>
                                                            <div style={{ fontSize: 15, opacity: 0.8 }}>Completed</div>
                                                        </Col>
                                                        <Col md="4 text-center">
                                                            <div style={{ fontSize: 40 }}>{state.sessionsByStatus.cancelled}</div>
                                                            <div style={{ fontSize: 15, opacity: 0.8 }}>Cancelled</div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Col>
                                </Col>
                                <Col style={{ minWidth: 400, flex: 1 }} className="card">
                                    {typeof coachingSessionStatsChart.current !== "undefined" &&
                                        <Col md="12" className="d-flex flex-column" style={{ justifyContent: "space-between" }}>
                                            <CardHeader>
                                                <CardTitle tag="h5">Completed Sessions</CardTitle>
                                                <p className="card-category">by status</p>
                                            </CardHeader>
                                            <CardBody>
                                                <Pie data={coachingSessionStatsChart.current} options={pieChartOptions} />
                                            </CardBody>
                                            <CardFooter>
                                                <div className="legend">
                                                    <i className="fa fa-circle text-primary" /> Active{" "}
                                                    <i className="fa fa-circle text-success" /> Completed{" "}
                                                    <i className="fa fa-circle text-danger" /> Cancelled{" "}
                                                </div>
                                            </CardFooter>
                                        </Col>
                                    }
                                </Col>
                            </Row>
                        }
                        {!state.loading &&
                            <CardBody>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th>Name</th>
                                            <th>Start at</th>
                                            <th>Coach</th>
                                            <th>Client</th>
                                            <th>Status</th>
                                            <th>Coach comments</th>
                                            <th>Client status</th>
                                            <th>Client comments</th>
                                            <th>Rating</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !state.loading && state.coachingSessions && state.coachingSessions.map(i =>
                                                <tr key={i.uuid}>
                                                    <td>{i.name}</td>
                                                    <td>{datetime.format(new Date(i.start_at), "MMM D YYYY h:mm A")}</td>
                                                    <td>{i.coach?.name}</td>
                                                    <td>{i.user?.name}</td>
                                                    <td>{i.status}</td>
                                                    <td>{i.coach_comments}</td>
                                                    <td>{i.client_status}</td>
                                                    <td>{i.client_comments}</td>
                                                    <td>{i.client_rating}{i.client_rating ? "/ 5" : ""}</td>
                                                </tr>
                                            )
                                        }
                                        {!state.loading && !state.coachingSessions &&
                                            <tr>
                                                <td className="text-center py-4" colSpan={9}>
                                                    <h5>{search ? "No result." : "There's nothing but empty space."}</h5>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                                <MainPagination page={state.page} prevPage={() => dispatch(prevPage())} nextPage={() => dispatch(nextPage())} />
                            </CardBody>
                        }
                    </Card>
                </Col>
            </Row>
            <ModalFilter showModal={showFilterModal} hideModal={() => setShowFilterModal(false)} filterModalData={filterModalData} setFilterModalData={setFilterModalData} selectedFilter={filters} setFilter={setFilters} types={["institution", "client", "coach", "date-range"]} />
        </div>
    );
}

export default CoachingSessionList;
