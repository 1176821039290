/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const getAdmins = createAsyncThunk(
    "admins/getAdmins",
    async ({ page, search = "", filters = "" }, thunkAPI) => apiThunkHandler(axios.get("/admins?page=" + page + (search ? '&search=' + search : '') + (filters ? "&filters=" + filters : "")), thunkAPI)
);

const createAdmin = createAsyncThunk(
    "admins/createAdmin",
    async ({ admin }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/admins",
            {
                ...admin
            }
        ),
        thunkAPI
    )
);

const updateAdmin = createAsyncThunk(
    "admins/updateAdmin",
    async ({ admin }, thunkAPI) => apiThunkHandler(
        axios.put(
            "/admins/" + admin.uuid,
            {
                ...admin
            }
        ),
        thunkAPI
    )
);

const deleteAdmin = createAsyncThunk(
    "admins/deleteAdmin",
    async ({ admin }, thunkAPI) => apiThunkHandler(axios.delete("/admins/" + admin.uuid), thunkAPI)
);

const admin = {
    getAdmins,
    createAdmin,
    updateAdmin,
    deleteAdmin,
};

export default admin;
