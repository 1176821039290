/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useLocation } from "react-router-dom";

import Navbar from "components/Navbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";

import { routes } from "routes";
import { User } from "api";

var ps;

function Dashboard(props) {
    const dispatch = useDispatch();

    const [backgroundColor, setBackgroundColor] = React.useState("white");
    const [activeColor, setActiveColor] = React.useState("info");
    const mainPanel = React.useRef();
    const location = useLocation();

    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle("perfect-scrollbar-on");
        }
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                document.body.classList.toggle("perfect-scrollbar-on");
            }
        };
    });

    React.useEffect(() => {
        mainPanel.current.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [location]);

    React.useEffect(() => {
        dispatch(User.getMyProfile());
    }, [dispatch]);

    // eslint-disable-next-line
    const handleActiveClick = (color) => {
        setActiveColor(color);
    };

    // eslint-disable-next-line
    const handleBgClick = (color) => {
        setBackgroundColor(color);
    };

    const userState = useSelector(state => state.user);
    const isSuperAdmin = userState.profile && userState.profile.isSuperAdmin;
    const isCoach = userState.profile && userState.profile.isCoach;

    let role = "admin";
    if (isSuperAdmin) {
        role = "superAdmin";
    } else if (isCoach) {
        role = "coach";
    }

    return (
        <div className="wrapper">
            <Sidebar
                {...props}
                routes={
                    routes
                        .filter(r => !r.invisible || !r.invisible[role])
                        .filter(r => isSuperAdmin || r.id !== "events" || userState?.profile.institution?.is_enabled_events)
                }
                bgColor={backgroundColor}
                activeColor={activeColor}
            />
            <div className="main-panel" ref={mainPanel}>
                <Navbar {...props} />
                <Switch>
                    {routes.map((prop, key) => {
                        return (
                            <Route
                                path={prop.layout + prop.path}
                                component={prop.component}
                                key={key}
                            />
                        );
                    })}
                </Switch>
                <Footer fluid />
            </div>
        </div>
    );
}

export default Dashboard;
