/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Route } from "react-router-dom";

import CoachingSessionList from "./CoachingSessionList";

function CoachingSessions() {
    return (
        <>
            <Route path="/admin/coaching-sessions" exact component={CoachingSessionList} />
        </>
    );
}

export default CoachingSessions;
