/**
 * LifeRamp
 *
 * @author      Waris Anwar
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";

import { Row } from "reactstrap";

import { Licenses } from "./Licenses";

function OpenSourceLicensesList() {
    return (
        <div className="content">
            <h1 className="text-center">LifeRamp</h1>
            <h2 className="text-center">Open Source Licenses</h2>
            <p>The following sets forth attribution notices for third-party software that may be contained in portions of this product.</p>
            <hr />
            {
                Licenses.map((i) => {
                    return (
                        <Row className="flex-column ml-0 my-3 border-bottom" key={i.copyright}>
                            <div className="d-flex align-items-center">
                                <h5 className="mb-1">{i.name}</h5>
                                <a href={i.url} target="_blank" rel="noreferrer" className="ml-3"> {i.license} License </a>
                            </div>
                            <p>
                                {i.copyright}
                            </p>
                        </Row>
                    )
                })
            }
        </div>
    )
}

export default OpenSourceLicensesList;
