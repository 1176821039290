/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button,
    ButtonGroup,
} from "reactstrap";
import { Link } from "react-router-dom";

import Search from "../../components/utils/Search";
import TableLoading from "../../components/utils/TableLoading";
import MainPagination from "../../components/utils/MainPagination";

import { Client } from "../../api";
import { updateNeedingCoachPage } from "../../state/slices/clientSlice";
import ModalFilter from "components/Modal/ModalFilter";

function ClientsNeedingCoachList() {
    const dispatch = useDispatch();
    const state = useSelector(state => state.client);

    const [search, setSearch] = React.useState("");

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const [filters, setFilters] = React.useState([]);
    const [filterModalData, setFilterModalData] = React.useState({
        loadData: true,
        search: {},
    });

    React.useEffect(() => {
        dispatch(Client.needingCoach({ page: state.needingCoachPage, search, filters: filters.length && JSON.stringify(filters) }));
    }, [dispatch, state.needingCoachPage, search, filters]);

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col md="6">
                                    <CardTitle tag="h4">Clients</CardTitle>
                                </Col>
                                <Col md="6">
                                    <Row style={{ justifyContent: "flex-end" }}>
                                        <Col md="7" className="d-flex justify-content-end" style={{ marginTop: "15px" }} >
                                            <div className="d-inline-block">
                                            </div>
                                            <div className="ml-3 d-inline-block">
                                                {!filters.length ?
                                                    <Button className={'btn-round'} color="primary" onClick={() => setShowFilterModal(true)}>Apply Filters</Button>
                                                    :
                                                    <div style={{ position: 'relative' }}>
                                                        <Button className={'btn-round'} color="warning" onClick={() => setShowFilterModal(true)}>Edit Filters</Button>
                                                        <button className="close" style={{ position: 'absolute', top: '35%', right: '-18px' }} onClick={() => setFilters([])}>×</button>
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                        <Col md="4" className="pt-3 mr-3">
                                            <Search value={search} onChange={setSearch} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead className="text-primary">
                                    <tr>
                                        <th>Firstname</th>
                                        <th>Lastname</th>
                                        <th>Email</th>
                                        <th>Institution</th>
                                        <th>Circle</th>
                                        <th className="text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !state.loading && state.needingCoach.map(i =>
                                            <tr key={i.uuid}>
                                                <td>{i.firstname}</td>
                                                <td>{i.lastname}</td>
                                                <td>{i.email}</td>
                                                <td>{i.institution?.shortname}</td>
                                                <td>{i.circle?.name}</td>
                                                <td className="text-right">
                                                    <ButtonGroup size="sm">
                                                        <Link to={"/admin/set-coach/update?uuid=" + i.uuid}>
                                                            <Button color="info mr-2" className="btn-link">
                                                                <i className="fa fa-arrow-right"></i>
                                                            </Button>
                                                        </Link>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {state.loading && <TableLoading colSpan={6} />}
                                    {!state.loading && !state.needingCoach.length &&
                                        <tr>
                                            <td className="text-center py-4" colSpan={6}>
                                                <h5>{search ? "No result." : "There's nothing but empty space."}</h5>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            <MainPagination page={state.page} prevPage={() => dispatch(updateNeedingCoachPage({ val: -1 }))} nextPage={() => dispatch(updateNeedingCoachPage({ val: 1 }))} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <ModalFilter showModal={showFilterModal} hideModal={() => setShowFilterModal(false)} filterModalData={filterModalData} setFilterModalData={setFilterModalData} selectedFilter={filters} setFilter={setFilters} types={['institution', 'circle']} />
        </div>
    );
}

export default ClientsNeedingCoachList;
