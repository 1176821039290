/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Progress, Button, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import FormInputs from "components/utils/FormInputs";

import { Coach } from "../../api";
import { setProfileSingleAnswer, setProfileMultiAnswer } from "../../state/slices/coachSlice";

const CoachProfileSetup = (props) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(Coach.getProfileQuestions());
        dispatch(Coach.getProfileData());
    }, [dispatch]);

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [error, setError] = React.useState("");
    const [currentIndex, setCurrentIndex] = React.useState(0);

    const profileQuestions = useSelector(state => state.coach.profileQuestions);
    const currentQuestion = useSelector(state => state.coach.profileQuestions[currentIndex] || null);
    const currentAnswer = useSelector(state => state.coach.profileAnswers.find(o => currentQuestion ? o.uuid === currentQuestion.uuid : false)) || null;

    const [progressBar, setProgressBar] = React.useState(0);

    React.useEffect(() => {
        setProgressBar((currentIndex + 1) / profileQuestions.length * 100);
    }, [currentIndex, profileQuestions]);

    const checkForErrors = () => {
        if (currentQuestion?.type === "number") {
            if (currentAnswer?.answer[0] < 1900) {
                return setError("Please enter a valid year.");
            } else if ((new Date().getFullYear()) - currentAnswer?.answer[0] < 18 ) {
                return setError("LifeRamp coaching platform is only available to 18+.");
            }
        } else {
            if (!currentAnswer || !currentAnswer.answer.length) {
                return setError("Please select at least one.");
            }
        }
        setError("");
        next();
    }

    const next = async () => {
        dispatch(Coach.setProfileAnswer({ uuid: currentAnswer.uuid, answer: currentAnswer.answer }));
        if (currentIndex < profileQuestions.length) {
            setCurrentIndex(currentIndex + 1);
        }
        if (currentIndex + 1 === profileQuestions.length) {
            setIsModalOpen(false);
            setCurrentIndex(0);
            props.onMatchingProfileSetup();
        }
    };

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
        setError("");
    };

    const onPressAnswer = (a) => {
        if (currentQuestion.type === "multi-select") {
            dispatch(setProfileMultiAnswer({ uuid: currentQuestion.uuid, answer: a }));
        } else {
            dispatch(setProfileSingleAnswer({ uuid: currentQuestion.uuid, answer: a }));
        }

        setError("");
    };

    return (
        <>
            <div className="w-100 text-center">
                <Button
                    className="btn-round text-center"
                    color="primary"
                    type="button"
                    onClick={() => setIsModalOpen(true)}>
                    Matching Profile Setup
                </Button>
            </div>
            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)} style={{ maxWidth: 900 }}>
                <ModalHeader className="text-center mt-4 mb-1">
                    Matching Profile Setup
                </ModalHeader>
                <ModalBody>
                    <div className="content">
                        <Progress
                            value={progressBar}
                            style={{ borderRadius: "0" }}
                            barStyle={{ background: "linear-gradient(to right, #00D6F6, #10CC53)" }}
                            className="mx-n3 mt-n4 mb-2" />
                        <div className="container" style={{ backgroundColor: "white", borderRadius: "5px", minHeight: 170 }}>
                            <div className="text-center text-danger py-1"> {error} </div>
                            {currentQuestion !== null &&
                                <FormGroup tag="fieldset">
                                    <Label>{currentQuestion.question}</Label>
                                    <div className="d-flex flex-wrap mt-2">
                                        {currentQuestion.type === 'single-select' &&
                                            <>
                                                {currentQuestion.answers.length < 10 ?
                                                    currentQuestion.answers.map((a, i) => (
                                                        <FormGroup className="mx-5" style={{ minWidth: 300 }} key={i}>
                                                            <FormInputs
                                                                inputType="radio"
                                                                name={a}
                                                                radioLabel={a}
                                                                radioChecked={currentAnswer && currentAnswer.answer.includes(a)}
                                                                onChange={() => { onPressAnswer(a) }} />
                                                        </FormGroup>))
                                                    :
                                                    <FormGroup className="ml-4">
                                                        <FormInputs
                                                            inputType="select"
                                                            selectLabel="Choose an Option"
                                                            onChange={(val) => { onPressAnswer(val) }}
                                                            inputValue={currentAnswer ? currentAnswer.answer[0] : null}
                                                            selectList={currentQuestion.answers} />
                                                    </FormGroup>}
                                            </>
                                        }
                                        {currentQuestion.type === 'multi-select' &&
                                            currentQuestion.answers.map((a, i) => (
                                                <FormGroup className="mx-5" style={{ minWidth: 300 }} key={i}>
                                                    <FormInputs
                                                        inputType="checkbox"
                                                        checkboxLabel={a}
                                                        checkboxChecked={currentAnswer && currentAnswer.answer.includes(a)}
                                                        onChange={() => onPressAnswer(a)} />
                                                </FormGroup>
                                            ))
                                        }
                                        {currentQuestion.type === 'number' &&
                                            <FormInputs
                                                inputType="number"
                                                inputValue={currentAnswer ? currentAnswer.answer[0] : ""}
                                                onChange={(val) => onPressAnswer(val)} />
                                        }
                                    </div>
                                </FormGroup>
                            }
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={currentIndex === 0 ? "d-flex justify-content-end w-100" : "d-flex justify-content-between w-100"}>
                        {currentIndex !== 0 &&
                            <Button
                                className="btn-round"
                                color="primary"
                                type="button"
                                onClick={prev}>
                                Prev
                            </Button>
                        }
                        <Button
                            className="btn-round"
                            color="primary"
                            type="button"
                            onClick={checkForErrors}>
                            {currentIndex + 1 < profileQuestions.length ? 'Next' : 'Submit'}
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default CoachProfileSetup;
