/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { Report } from "../../api";
import { setIsLoading, setError } from "../utils";

export const reportSlice = createSlice({
    name: "report",
    initialState: {
        loading: false,
        error: "",

        uuid: null,
        data: {},

        reports: [],
        page: 1,
        maxPage: 1,
    },
    reducers: {
        setReport(state, { payload: { report } }) {
            state.uuid = report.uuid;
            state.data = report;
        },
        prevPage(state) {
            if (state.page > 1) {
                state.page--;
            }
        },
        nextPage(state) {
            if (state.page < state.maxPage) {
                state.page++;
            }
        },
    },
    extraReducers: {
        [Report.getReports.pending]: setIsLoading(true),
        [Report.resolveReport.pending]: setIsLoading(true),

        [Report.getReports.fulfilled]: (state, action) => {
            state.maxPage = action.payload.maxPage
            state.reports = action.payload.reports;
            setIsLoading(false)(state);
        },
        [Report.resolveReport.fulfilled]: (state, action) => {
            state.reports.splice(state.reports.indexOf(action.meta.arg.report), 1);
            setIsLoading(false)(state);
        },

        [Report.getReports.rejected]: setError,
        [Report.resolveReport.rejected]: setError,
    }
});

export const { setReport, prevPage, nextPage } = reportSlice.actions;

export default reportSlice.reducer;
