/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { Institution } from "../../api";
import { setIsLoading, setError } from "../utils";

export const institutionSlice = createSlice({
    name: "institution",
    initialState: {
        loading: false,
        error: "",

        uuid: null,
        data: {},

        institutions: [],
        page: 1,
        maxPage: 1,

        circles: [],
    },
    reducers: {
        setInstitution(state, { payload: { institution } }) {
            state.uuid = institution.uuid;
            state.data = institution;
        },
        prevPage(state) {
            if (state.page > 1) {
                state.page--;
            }
        },
        nextPage(state) {
            if (state.page < state.maxPage) {
                state.page++;
            }
        },
    },
    extraReducers: {
        [Institution.getInstitutions.pending]: setIsLoading(true),
        [Institution.createInstitution.pending]: setIsLoading(true),
        [Institution.updateInstitution.pending]: setIsLoading(true),
        [Institution.deleteInstitution.pending]: setIsLoading(true),
        [Institution.getCircles.pending]: setIsLoading(true),

        [Institution.getInstitutions.fulfilled]: (state, action) => {
            state.maxPage = action.payload.maxPage
            state.institutions = action.payload.institutions;
            setIsLoading(false)(state);
        },
        [Institution.createInstitution.fulfilled]: (state, action) => {
            const institution = action.payload.institution;
            state.institutions.unshift(institution)
            setIsLoading(false)(state);
        },
        [Institution.updateInstitution.fulfilled]: (state, action) => {
            const institution = action.payload.institution;
            state.institutions.splice(state.institutions.indexOf(state.institutions.find(i => i.uuid === institution.uuid)), 1, institution);
            setIsLoading(false)(state);
        },
        [Institution.deleteInstitution.fulfilled]: (state, action) => {
            const institution = action.meta.arg.institution;
            state.institutions.splice(state.institutions.indexOf(state.institutions.find(i => i.uuid === institution.uuid)), 1);
            setIsLoading(false)(state);
        },
        [Institution.getCircles.fulfilled]: (state, action) => {
            state.circles = action.payload.circles;
            setIsLoading(false)(state);
        },

        [Institution.getInstitutions.rejected]: setError,
        [Institution.createInstitution.rejected]: setError,
        [Institution.updateInstitution.rejected]: setError,
        [Institution.deleteInstitution.rejected]: setError,
        [Institution.getCircles.rejected]: setError,
    }
});

export const { setInstitution, prevPage, nextPage } = institutionSlice.actions;

export default institutionSlice.reducer;
