/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button,
    ButtonGroup,
} from "reactstrap";
import { Link } from "react-router-dom";

import Search from "../../components/utils/Search";
import TableLoading from "../../components/utils/TableLoading";
import MainPagination from "../../components/utils/MainPagination";
import DialogModal from "components/Modal/DialogModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";

import { Client } from "../../api";
import { prevPage, nextPage } from "../../state/slices/clientSlice";
import ClientImportModal from "components/Modal/ClientImportModal";
import ModalFilter from "components/Modal/ModalFilter";

function ClientList() {
    const dispatch = useDispatch();
    const state = useSelector(state => state.client);

    const userState = useSelector(state => state.user);
    const isAdmin = userState.profile && (userState.profile.isSuperAdmin || userState.profile.canManageInstitution);

    const [search, setSearch] = React.useState("");

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const [filters, setFilters] = React.useState([]);
    const [filterModalData, setFilterModalData] = React.useState({
        loadData: true,
        search: {},
    });

    React.useEffect(() => {
        dispatch(Client.getClients({ page: state.page, search, filters: filters.length && JSON.stringify(filters) }));
    }, [dispatch, state.page, search, filters]);

    const [dialogModal, setDialogModal] = React.useState(false);
    const [dialogModalMessage, setDialogModalMessage] = React.useState("");

    const [displayConfirmationModal, setDisplayConfirmationModal] = React.useState(false);
    const [confirmationModalData, setConfirmationModalData] = React.useState({ message: "", id: "" });

    const confirmDeleteClient = (obj) => {
        if (true) return alert("Deletion unavailable at the moment");

        setConfirmationModalData({ message: "You are about to delete " + obj.firstname + ". Are you sure?", obj: obj })
        setDisplayConfirmationModal(true);
    };

    const hideConfirmationModal = () => setDisplayConfirmationModal(false);
    const closeDialogModal = () => setDialogModal(false);

    const deleteClient = (client) => {
        dispatch(Client.deleteClient({ client }))
            .then((result) => {
                hideConfirmationModal();
                setDialogModal(true);
                setDialogModalMessage(result.payload.status === "success" ? "Client deleted!" : result.payload.message);
            });
    };

    const [isImportModalOpen, setIsImportModalOpen] = React.useState(false);
    const openImportModal = () => {
        setIsImportModalOpen(true);
    };

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col md="6">
                                    <CardTitle tag="h4">Clients</CardTitle>
                                </Col>
                                <Col md="6">
                                    <Row style={{ justifyContent: "flex-end" }}>
                                        <Col md="7" className="d-flex justify-content-end" style={{ marginTop: "15px" }}>
                                            {
                                                isAdmin &&
                                                <>

                                                    <div className="d-inline-block">
                                                        <Link to="/admin/clients/addEdit?role=client"><Button color="primary" style={{ marginRight: "15px" }}>Add</Button></Link>
                                                        <Button color="primary" onClick={openImportModal}>Import</Button>
                                                    </div>
                                                    <div className="ml-3 d-inline-block">
                                                        {!filters.length ?
                                                            <Button className={'btn-round'} color="primary" onClick={() => setShowFilterModal(true)}>Apply Filters</Button>
                                                            :
                                                            <div style={{ position: 'relative' }}>
                                                                <Button className={'btn-round'} color="warning" onClick={() => setShowFilterModal(true)}>Edit Filters</Button>
                                                                <button className="close" style={{ position: 'absolute', top: '35%', right: '-18px' }} onClick={() => setFilters([])}>×</button>
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            }

                                        </Col>
                                        <Col md="4" className="pt-3 mr-3">
                                            <Search value={search} onChange={setSearch} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead className="text-primary">
                                    <tr>
                                        <th>Firstname</th>
                                        <th>Lastname</th>
                                        <th>Email</th>
                                        <th>Institution</th>
                                        <th>Circle</th>
                                        {isAdmin && <th className="text-right">Actions</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !state.loading && state.clients.map(i =>
                                            <tr key={i.uuid}>
                                                <td>{i.firstname}</td>
                                                <td>{i.lastname}</td>
                                                <td>{i.email}</td>
                                                <td>{i.institution?.shortname}</td>
                                                <td>{i.circle?.name}</td>
                                                {
                                                    isAdmin &&
                                                    <td className="text-right">
                                                        <ButtonGroup size="sm">
                                                            <Link to={"/admin/clients/addEdit?role=client&uuid=" + i.uuid}>
                                                                <Button color="info mr-2" className="btn-link">
                                                                    <i className="fa fa-pen"></i>
                                                                </Button>
                                                            </Link>
                                                            <Button color="danger" className="btn-link" onClick={() => confirmDeleteClient(i)}><i className="fa fa-trash"></i></Button>
                                                        </ButtonGroup>
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    }
                                    {state.loading && <TableLoading colSpan={6} />}
                                    {!state.loading && !state.clients.length &&
                                        <tr>
                                            <td className="text-center py-4" colSpan={6}>
                                                <h5>{search ? "No result." : "There's nothing but empty space."}</h5>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            <MainPagination page={state.page} prevPage={() => dispatch(prevPage())} nextPage={() => dispatch(nextPage())} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <DialogModal message={dialogModalMessage} closeModal={closeDialogModal} isModal={dialogModal} />

            {
                isAdmin &&
                <>
                    <ClientImportModal isModalOpen={isImportModalOpen} closeModal={() => setIsImportModalOpen(false)} />
                    <ModalFilter showModal={showFilterModal} hideModal={() => setShowFilterModal(false)} filterModalData={filterModalData} setFilterModalData={setFilterModalData} selectedFilter={filters} setFilter={setFilters} types={['institution', 'circle']} />
                </>
            }
            <ConfirmationModal type="delete" showModal={displayConfirmationModal} confirmModal={deleteClient} hideModal={hideConfirmationModal} data={confirmationModalData} />
        </div>
    );
}

export default ClientList;
