/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import config from "./config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const signUp = createAsyncThunk(
    "user/signUp",
    async ({ firstname, lastname, email, password }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/auth/signup",
            {
                firstname,
                lastname,
                email,
                password,
                device_name: config.deviceName,
                timezone: config.timezone,
            }
        ),
        thunkAPI
    )
);

const signIn = createAsyncThunk(
    "user/signIn",
    async ({ email, password }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/auth/signin",
            {
                email,
                password,
                device_name: config.deviceName,
                timezone: config.timezone,
            }
        ),
        thunkAPI
    )
);

const signOut = createAsyncThunk(
    "user/signOut",
    async (_, thunkAPI) => apiThunkHandler(axios.post("/auth/signout"), thunkAPI)
);

const resetStages = {
    REQUEST: "REQUEST",
    VERIFY: "VERIFY",
    RESET: "RESET",
    DONE: "DONE",
};

const requestResetPassword = createAsyncThunk(
    "user/requestResetPassword",
    async ({ email }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/auth/request-reset-password",
            {
                email,
            }
        ),
        thunkAPI
    )
);

const verifyCode = createAsyncThunk(
    "user/verifyCode",
    async ({ email, uuid, code }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/auth/verify-code",
            {
                email,
                uuid,
                code,
            }
        ),
        thunkAPI
    )
);

const resetPassword = createAsyncThunk(
    "user/resetPassword",
    async ({ email, uuid, token, password }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/auth/reset-password",
            {
                email,
                uuid,
                token,
                password,
            }
        ),
        thunkAPI
    )
);

const updateProfile = createAsyncThunk(
    "user/updateProfile",
    async ({ profile }, thunkAPI) => apiThunkHandler(
        axios.put(
            "/me",
            {
                ...profile
            }
        ),
        thunkAPI
    )
);

const getMyProfile = createAsyncThunk(
    "user/getMyProfile",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/me"), thunkAPI)
);

const user = {
    signUp,
    signIn,
    signOut,

    resetStages,
    requestResetPassword,
    verifyCode,
    resetPassword,

    updateProfile,
    getMyProfile,
};

export default user;
