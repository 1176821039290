/**
 * LifeRamp
 *
 * @author      Waris Anwar
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import randomString from "@afaanbilal/random-string";

import DialogModal from "components/Modal/DialogModal";

import { Circle, Institution } from "../../api";
import { uploadToFirebase } from "../../api/utils";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Label,
    CardImg,
    Spinner,
    CardText,
    CardImgOverlay,
} from "reactstrap";
import AdminImageModal from "components/Modal/ImagePickerAdmin";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const blankCircle = { name: "", institution: "", description: "", image: "" };

const CircleAddEdit = (props) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.circle);
    const institutions = useSelector(state => state.institution.institutions);

    React.useEffect(() => {
        dispatch(Institution.getInstitutions({ page: 1 }));
    }, [dispatch]);

    let uuid = useQuery().get("uuid");
    const isEdit = !!uuid || false;
    const circleData = state.circles ? state.circles.find(c => c.uuid === uuid) : null;
    const [circle, setCircle] = React.useState(isEdit ? circleData : blankCircle);
    const [dialogModal, setDialogModal] = React.useState(false);
    const [dialogModalMessage, setDialogModalMessage] = React.useState("");
    const [imagePicker, setImagePicker] = React.useState(false);

    const [uploadingToFirebase, setUploadingToFirebase] = React.useState(false);
    const [uploadedImageBlob, setUploadedImageBlob] = React.useState(null);
    const [shouldSave, setShouldSave] = React.useState(false);

    React.useEffect(() => {
        if (shouldSave) {
            if (isEdit) {
                dispatch(Circle.updateCircle({ circle }))
                    .then((result) => {
                        setDialogModal(true);
                        setDialogModalMessage(result.payload.status === "success" ? "Circle saved!" : result.payload.message);
                        setUploadedImageBlob(null);
                    });
            } else {
                dispatch(Circle.createCircle({ circle }))
                    .then((result) => {
                        setDialogModal(true);
                        setDialogModalMessage(result.payload.status === "success" ? "Circle added!" : result.payload.message);
                        setUploadedImageBlob(null);
                    });
            }

            setShouldSave(false);
        }
    }, [shouldSave, isEdit, dispatch, circle]);

    const onSave = async () => {
        if (state.loading || uploadingToFirebase) return;

        if (uploadedImageBlob) {
            setUploadingToFirebase(true);
            const url = await uploadToFirebase("institution-images/" + randomString() + "/image.png", uploadedImageBlob);
            setUploadingToFirebase(false);

            if (!url) {
                setDialogModalMessage("Please choose an image less than 5MB");
                return;
            }

            setCircle({ ...circle, image: url });
            setShouldSave(true);
        } else {
            setShouldSave(true);
        }
    };

    const onImageCropped = ({ url, blob }) => {
        setUploadedImageBlob(blob);
        setCircle({ ...circle, image: url });
    };

    return (
        <div className="content">
            <AdminImageModal closeModal={() => setImagePicker(false)} isModal={imagePicker} aspectRatio={1070 / 628} onImageCropped={onImageCropped} />
            <Row>
                <Col md="4">
                    <Card style={{ margin: "0px", borderTopRightRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "2px", borderBottomRightRadius: "2px" }}>
                        <CardImg style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "2px", borderBottomRightRadius: "2px" }} src={`${circle.image ? circle.image : "https://www.careersinafrica.com/wp-content/uploads/2020/05/placeholder.png"}`} alt="Card image cap" />
                        <CardImgOverlay>
                            <Button
                                style={{
                                    position: "absolute",
                                    bottom: "0px",
                                    right: "10px",
                                    color: "grey",
                                    backgroundColor: "white",
                                    fontSize: "24px",
                                    padding: "5px",
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",

                                }}
                                onClick={() => setImagePicker(true)}
                            ><i className="fa fa-camera" aria-hidden="true"></i></Button>
                        </CardImgOverlay>
                    </Card>
                    <Card style={{ borderTopRightRadius: "2px", borderTopLeftRadius: "2px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                        <CardBody className="d-flex flex-column align-items-center">
                            <CardTitle tag="h5" className="mt-3">{circle.name || "Name"}</CardTitle>
                            <CardTitle tag="h4" className="text-center my-4">{circle.institution.name || "Institution Name"}</CardTitle>
                            <CardText tag="h5" className="mb-2 text-muted">{circle.institution.shortname || ""}</CardText>
                        </CardBody>
                        <hr />
                        <CardFooter className="d-flex flex-column align-items-center">
                            <CardTitle tag="h5">{circle.peopleCount} Members</CardTitle>
                            <CardTitle tag="h5">{circle.postCount} Conversations</CardTitle>
                        </CardFooter>
                    </Card>
                </Col>
                <Col md="8">
                    <Card className="card-user">
                        <CardHeader>
                            <Row>
                                <Col><CardTitle tag="h5">{isEdit ? "Edit Circle" : "Add Circle"}</CardTitle></Col>
                                <Col><CardTitle tag="h5" className="text-right">
                                    <Link to="/admin/circles">
                                        <i className="nc-icon nc-simple-remove" /></Link>
                                </CardTitle>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Institution</Label>
                                            <Input type="select" name="select"
                                                value={ circle.institution ? circle.institution.uuid : "" }
                                                onChange={(value) => setCircle(state => ({ ...state, institution: value.target.value }))}>
                                                <option disabled value="">Select Institution</option>
                                                {
                                                    institutions.map(i => <option value={i.uuid} key={i.uuid}>{i.name}</option>)
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Name of the Circle</Label>
                                            <Input
                                                placeholder="Name of the Circle"
                                                type="text"
                                                value={circle.name || ''}
                                                onChange={(value) => setCircle(state => ({ ...state, name: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <Input
                                                placeholder="Description"
                                                type="textarea"
                                                value={circle.description || ''}
                                                onChange={(value) => setCircle(state => ({ ...state, description: value.target.value }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="update ml-auto mr-auto">
                                        {
                                            state.loading || uploadingToFirebase ? <Spinner color="primary" /> : <Button
                                                className="btn btn-lg btn-block"
                                                color="primary"
                                                type="button"
                                                onClick={onSave}
                                            >
                                                Save
                                            </Button>
                                        }
                                        <DialogModal isModal={dialogModal} closeModal={() => setDialogModal(false)} message={dialogModalMessage} />
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default CircleAddEdit;
