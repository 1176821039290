/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { Resource } from "../../api";
import { setIsLoading, setError } from "../utils";

export const resourceSlice = createSlice({
    name: "resource",
    initialState: {
        loading: false,
        error: "",

        uuid: null,
        data: {},

        categories: [],
        resources: [],
        page: 1,
        maxPage: 1,
    },
    reducers: {
        setResource(state, { payload: { resource } }) {
            state.uuid = resource.uuid;
            state.data = resource;
        },
        prevPage(state) {
            if (state.page > 1) {
                state.page--;
            }
        },
        nextPage(state) {
            if (state.page < state.maxPage) {
                state.page++;
            }
        },
    },
    extraReducers: {
        [Resource.getCategories.pending]: setIsLoading(true),
        [Resource.getResources.pending]: setIsLoading(true),
        [Resource.getResourcesAll.pending]: setIsLoading(true),
        [Resource.createResource.pending]: setIsLoading(true),
        [Resource.updateResource.pending]: setIsLoading(true),
        [Resource.deleteResource.pending]: setIsLoading(true),

        [Resource.getCategories.fulfilled]: (state, action) => {
            state.categories = action.payload.categories;
            setIsLoading(false)(state);
        },
        [Resource.getResources.fulfilled]: (state, action) => {
            state.maxPage = action.payload.maxPage
            state.resources = action.payload.resources;
            setIsLoading(false)(state);
        },
        [Resource.getResourcesAll.fulfilled]: (state, action) => {
            state.maxPage = action.payload.maxPage
            state.resources = action.payload.resources;
            setIsLoading(false)(state);
        },
        [Resource.createResource.fulfilled]: (state, action) => {
            const resource = action.payload.resource;
            state.resources.unshift(resource)
            setIsLoading(false)(state);
        },
        [Resource.updateResource.fulfilled]: (state, action) => {
            const resource = action.payload.resource;
            state.resources.splice(state.resources.indexOf(state.resources.find(i => i.uuid === resource.uuid)), 1, resource);
            setIsLoading(false)(state);
        },
        [Resource.deleteResource.fulfilled]: (state, action) => {
            const resource = action.meta.arg.resource;
            state.resources.splice(state.resources.indexOf(state.resources.find(i => i.uuid === resource.uuid)), 1);
            setIsLoading(false)(state);
        },

        [Resource.getCategories.rejected]: setError,
        [Resource.getResources.rejected]: setError,
        [Resource.getResourcesAll.rejected]: setError,
        [Resource.createResource.rejected]: setError,
        [Resource.updateResource.rejected]: setError,
        [Resource.deleteResource.rejected]: setError,
    }
});

export const { setResource, prevPage, nextPage } = resourceSlice.actions;

export default resourceSlice.reducer;
