/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { Post } from "../../api";
import { setIsLoading, setError } from "../utils";

export const postSlice = createSlice({
    name: "posts",
    initialState: {
        loading: false,
        error: "",

        uuid: null,
        data: {},

        posts: [],
        page: 1,
        maxPage: 1,

        circles: [],
    },
    reducers: {
        setInstitution(state, { payload: { institution } }) {
            state.uuid = institution.uuid;
            state.data = institution;
        },
        prevPage(state) {
            if (state.page > 1) {
                state.page--;
            }
        },
        nextPage(state) {
            if (state.page < state.maxPage) {
                state.page++;
            }
        },
    },
    extraReducers: {
        [Post.getPost.pending]: setIsLoading(true),
        [Post.getPosts.pending]: setIsLoading(true),
        [Post.createPost.pending]: setIsLoading(true),
        [Post.updatePost.pending]: setIsLoading(true),
        [Post.deletePost.pending]: setIsLoading(true),

        [Post.getPost.fulfilled]: (state, action) => {
            const post = action.payload.post;
            if (!state.posts.find(p => p.uuid === post.uuid)) {
                state.posts.push(post)
            }
            setIsLoading(false)(state);
        },
        [Post.getPosts.fulfilled]: (state, action) => {
            state.maxPage = action.payload.maxPage
            state.posts = action.payload.posts;
            setIsLoading(false)(state);
        },
        [Post.createPost.fulfilled]: (state, action) => {
            state.posts.unshift(action.payload.post);
            setIsLoading(false)(state);
        },
        [Post.updatePost.fulfilled]: (state, action) => {
            state.posts.splice(
                state.posts.indexOf(
                    state.posts.find(p => p.uuid === action.payload.post.uuid)
                ), 1, action.payload.post
            );
            setIsLoading(false)(state);
        },
        [Post.deletePost.fulfilled]: (state, action) => {
            const post = action.meta.arg.post;
            state.posts.splice(state.posts.indexOf(state.posts.find(p => p.uuid === post.uuid)), 1);
            setIsLoading(false)(state);
        },

        [Post.getPost.rejected]: setError,
        [Post.getPosts.rejected]: setError,
        [Post.createPost.rejected]: setError,
        [Post.updatePost.rejected]: setError,
        [Post.deletePost.rejected]: setError,

    }
});

export const { setInstitution, prevPage, nextPage } = postSlice.actions;

export default postSlice.reducer;
