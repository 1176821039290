/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { Event } from "../../api";
import { setIsLoading, setError } from "../utils";

export const eventSlice = createSlice({
    name: "event",
    initialState: {
        loading: false,
        error: "",

        uuid: null,
        data: {},

        events: [],
        page: 1,
        maxPage: 1,
    },
    reducers: {
        prevPage(state) {
            if (state.page > 1) {
                state.page--;
            }
        },
        nextPage(state) {
            if (state.page < state.maxPage) {
                state.page++;
            }
        },
    },
    extraReducers: {
        [Event.getEvents.pending]: setIsLoading(true),

        [Event.getEvents.fulfilled]: (state, action) => {
            state.maxPage = action.payload.maxPage
            state.events = action.payload.events;
            setIsLoading(false)(state);
        },

        [Event.getEvents.rejected]: setError,
    }
});

export const { prevPage, nextPage } = eventSlice.actions;

export default eventSlice.reducer;
