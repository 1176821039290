/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReportModal from "./ReportModal";
import datetime from "date-and-time";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button,
} from "reactstrap";

import TableLoading from "../../components/utils/TableLoading";
import MainPagination from "../../components/utils/MainPagination";

import { Report } from "../../api";
import { prevPage, nextPage } from "../../state/slices/reportSlice";

// const REPORTS = [{
//     subject: "report",
//     email: "a@b",
//     date: "24/06/2021",
//     time: new Date().toLocaleTimeString(),
//     message: "message",
//     attachment: "",
// }];

function ReportList() {
    const dispatch = useDispatch();
    const state = useSelector(state => state.report);

    const [showModal, setShowModal] = React.useState(false);
    const [showReport, setShowReport] = React.useState(null);

    React.useEffect(() => {
        dispatch(Report.getReports({ page: state.page }));
    }, [dispatch, state.page]);

    const resolveNoAct = (report) => {
        dispatch(Report.resolveReport({ report, action: "no-action" })).then(() => { setShowModal(false); });
    };

    const resolveDelete = (report) => {
        dispatch(Report.resolveReport({ report, action: "delete" })).then(() => { setShowModal(false); });
    };

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col md="8">
                                    <CardTitle tag="h4">Reports</CardTitle>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead className="text-primary">
                                    <tr>
                                        <th>Subject</th>
                                        <th>Reported by</th>
                                        <th>Reported on</th>
                                        <th className="text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state.reports.map((report, index) =>
                                            <tr key={index}>
                                                <td>{report.subject}</td>
                                                <td>{report.user.name}</td>
                                                <td>{datetime.format(new Date(report.created_at), "MMM D YYYY h:mm A")}</td>
                                                <td className="text-right">
                                                    <Button color="info mr-2" className="btn-link" onClick={() => { setShowReport(report); setShowModal(true); }}>
                                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {state.loading && <TableLoading colSpan={6} />}
                                    {!state.loading && !state.reports?.length &&
                                        <tr>
                                            <td className="text-center py-4" colSpan={6}>
                                                <h5>{"There's nothing but empty space."}</h5>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>

                            <MainPagination page={state.page} prevPage={() => dispatch(prevPage())} nextPage={() => dispatch(nextPage())} />
                            {showReport !== null &&
                                <ReportModal
                                    {...showReport}
                                    showModal={showModal}
                                    toggleModal={() => setShowModal(s => !s)}
                                    resolveNoAct={() => resolveNoAct(showReport)}
                                    resolveDelete={() => resolveDelete(showReport)}
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ReportList;
