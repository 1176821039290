/**
 * LifeRamp
 *
 * @author      Chetanya gupta
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Route } from "react-router-dom";

import ResourcesList from "./ResourceList";
import ResourcesAddEdit from "./ResourceAddEdit";

function Resources() {
    return (
        <>
            <Route path="/admin/resources" exact component={ResourcesList} />
            <Route path="/admin/resources/addEdit" exact component={ResourcesAddEdit} />
        </>
    );
}

export default Resources;
