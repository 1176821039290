/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const getDashboardData = createAsyncThunk(
    "dashboard/getDashboardData",
    async ({filters = "" }, thunkAPI) => apiThunkHandler(axios.get("/admin-dashboard?" + (filters ? "&filters=" + filters : "")), thunkAPI)
);

const getSettings = createAsyncThunk(
    "dashboard/getSettings",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/admin-dashboard/settings"), thunkAPI)
);

const saveSettings = createAsyncThunk(
    "dashboard/saveSettings",
    async ({ settings }, thunkAPI) => apiThunkHandler(
        axios.put("/admin-dashboard/settings",
            {
                ...settings
            }
        ),
        thunkAPI
    )
);

const reRunCalendly = createAsyncThunk(
    "dashboard/reRunCalendly",
    async (_, thunkAPI) => apiThunkHandler(axios.post("/admin-dashboard/rerun-calendly-setup"), thunkAPI)
);

const dashboard = {
    getDashboardData,

    getSettings,
    saveSettings,

    reRunCalendly,

};

export default dashboard;
