/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const getClients = createAsyncThunk(
    "clients/getClients",
    async ({ page, search = "", filters = "" }, thunkAPI) => apiThunkHandler(axios.get("/clients?page=" + page + (search ? "&search=" + search : "") + (filters ? "&filters=" + filters : "")), thunkAPI)
);

const createClient = createAsyncThunk(
    "clients/createClient",
    async ({ client }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/clients",
            {
                ...client
            }
        ),
        thunkAPI
    )
);

const updateClient = createAsyncThunk(
    "clients/updateClient",
    async ({ client }, thunkAPI) => apiThunkHandler(
        axios.put(
            "/clients/" + client.uuid,
            {
                ...client
            }
        ),
        thunkAPI
    )
);

const removeCoach = createAsyncThunk(
    "clients/remove-coach",
    async ({ client }, thunkAPI) => apiThunkHandler(axios.delete("/clients/remove-coach/" + client.uuid), thunkAPI)
);

const deleteClient = createAsyncThunk(
    "clients/deleteClient",
    async ({ client }, thunkAPI) => apiThunkHandler(axios.delete("/clients/" + client.uuid), thunkAPI)
);

const importClients = createAsyncThunk(
    "clients/importClients",
    async ({ institution_uuid, circle_uuid, csv_file }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/clients/import",
            ((institution_uuid, circle_uuid, csv_file) => {
                const formData = new FormData();

                formData.append("institution_uuid", institution_uuid);
                formData.append("circle_uuid", circle_uuid);
                formData.append("csv_file", csv_file, csv_file.name);

                return formData;
            })(institution_uuid, circle_uuid, csv_file)
        ),
        thunkAPI
    )
);

const needingCoach = createAsyncThunk(
    "clients/needingCoach",
    async ({ page, search = "", filters = "" }, thunkAPI) => apiThunkHandler(axios.get("/clients/needing-coach?page=" + page + (search ? "&search=" + search : "") + (filters ? "&filters=" + filters : "")), thunkAPI)
);

const client = {
    getClients,
    createClient,
    updateClient,
    removeCoach,
    deleteClient,
    importClients,
    needingCoach,
};

export default client;
