/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";

import {
    Pagination,
    PaginationItem,
    PaginationLink,
} from "reactstrap";

function MainPagination({ page, prevPage, nextPage }) {
    return (
        <Pagination className="d-flex flex-row-reverse my-2 mr-2">
            <PaginationItem>
                <PaginationLink previous href="#" onClick={prevPage} />
            </PaginationItem>
            <PaginationItem>
                <PaginationLink href="#">
                    {page}
                </PaginationLink>
            </PaginationItem>
            <PaginationItem>
                <PaginationLink next href="#" onClick={nextPage} />
            </PaginationItem>
        </Pagination>
    );
}

export default MainPagination;
