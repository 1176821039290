/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { useDispatch } from "react-redux";
// eslint-disable-next-line
import { Link, useLocation } from "react-router-dom";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    // NavItem,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container,
    // InputGroup,
    // InputGroupText,
    // InputGroupAddon,
    // Input,
} from "reactstrap";

import { routes } from "routes.js";

import { setAuthToken, User } from "../../api";
import { persistor } from "state/store";

function Header(props) {
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = React.useState(false);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const [color, setColor] = React.useState("transparent");
    const sidebarToggle = React.useRef();
    const location = useLocation();
    const toggle = () => {
        if (isOpen) {
            setColor("transparent");
        } else {
            setColor("dark");
        }
        setIsOpen(!isOpen);
    };
    const dropdownToggle = (e) => {
        setDropdownOpen(!dropdownOpen);
    };
    const getBrand = () => {
        let brandName = "Default Brand";
        routes.map((prop, key) => {
            if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
                brandName = prop.name;
            }
            return null;
        });
        return brandName;
    };
    const openSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        sidebarToggle.current.classList.toggle("toggled");
    };
    // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
    const updateColor = () => {
        if (window.innerWidth < 993 && isOpen) {
            setColor("dark");
        } else {
            setColor("transparent");
        }
    };
    React.useEffect(() => {
        window.addEventListener("resize", updateColor.bind(this));
    });
    React.useEffect(() => {
        if (
            window.innerWidth < 993 &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            sidebarToggle.current.classList.toggle("toggled");
        }
    }, [location]);

    const signOut = () => {
        dispatch(User.signOut()).then(() => {
            persistor.purge();
            window.localStorage.clear();
            setAuthToken(null);
            window.location.reload();
        });
    };

    return (
        // add or remove classes depending if we are on full-screen-maps page or not
        <Navbar
            color={
                props.location.pathname.indexOf("full-screen-maps") !== -1
                    ? "dark"
                    : color
            }
            expand="lg"
            className={
                props.location.pathname.indexOf("full-screen-maps") !== -1
                    ? "navbar-absolute fixed-top"
                    : "navbar-absolute fixed-top " +
                    (color === "transparent" ? "navbar-transparent " : "")
            }
        >
            <Container fluid>
                <div className="navbar-wrapper">
                    <div className="navbar-toggle">
                        <button
                            type="button"
                            ref={sidebarToggle}
                            className="navbar-toggler"
                            onClick={() => openSidebar()}
                        >
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                    </div>
                    <NavbarBrand href="/">{getBrand()}</NavbarBrand>
                </div>
                <NavbarToggler onClick={toggle}>
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                </NavbarToggler>
                <Collapse isOpen={isOpen} navbar className="justify-content-end">
                    {/* <form>
                        <InputGroup className="no-border">
                            <Input placeholder="Search..." />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                    <i className="nc-icon nc-zoom-split" />
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </form> */}
                    <Nav navbar>
                        {/* <NavItem>
                            <Link to="#pablo" className="nav-link btn-magnify">
                                <i className="nc-icon nc-layout-11" />
                                <p>
                                    <span className="d-lg-none d-md-block">Stats</span>
                                </p>
                            </Link>
                        </NavItem> */}
                        <Dropdown
                            nav
                            isOpen={dropdownOpen}
                            toggle={(e) => dropdownToggle(e)}
                        >
                            <DropdownToggle caret nav>
                                <i className="nc-icon nc-settings-gear-65" />
                                <p>
                                    <span className="d-lg-none d-md-block">Account</span>
                                </p>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem href="#" tag="a" onClick={signOut}>Logout</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
