/**
 * LifeRamp
 *
 * @author      Waris Anwar
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Route } from "react-router-dom";

import CircleList from "./CircleList";
import CircleAddEdit from "./CircleAddEdit";

function Circles() {
    return (
        <>
            <Route path="/admin/circles" exact component={CircleList} />
            <Route path="/admin/circles/addEdit" exact component={CircleAddEdit} />
        </>
    );
}

export default Circles;
 