/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap";

const DeleteConfirmation = ({ showModal, hideModal, confirmModal, data, type = "success", showLoader = true }) => {

    const [onClick, setOnClick] = React.useState(false);

    const modalInfo = {
        delete: {
            titleBody: 'alert-danger',
            btnStyle: 'btn-danger',
            btnText: 'Delete'
        },
        success: {
            titleBody: 'alert-success',
            btnStyle: 'btn-success',
            btnText: 'Confirm'
        },
    };

    React.useEffect(() => {
        setOnClick(false);
    }, [showModal]);

    return (
        <Modal isOpen={showModal} toggle={hideModal}>
            <ModalHeader toggle={hideModal}>Delete Confirmation
            </ModalHeader>
            <ModalBody><div className={`p-3 ${modalInfo[type].titleBody}`}>{data.message}</div></ModalBody>
            <ModalFooter>
                <Button variant="default" onClick={hideModal}>
                    Cancel
                </Button>
                <Button className={`btn ${modalInfo[type].btnStyle}`} onClick={() => { setOnClick(true); confirmModal(data.obj) }}>
                    {showLoader && onClick ?
                        <Spinner style={{ width: 15, height: 15 }} animation="border" variant="light" />
                        :
                        modalInfo[type].btnText
                    }
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default DeleteConfirmation;