/**
 * LifeRamp
 *
 * @author      Chetanya Gupta
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { useSelector } from "react-redux";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    CardImg,
    Spinner,
} from "reactstrap";

const ReportModal = ({
    subject,
    message,
    image,
    post,
    comment,
    resolveNoAct,
    resolveDelete,
    showModal,
    toggleModal,
}) => {
    const state = useSelector(state => state.report);

    return (
        <div>
            <Modal isOpen={showModal} toggle={toggleModal} size="lg" >
                <ModalHeader toggle={toggleModal}>View Report</ModalHeader>
                <ModalBody>
                    <div className="content">
                        <Row>
                            <Col md="7">
                                <Row className="p-3 m-2 border-bottom justify-content-center">
                                    {state.loading ? <Spinner color="primary" />
                                        :
                                        <>
                                            <Button style={{ backgroundColor: "grey", marginRight: "20px" }} onClick={resolveNoAct}>Mark as resolved</Button>
                                            <Button style={{ backgroundColor: "red" }} onClick={resolveDelete}>Delete</Button>
                                        </>
                                    }
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Subject</Label>
                                            <Input
                                                placeholder="Subtitle"
                                                type="text"
                                                disabled
                                                value={subject}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Message</Label>
                                            <Input type="textarea" name="text" value={message} disabled bsSize="lg" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="5" style={{ flexDirection: "column" }}>
                                <FormGroup>
                                    <Label>Attachment</Label>
                                    {image ?
                                        <CardImg top style={{ width: "100%", border: "1px solid black" }} src={image} alt="Card image cap" />
                                        :
                                        <p><small>No attachment</small></p>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        {post !== null &&
                            <div className="border-top p-4">
                                <h4>REPORTED POST</h4>
                                {post.image !== null &&
                                    <CardImg top className="mb-4" style={{ width: "100%" }} src={post.image} alt="Card image cap" />
                                }
                                <h2 className="text-justify">{post.title}</h2>
                                <p className="text-justify">{post.content}</p>
                            </div>
                        }
                        {comment !== null &&
                            <div className="border-top p-4">
                                <h4>REPORTED COMMENT</h4>
                                <p className="text-justify">{comment.content}</p>
                            </div>
                        }
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ReportModal;
