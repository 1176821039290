/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from 'react'
import { useDispatch, useSelector } from "react-redux";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, Spinner, Col } from "reactstrap";
import Search from "../../components/utils/Search";

import { Institution, Circle, Client, Coach, Resource } from "../../api";

const FilterModal = ({ showModal, hideModal, filterModalData, setFilterModalData, setFilter, selectedFilter = [], types = [] }) => {

    const dispatch = useDispatch();

    const [availableFilters, setAvailableFilters] = React.useState([]);
    const [chosenFilters, setChosenFilters] = React.useState([]);

    const institution = useSelector(state => state.institution);
    const circle = useSelector(state => state.circle);
    const client = useSelector(state => state.client);
    const coach = useSelector(state => state.coach);
    const resource = useSelector(state => state.resource);

    const [search, setSearch] = React.useState({
        institution: filterModalData.search.institution || "",
        circle: filterModalData.search.circle || "",
        client: filterModalData.search.client || "",
        coach: filterModalData.search.coach || "",
    });

    const [loading, setLoading] = React.useState({
        institution: false,
        circle: false,
        client: false,
        coach: false,
    });

    React.useEffect(() => {
        if (filterModalData.loadData && showModal) {
            types.includes('institution') && dispatch(Institution.getInstitutions({ page: 1 }));
            types.includes('circle') && dispatch(Circle.getCircles({ page: 1 }));
            types.includes('client') && dispatch(Client.getClients({ page: 1 }));
            types.includes('coach') && dispatch(Coach.getCoaches({ page: 1 }));
            types.includes('resource-category') && dispatch(Resource.getCategories({ page: 1 }));

            setFilterModalData({ ...filterModalData, loadData: false });
        }

        // eslint-disable-next-line
    }, [types]);

    React.useEffect(() => {
        setLoading({
            institution: institution.loading,
            circle: circle.loading,
            client: client.loading,
            coach: coach.loading,
        });
    }, [institution.loading, circle.loading, client.loading, coach.loading]);

    React.useEffect(() => {
        var filters = [];

        types.includes('institution') && filters.push(
            {
                type: 'institution',
                values: [
                    ...institution.institutions.map(i => ({
                        name: i.name,
                        uuid: i.uuid
                    })),
                ]
            }
        );

        types.includes('circle') && filters.push(
            {
                type: 'circle',
                values: [
                    ...circle.circles.map(i => ({
                        name: i.name,
                        uuid: i.uuid
                    })),
                ]
            }
        );

        types.includes('client') && filters.push(
            {
                type: 'client',
                values: [
                    ...client.clients.map(i => ({
                        name: i.name,
                        uuid: i.uuid
                    })),
                ]
            }
        );

        types.includes('coach') && filters.push(
            {
                type: 'coach',
                values: [
                    ...coach.coaches.map(i => ({
                        name: i.name,
                        uuid: i.uuid
                    })),
                ]
            }
        );

        types.includes('resource-category') && filters.push(
            {
                type: 'resource-category',
                values: [
                    ...resource.categories.map(i => ({
                        name: i.name,
                        uuid: i.uuid
                    })),
                ]
            }
        );

        types.includes('date-range') && filters.push(
            {
                type: 'date-range',
                // values: [],
            }
        );

        setAvailableFilters(filters);
        // eslint-disable-next-line
    }, [types, institution.institutions, circle.circles, client.clients, coach.coaches])


    const isTypeEnabled = (t) => {
        return chosenFilters.find(f => f.type === t);
    };
    const toggleFilterType = (t) => {
        let filter = [...chosenFilters];

        if (isTypeEnabled(t)) {
            filter.splice(filter.indexOf(isTypeEnabled(t)), 1);
        } else {
            if (t === "date-range") {
                filter.push({ type: t, start: '', end: '' });
            } else {
                filter.push({ type: t, uuids: [] });
            }
        }

        setChosenFilters(filter);
    };

    const isValueEnabled = (t, o) => {
        if (isTypeEnabled(t)) {
            return isTypeEnabled(t).uuids.find(i => i === o.uuid);
        }
        return false
    };
    const toggleFilterValue = (t, o) => {
        let filter = [...chosenFilters];

        if (isValueEnabled(t, o)) {
            filter[filter.indexOf(isTypeEnabled(t))].uuids.splice(o.uuid, 1);
        } else {
            filter[filter.indexOf(isTypeEnabled(t))].uuids.push(o.uuid);
        }

        setChosenFilters(filter);
    };

    const toggleFilterDate = (t, v) => {
        let filter = [...chosenFilters];

        if (t === "start") {
            filter[filter.indexOf(isTypeEnabled("date-range"))].start = v;
        }

        if (t === "end") {
            filter[filter.indexOf(isTypeEnabled("date-range"))].end = v;
        }

        setChosenFilters(filter);

    };

    const showSave = () => {
        let length = 0
        chosenFilters.forEach(i => {
            if (i.type === "date-range") {
                length += i.start ? 1 : 0;
                length += i.end ? 1 : 0;
            } else {
                length += i.uuids?.length;
            }
        })

        return Boolean(length);
    };

    const updateSearch = (val, type) => {
        let s = { ...search };
        s[type] = val;

        setSearch(s);
        val === "" && updateData(type, true);
    };

    const handleSearch = (event, type) => {
        if (event.key === 'Enter') {
            updateData(type);
        }
    };

    const updateData = (type, clearSearch = false) => {
        type === "client" && dispatch(Client.getClients({ page: client.page, search: clearSearch ? "" : search.client }));;
        type === "coach" && dispatch(Coach.getCoaches({ page: coach.page, search: clearSearch ? "" : search.coach }));;

        setFilterModalData({ ...filterModalData, search: search });
    };

    const applyFilter = () => {
        let filters = [...chosenFilters]

        filters.forEach((f, index) => {
            if (f.type === "date-range") {
                !f.start && !f.end && filters.splice(index, 1);
                return;
            }

            if (f.uuids.length === 0) {
                filters.splice(index, 1);
            }
        });

        setFilter(filters)
    };

    React.useEffect(() => {
        setChosenFilters(selectedFilter);
    }, [selectedFilter]);

    return (
        <Modal isOpen={showModal} toggle={hideModal} size="lg" style={{ maxWidth: '1000px', width: '100%' }}>
            <ModalHeader toggle={hideModal}>
                Apply Filters
            </ModalHeader>
            <ModalBody style={{ padding: "2em 1em 3em" }}>
                <div className={`row mx-3 mb-3 font-weight-bold`} style={{ fontSize: "18px" }}>Filter By</div>
                <div className={`row mx-4`}>
                    {
                        availableFilters.map(i =>
                            <Col key={i.type}>
                                <Label className="text-muted col"
                                    style={{ textTransform: "capitalize", fontSize: '16px' }}
                                    key={i.type}>
                                    <Input
                                        type="checkbox"
                                        style={{ transform: "scale(1.8)" }}
                                        name={i.type}
                                        value={i.type}
                                        checked={isTypeEnabled(i.type) ? true : false}
                                        onChange={() => toggleFilterType(i.type)} />
                                    <div style={{ paddingLeft: "8px" }}>{i.type.replace("-", " ")}</div>
                                </Label>
                            </Col>
                        )
                    }
                </div>
                {
                    chosenFilters.map(i =>
                        <div key={i.type}>
                            {i.type !== "date-range" ?
                                <div key={i.type}>
                                    <div className={`border-top my-3`}></div>
                                    <div className={`row mx-3 mb-3 font-weight-bold`}
                                        style={{ fontSize: "18px", textTransform: "capitalize", justifyContent: "space-between", alignItems: "center" }}>
                                        <div>Select {i.type}</div>
                                        <div>
                                            {["client", "coach"].includes(i.type) &&
                                                <Search disableForm={true} value={search[i.type]} onKeyDown={event => handleSearch(event, i.type)} onChange={value => { updateSearch(value, i.type) }} />
                                            }</div>
                                    </div>
                                    {loading[i.type]
                                        ?
                                        <div className={`row justify-content-center mb-1`}>
                                            <Spinner color="primary" />
                                        </div>
                                        :
                                        <Col className={`row mx-2`}>
                                            {
                                                availableFilters.filter(f => f.type === i.type)[0].values.map(o =>
                                                    <Col className={`col-4 mb-1`} key={o.uuid}>
                                                        <Label className="text-muted col"
                                                            style={{ textTransform: "capitalize", fontSize: '16px' }}
                                                            key={o.uuid}>
                                                            <Input
                                                                type="checkbox"
                                                                style={{ transform: "scale(1.8)" }}
                                                                name={o.name}
                                                                value={o.uuid}
                                                                checked={isValueEnabled(i.type, o) ? true : false}
                                                                onChange={() => toggleFilterValue(i.type, o)} />
                                                            <div style={{ paddingLeft: "8px" }}>{o.name}</div>
                                                        </Label>
                                                    </Col>
                                                )
                                            }
                                        </Col>
                                    }
                                </div>
                                :
                                <div key={i.type}>
                                    <div className={`border-top my-3`}></div>
                                    <div className={`row mx-3 mb-3 font-weight-bold`} style={{ fontSize: "18px", textTransform: "capitalize" }}>Select {i.type.replace("-", " ")}</div>
                                    <div className={`row mx-2`}>
                                        <div className="col-6">
                                            <div style={{ fontSize: '16px', paddingLeft: "8px", marginBottom: "10px", fontWeight: "600" }}>Start</div>
                                            <Input
                                                type="datetime-local"
                                                style={{ transform: "scaleY(1.2)" }}
                                                id="start-date"
                                                name="start-date"
                                                value={i.start || false}
                                                onChange={(value) => toggleFilterDate("start", value.target.value)} />
                                        </div>
                                        <div className="col-6">
                                            <div style={{ fontSize: '16px', paddingLeft: "8px", marginBottom: "10px", fontWeight: "600" }}>End</div>
                                            <Input
                                                type="datetime-local"
                                                style={{ transform: "scaleY(1.2)" }}
                                                id="end-date"
                                                name="end-date"
                                                value={i.end || false}
                                                onChange={(value) => toggleFilterDate("end", value.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                }
            </ModalBody>
            {showSave() &&
                <ModalFooter>
                    <div>
                        <Button className={`btn btn-danger`} onClick={() => { hideModal(); setFilter([]) }}>
                            Remove
                        </Button>
                        <Button className={`btn btn-success`} onClick={() => { hideModal(); applyFilter(chosenFilters) }}>
                            Apply
                        </Button>
                    </div>
                </ModalFooter>
            }
        </Modal>
    )
}

export default FilterModal;