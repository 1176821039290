/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

const dashboard24HoursPerformanceChart = {
    data: (canvas) => {
        return {
            labels: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
            ],
            datasets: [
                {
                    borderColor: "#6bd098",
                    backgroundColor: "#6bd098",
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    borderWidth: 3,
                    tension: 0.4,
                    fill: true,
                    data: [300, 310, 316, 322, 330, 326, 333, 345, 338, 354],
                },
                {
                    borderColor: "#f17e5d",
                    backgroundColor: "#f17e5d",
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    borderWidth: 3,
                    tension: 0.4,
                    fill: true,
                    data: [320, 340, 365, 360, 370, 385, 390, 384, 408, 420],
                },
                {
                    borderColor: "#fcc468",
                    backgroundColor: "#fcc468",
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    borderWidth: 3,
                    tension: 0.4,
                    fill: true,
                    data: [370, 394, 415, 409, 425, 445, 460, 450, 478, 484],
                },
            ],
        };
    },
    options: {
        plugins: {
            legend: { display: false },
            tooltip: { enabled: false },
        },
        scales: {
            y: {
                ticks: {
                    color: "#9f9f9f",
                    beginAtZero: false,
                    maxTicksLimit: 5,
                },
                grid: {
                    drawBorder: false,
                    display: false,
                },
            },
            x: {
                barPercentage: 1.6,
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    padding: 20,
                    color: "#9f9f9f",
                },
            },
        },
    },
};

const dashboardNASDAQChart = {
    data: (canvas) => {
        return {
            labels: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
            datasets: [
                {
                    data: [0, 19, 15, 20, 30, 40, 40, 50, 25, 30, 50, 70],
                    fill: false,
                    borderColor: "#fbc658",
                    backgroundColor: "transparent",
                    pointBorderColor: "#fbc658",
                    pointRadius: 4,
                    pointHoverRadius: 4,
                    pointBorderWidth: 8,
                    tension: 0.4,
                },
                {
                    data: [0, 5, 10, 12, 20, 27, 30, 34, 42, 45, 55, 63],
                    fill: false,
                    borderColor: "#51CACF",
                    backgroundColor: "transparent",
                    pointBorderColor: "#51CACF",
                    pointRadius: 4,
                    pointHoverRadius: 4,
                    pointBorderWidth: 8,
                    tension: 0.4,
                },
            ],
        };
    },
    options: {
        plugins: {
            legend: { display: false },
        },
    },
};

const barChartOptions = {
    plugins: {
        legend: { display: false },
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
};

const dashboardUserTimeChartFn = (v, c = false) => {
    let labels = [];
    let data = [];
    for (let k in v) {
        if (v.hasOwnProperty(k)) {
            labels.push(k);
            data.push(v[k]);
        }
    }

    return {
        labels: labels,
        datasets: [
            {
                data: data,
                backgroundColor: c ? "orange" : "#51cbce",
            },
        ],
    };
};

const dashboardSessionChartFn = (v) => {
    let labels = [];
    let data = [];
    for (let k in v) {
        if (v.hasOwnProperty(k)) {
            labels.push(k);
            data.push(v[k]);
        }
    }

    return {
        labels: labels,
        datasets: [
            {
                data: data,
                backgroundColor: ["#ef8157", "orange", "rgb(82, 97, 121)", "#6bd098", "rgb(82, 197, 51)", "#6c757d"],
            },
        ],
    };
};

const pieChartOptions = {
    plugins: {
        legend: { display: false },
    },
    maintainAspectRatio: false,
    pieceLabel: {
        render: "percentage",
        fontColor: ["white"],
        precision: 2,
    },
    scales: {
        y: {
            ticks: {
                display: false,
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
        x: {
            barPercentage: 1.6,
            grid: {
                drawBorder: false,
                display: false,
            },
            ticks: {
                display: false,
            },
        },
    },
};

const dashboardSessionStatsChartFn = (active, completed, cancelled) => ({
    labels: ["Active", "Completed", "Cancelled"],
    datasets: [
        {
            label: "Sessions",
            data: [active, completed, cancelled],
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ["#51cbce", "#6bd098", "#ef8157"],
            borderWidth: 1,
        },
    ],
});

const dashboardGoalsChartFn = (active, completed) => ({
    labels: ["Active", "Completed"],
    datasets: [
        {
            label: "Goals",
            data: [active, completed],
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ["#51cbce", "#6bd098"],
            borderWidth: 1,
        },
    ],
});

const dashboardGoalsCategoryChartFn = (professional, educational, personal) => ({
    labels: ["Professional", "Educational", "Personal"],
    datasets: [
        {
            label: "Goals",
            data: [professional, educational, personal],
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ["#51cbce", "#6bd098", "orange"],
            borderWidth: 1,
        },
    ],
})

module.exports = {
    pieChartOptions,
    dashboardSessionStatsChartFn,
    dashboardGoalsChartFn,
    dashboardGoalsCategoryChartFn,

    barChartOptions,
    dashboardUserTimeChartFn,
    dashboardSessionChartFn,
    dashboard24HoursPerformanceChart,
    dashboardNASDAQChart,
};
